import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  ButtonBoxStyle,
} from "../../Css";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import Layout from "../Side bar/Layout";
import { updateShippingInfoValidation } from "../../../From/Validation";
import AutoComplete from "../Common/AutoComplete";
import CustomPhoneInput from "../Common/PhoneInput";
import { RadioBtn } from "../../../Css";
import { useTranslation } from "react-i18next";

const defaultValue = {
  address1: "",
  address2: "",
  city: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  state: "",
  zipCode: "",
  sameAsShipping: false,
};

export default function UpdateBillingInformation() {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [billingData, setBillingData] = useState({});
  const [shippingData, setShippingData] = useState({});

  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  const getState = async () => {
    try {
      const { data } = await ApiService.stateList();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.state,
          value: item?.id,
        }));
        setStateOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getBillingData = async () => {
    try {
      const { data } = await ApiService.getBillingInfo();
      const billingInfo = data?.billingInfo;
      setBillingData(billingInfo);
      setInitialValues({
        ...initialValues,
        address1: billingInfo?.address1,
        address2: billingInfo?.address2,
        city: billingInfo?.city,
        firstName: billingInfo?.firstName,
        lastName: billingInfo?.lastName,
        phoneNumber: billingInfo?.phoneNumber,
        state: billingInfo?.state,
        zipCode: billingInfo?.zipCode,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getBillingData();
    getState();
    getShippingData();
  }, [userId]);

  const onSubmit = async (values) => {
    try {
      const data = await ApiService.updateBillingInfo(values);
      if (
        data?.data?.msg === "Your billing info has been successfully updated"
      ) {
        setOpen(true);
        setShowSuccessMessage(true);
        getBillingData();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getShippingData = async () => {
    try {
      const { data } = await ApiService.getShippingInfo();
      const shippingInfo = data?.shippingInfo;
      setShippingData(shippingInfo);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleSameAsShipping = async (values) => {
    try {
      if (!values?.sameAsShipping) {
        setInitialValues({
          ...initialValues,
          ...shippingData,
          sameAsShipping: !values?.sameAsShipping,
        });
      } else {
        setInitialValues({
          ...initialValues,
          ...billingData,
          sameAsShipping: !values?.sameAsShipping,
        });
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Layout>
      <Box sx={{ padding: "30px" }}>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={onSubmit}
          validationSchema={updateShippingInfoValidation}
          validateOnMount={false}
          enableReinitialize
        >
          {({ getFieldProps, values, isSubmitting, setFieldValue }) => (
            <Form>
              {console.log("values: ", values)}
              <Box sx={FormHeaderBox}>
                <Box sx={{ borderBottom: "1px solid #1175BB", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    {t("screens.rightSidebar.text.updateBillingInformation")}
                  </Typography>
                </Box>
                <RadioBtn>
                  <input
                    type="checkbox"
                    onChange={() => handleSameAsShipping(values, setFieldValue)}
                    defaultChecked={values?.sameAsShipping}
                  ></input>
                  {t("screens.rightSidebar.text.sameAsShippingAddress")}
                </RadioBtn>
                <div style={{ width: "100%" }}>
                  <Body style={{ gap: "10px 20px" }}>
                    <Column>
                      <FormGroup
                        name="firstName"
                        label={`${t("screens.formData.firstName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("firstName")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="lastName"
                        label={`${t("screens.formData.lastName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("lastName")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="address1"
                        label={`${t("screens.formData.streetAddress")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("address1")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="address2"
                        label={`${t("screens.formData.address2")}`}
                      >
                        <InputField {...getFieldProps("address2")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="city"
                        label={`${t("screens.formData.city")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("city")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="state"
                        label={`${t("screens.formData.stateProvinceRegion")}`}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("state")}
                          name="state"
                          options={stateOptions}
                          placeholder={`${t("screens.formData.selectState")}`}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="zipCode"
                        label={`${t("screens.formData.zipCode")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("zipCode")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="phoneNumber"
                        label={`${t("screens.formData.phoneNumber")}`}
                        requiredField
                      >
                        <CustomPhoneInput {...getFieldProps("phoneNumber")} />
                      </FormGroup>
                    </Column>
                  </Body>
                </div>
                {/* </Grid> */}
                <Box sx={ButtonBoxStyle}>
                  <Box display="flex">
                    <CustomButton
                      label={
                        isSubmitting
                          ? `${t("screens.formData.updating")}`
                          : `${t("screens.formData.update")}`
                      }
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {showSuccessMessage && (
          <CustomSnackbar
            title={`${t("screens.successMessage.dataUpdated")}`}
            desc={`${t("screens.successMessage.billingUpdated")}`}
            open={open}
            okOnclick={() => {
              setShowSuccessMessage(false);
            }}
          />
        )}
      </Box>
    </Layout>
  );
}
