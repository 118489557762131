import React from "react";

import { useFormikContext } from "formik";
import { get } from "lodash";

function InputField({
  id,
  name,
  label,
  autoComplete = "off",
  sx,
  helperText,
  onBlur,
  onChange,
  width,
  required,
  value,
  errorMessage,
  inputSx,
  boxStyle,
  hasError,
  InputProps,
  className,
  ...params
}) {
  const { errors, values, touched, submitCount } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  return (
    <>

      <div>
        <input
          id={id}
          name={name}
          autoComplete={autoComplete}
          width={width}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={fieldTouched && error}
          className={className}
          {...params}
          style={{borderColor: error && fieldTouched ? '#DC3838' : '#1175BB'}}
        />
      </div>
    </>
  );
}

export default InputField;
