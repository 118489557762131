/* eslint-disable */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useAsyncDebounce } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import styled from "styled-components";

import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  UseTableInstanceProps,
} from "react-table";
// import DownSvgLightIcon from "@icons/DownSvgLightIcon";
// import UpSvgLightIcon from "@icons/UpSvgLightIcon";

// import LeftSvg from "@public/svg/left.svg";
// import RightSvg from "@public/svg/right.svg";

// import MonoSelect from "../Select/MonoSelect";
// import { PaginationOption } from "shared/dist/utils/Constant";
// import { DateRangeFilter } from "./DateRangeFilter";
// import { DropdownFilter } from "./DropdownFilter";
import { GlobalFilter } from "./filter";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styles from "./CustomTable.module.css";
import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { DropdownFilter } from "./DropdownFilter";
import CustomButton from "../Button";
import UserDropDown from "../../Item-List/UserDropDown";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import { useNavigate } from "react-router-dom";
import MemberDropDown from "../MemberDropdown";
import { useTranslation } from "react-i18next";

const getFilterValueByKey = (key, filter) => {
  const item = filter.find((it) => it?.id === key);
  return item && item.value;
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef && resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});
IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

const getRowId = (row, relativeIndex, parent) => {
  // In row object you have access to data.
  // Yon choose parameter. In this example I used uniqueId
  return parent ? [parent.id, row.id].join(".") : row.id;
};

function CustomTable({
  columns,
  data,
  onChange = () => {},
  fetchData,
  filterOptions = {},
  defaultOptions = {},
  metaData = {},
  fullTextFilterName = "search_text",
  pageCount: controlledPageCount,
  loading,
  hideFiltering = false,
  allowSelectRow = false,
  onRowSelect,
  rowSpanColumns = [],
  tableRef = useRef(null),
  filterOptionsButton = {},
  hideColumns,
  disabledRows = [],
  pagination,
  onPageSizeChanged,
  dataAddLink,
  AddPetArchive,
  isSelectHide = false,
  setSelectedUserName,
  items,
  placeholder,
  isArchive,
  label,
  details,
  back,
  addOnclick,
  addButton,
  ...props
}) {
  const pawprint_user_role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  let navigate = useNavigate();
  const [globalFilterOptions, setGlobalFilterOptions] = useState(filterOptions);
  const hiddenColumns = columns
    .filter((column) => column.isHidden)
    .map((item) => item.accessor);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (filterOptions && Object.keys(filterOptions).length > 0) {
      setGlobalFilterOptions(filterOptions);
    }
  }, [filterOptions]);

  const selectionCell = [];

  if (allowSelectRow) {
    selectionCell.push({
      id: "selection",
      Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
        const modifiedOnChange = (event) => {
          page.forEach((row) => {
            // check each row if it is not disabled
            if (!row.original.disabled) {
              toggleRowSelected(row.id, event.currentTarget.checked);
            }
          });
        };

        // Count number of selectable and selected rows in the current page
        // to determine the state of select all checkbox
        let selectableRowsInCurrentPage = 0;
        let selectedRowsInCurrentPage = 0;
        page.forEach((row) => {
          if (row.isSelected) {
            selectedRowsInCurrentPage++;
          }
          if (!row.original.disabled) {
            selectableRowsInCurrentPage++;
          }
        });

        // If there are no selectable rows in the current page
        // select all checkbox will be disabled -> see page 2
        const disabled = selectableRowsInCurrentPage === 0;
        const checked =
          (isAllPageRowsSelected ||
            selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
          !disabled;

        return (
          <div className="items-center text-center">
            <IndeterminateCheckbox
              onChange={modifiedOnChange}
              checked={checked}
              disabled={disabled}
              indeterminate={
                selectedRowsInCurrentPage > 0 &&
                selectedRowsInCurrentPage === selectableRowsInCurrentPage
              }
            />
          </div>
        );
      },
      Cell: ({ row }) => (
        <div className="items-center text-center">
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            disabled={row.original.disabled}
          />
        </div>
      ),
      width: 40,
      headerClass: "justify-center items-center text-center",
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    selectedFlatRows,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state,
    setFilter,
    setPageSize,
    toggleAllRowsSelected,
    toggleRowSelected,
    setHiddenColumns,
  } = useTable(
    {
      wrappedOnChange: useCallback(onChange, [onChange]),
      columns,
      data,
      disableMultiSort: true,
      manualPagination: true,
      manualFilters: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      initialState: {
        pageIndex: 0,
        hiddenColumns,
        ...defaultOptions,
      },
      getRowId,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.useInstance.push((state) => {
        // Expose the table instance to the parent component
        if (tableRef) {
          tableRef.current = state;
        }
      }),
        hooks.visibleColumns.push((columns) => [...selectionCell, ...columns]);
    }
  );

  const generateSortingIndicator = (column) =>
    column.isSorted ? (
      <span className="flex px-1 pt-2">
        {column.isSortedDesc ? (
          <span>
            <ArrowDownwardIcon />
          </span>
        ) : (
          <span>
            <ArrowUpwardIcon />
          </span>
        )}{" "}
      </span>
    ) : (
      ""
    );

  const { pageIndex, pageSize, filters, globalFilter, sortBy, selectedRowIds } =
    state;
  const StartArrayPageIndex =
    pageOptions.length < 9
      ? pageOptions.slice(0, pageOptions.length)
      : pageIndex - 3 < 0
      ? pageOptions.slice(0, 4)
      : pageIndex - 4 < 0
      ? pageOptions.slice(0, 5)
      : pageOptions.slice(0, 2);
  const CenterArrayPageIndex =
    pageIndex > 3 &&
    pageIndex <= pageOptions.length - 5 &&
    pageOptions.slice(pageIndex - 1, pageIndex + 2);
  const EndArrayPageIndex =
    pageIndex >= pageOptions.length - 3
      ? pageOptions.slice(pageOptions.length - 4, pageOptions.length)
      : pageIndex >= pageOptions.length - 4
      ? pageOptions.slice(pageOptions.length - 5, pageOptions.length)
      : pageOptions.slice(pageOptions.length - 2, pageOptions.length);

  const searchFunction = () => {
    const oFilter = {
      limit: pageSize,
      from: pageSize * pageIndex,
      page: pageIndex + 1,
    };
    filters.map((tblFilter) => {
      oFilter[`filter[${tblFilter.id}]`] = tblFilter.value;
    });

    if (globalFilter) {
      oFilter[`filter[${fullTextFilterName}]`] = globalFilter;
    }

    if (sortBy.length) {
      oFilter.sort = `${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`;
    }
    if (fetchData) fetchData(oFilter);
  };
  const debouncedSearchFunction = useAsyncDebounce(searchFunction, 500); // Debounce the search function with a 500ms delay

  useEffect(() => {
    searchFunction();
  }, [fetchData, pageIndex, pageSize, filters, globalFilter, sortBy]);

  // const pagination =()=>{
  //   const oFilter: { [key: string]: any } = {
  //     limit: pageSize,
  //     from: pageSize * pageIndex,
  //     page: pageIndex + 1
  //   }
  //   console.log('=====>',oFilter)
  //   if (fetchData) fetchData(oFilter)
  // }

  // useEffect(() => {
  //   pagination()
  // }, [pageSize])

  useEffect(() => {
    if (defaultOptions?.selectedRowIds) {
      for (const key in defaultOptions?.selectedRowIds) {
        toggleRowSelected(key, defaultOptions?.selectedRowIds[key]);
      }
      // toggleRowSelected
    }
  }, [defaultOptions?.selectedRowIds]);

  useEffect(() => {
    if (hideColumns) {
      setHiddenColumns(hideColumns);
    }
  }, [hideColumns]);

  useEffect(() => {
    if (onRowSelect) {
      onRowSelect({ selectedFlatRows });
    }
  }, [selectedRowIds, selectedFlatRows]);

  const calculateRowSpan = (cell, rowIndex, columnId) => {
    let rowSpan = 1;

    // Custom logic to calculate row span
    if (rowIndex > 0 && cell.value === rows[rowIndex - 1].values[columnId]) {
      rowSpan = 0;
    }

    for (let i = rowIndex + 1; i < rows.length; i++) {
      if (cell.value === rows[i].values[columnId]) {
        rowSpan++;
      } else {
        break;
      }
    }

    return rowSpan;
  };

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "end", position: "relative" }}
        className="meber-menu-section"
      >
        {/* {!isSelectHide && <MemberDropDown />} */}
      </div>
      {!hideFiltering && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "@media (max-width: 400px)": {
                alignItems: isArchive ? "center" : "end",
              },
            }}
          >
            {isArchive && (
              <Box sx={{ cursor: "pointer", marginRight: "10px" }}>
                <ArrowBackIosNewRoundedIcon
                  onClick={() => navigate(back ? back : "/archive")}
                />
              </Box>
            )}
            <p
              style={{ fontSize: "23px", color: "#232323", fontWeight: "600" }}
            >
              {label}
            </p>
          </Box>
          <AddSection>
            {Object.entries(globalFilterOptions).map((entry) => {
              const [key, value] = entry;
              if (value.type === "DateRange") {
                return (
                  <div className="mx-4 w-60">
                    <DateRangeFilter
                      setFilter={setFilter}
                      key={key}
                      id={key}
                      placeholder={value.placeholder}
                    />
                  </div>
                );
              } else {
                return (
                  <DropdownFilter
                    setFilter={setFilter}
                    key={key}
                    id={key}
                    placeholder={value.placeholder}
                    options={value.options}
                    value={getFilterValueByKey(key, filters)}
                    className={value.className}
                  />
                );
              }
            })}
            <>
              {addButton && (
                <Button
                  sx={{ height: "40px", width: "50px" }}
                  style={{
                    backgroundColor: "#1175BB",
                    color: "#FFFFFF",
                    width: "80px",
                    margin: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addOnclick ? addOnclick() : navigate(dataAddLink);
                  }}
                >
                  {`${t("screens.formData.add")}`}
                </Button>
              )}
            </>
          </AddSection>
          {Object.entries(filterOptionsButton).length > 0 && (
            <div className="flex items-center">
              {Object.entries(filterOptionsButton).map((entry) => {
                const [key, value] = entry;
                return (
                  <Button
                    outline
                    key={key}
                    //   className="mr-2"
                    //   size="xs"
                    onClick={value.onClick}
                  >
                    {value.icon}
                    {value.label}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      )}

      <div className="tableScroll">
        <table
          {...getTableProps()}
          style={{
            width: "100%",
            boxShadow: "none",
            border: "0",
            borderRadius: "10px",
            maxHeight: "calc(100vh - 146px)",
            margin: "0",
            overflow: "auto",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                className={`${styles.tableHeader}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        cursor: "pointer",
                        margin: 0,
                        padding: "15px 10px",
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: 700,
                        height: "27px",
                        textAlign: column.textAlign,
                      },
                    })}
                  >
                    <div>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{
              margin: 0,
              padding: "5px 12px",
              fontSize: "16px",
              color: "#000000",
              fontWeight: 400,
              height: "60px",
            }}
          >
            {loading ? (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  <CircularProgress style={{ color: "#1175BB" }} />
                </td>
              </tr>
            ) : (
              <>
                {data.length <= 0 ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center" }}
                    >
                      {details}
                    </td>
                  </tr>
                ) : (
                  <>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      const isRowDisabled = disabledRows.includes(
                        row.original.id
                      );
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={
                            isRowDisabled
                              ? "bg-gray-200 opacity-50 pointer-events-none"
                              : ""
                          }
                          onMouseEnter={() => setHoveredRowIndex(i)}
                          onMouseLeave={() => setHoveredRowIndex(null)}
                          key={`${i}_row`}
                          //   style={{ height: 'inherit'}}
                        >
                          {row.cells.map((cell, rowIndex) => {
                            // const rowSpan = calculateRowSpan(cell, rowIndex, cell.column.id);

                            let rowSpan = 1;
                            if (rowSpanColumns?.includes(cell.column.id)) {
                              rowSpan = row?.original?.rowSpan || 0;
                            }
                            if (rowSpan <= 0) {
                              return null;
                            }
                            return (
                              <td
                                {...cell.getCellProps({ rowSpan })}
                                style={{
                                  minWidth: cell.column.minWidth,
                                  width: cell.column.width,
                                  maxWidth: cell.column.maxWidth,
                                  padding: "5px 10px",
                                  cursor: "pointer",
                                  background:
                                    hoveredRowIndex === i
                                      ? "rgba(17, 117, 187, 0.10)"
                                      : "white",
                                  textAlign: cell.column.textAlign || "start",
                                }}
                              >
                                {cell?.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div>
        {pageOptions.length ? (
          <Pagination>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                alignItems: "center",
              }}
            >
              <span className="text14Regular">
                Showing {pageIndex + 1} of <span>{pageOptions.length}</span>{" "}
                Results
              </span>
              &nbsp;
              <div>
                {pagination && (
                  <Select
                    onChange={(e) => {
                      setPageSize(e.target.value);
                      onPageSizeChanged?.(e.target.value);
                    }}
                    defaultValue={defaultOptions?.pageSize}
                    // menuPosition={"absolute"}
                    // menuPlacement={"auto"}
                    // pagination={true}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #1175BB",
                        borderRadius: "10px",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "transparent",
                        },
                      "& .MuiSelect-icon": { color: "#AEAFB4" },
                      "& .MuiChip-root": {
                        color: "white",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1175BB",
                        },
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1175BB",
                      },
                      "&.Mui-focused .MuiChip-root": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                  >
                    {[5, 10, 15, 20, 25, 50, 100].map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                sx={btnStyle}
              >
                First
              </Button>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                label="Back"
                sx={{ ...btnStyle, background: "transparent !important" }}
              >
                <NavigateBeforeIcon />
              </Button>
              <div style={{ display: "flex" }}>
                {StartArrayPageIndex.map((i) => (
                  <Button
                    key={`${i}_page_index`}
                    sx={{
                      background:
                        pageIndex === i
                          ? "#1976d22e !important"
                          : "transparent !important",
                      color:
                        pageIndex === i
                          ? "#1175BB !important"
                          : "#737373 !important",
                      ...buttonStyle,
                    }}
                    onClick={() => gotoPage(i)}
                  >
                    <span
                      className={`${
                        pageIndex === i
                          ? "text-primary-600"
                          : "text-neutral-500"
                      } text14Regular`}
                    >
                      {i + 1}
                    </span>
                  </Button>
                ))}
                {CenterArrayPageIndex.length && pageOptions.length > 8 && (
                  <div style={{ margin: "auto" }}>...</div>
                )}
                {CenterArrayPageIndex.length &&
                  pageOptions.length > 8 &&
                  CenterArrayPageIndex.map((i) => (
                    <Button
                      key={`${i}_page_index`}
                      sx={{
                        background:
                          pageIndex === i
                            ? "#1976d22e !important"
                            : "transparent !important",
                        color:
                          pageIndex === i
                            ? "#1175BB !important"
                            : "#737373 !important",
                        ...buttonStyle,
                      }}
                      onClick={() => gotoPage(i)}
                    >
                      <span
                        className={`${
                          pageIndex === i
                            ? "text-primary-600"
                            : "text-neutral-500"
                        } text14Regular`}
                      >
                        {i + 1}
                      </span>
                    </Button>
                  ))}
                {EndArrayPageIndex.length && pageOptions.length > 8 && (
                  <div style={{ margin: "auto" }}>...</div>
                )}
                {EndArrayPageIndex.length &&
                  pageOptions.length > 8 &&
                  EndArrayPageIndex.map((i) => (
                    <Button
                      key={`${i}_page_index`}
                      sx={{
                        background:
                          pageIndex === i
                            ? "#1976d22e !important"
                            : "transparent !important",
                        color:
                          pageIndex === i
                            ? "#1175BB !important"
                            : "#737373 !important",
                        ...buttonStyle,
                      }}
                      onClick={() => gotoPage(i)}
                    >
                      <span
                        className={`${
                          pageIndex === i
                            ? "text-primary-600"
                            : "text-neutral-500"
                        } text14Regular`}
                      >
                        {i + 1}
                      </span>
                    </Button>
                  ))}
              </div>
              <Button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                sx={{ ...btnStyle, background: "transparent !important" }}
              >
                <NavigateNextIcon />
              </Button>
              <Button
                onClick={() => gotoPage(controlledPageCount - 1)}
                disabled={!canNextPage}
                sx={btnStyle}
              >
                Last
              </Button>
            </div>
          </Pagination>
        ) : null}
      </div>
    </>
  );
}

export default CustomTable;

const buttonStyle = {
  // color: "black",
  minWidth: "40px",
  height: "40px",
  padding: "0px",
  margin: "0px",
  borderRadius: "6px",
};

const btnStyle = {
  minWidth: "40px",
  height: "40px",
  fontWeight: 500,
  backgroundColor: "transparent !important",
  color: "#1175BB",
  cursor: "pointer",
  "&:disabled": {
    color: "#428bca",
    cursor: "not-allowed",
  },
};

const Pagination = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px",

  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "start",
  },
}));

const AddSection = styled("div")(() => ({
  display: "flex",
  justifyContent: "end",
  margin: "10px",

  "@media (max-width: 400px)": {
    flexDirection: "column-reverse",
    alignItems: "end",
  },
}));
