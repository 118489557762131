import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  ButtonBoxStyle,
} from "../../Css";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import Layout from "../Side bar/Layout";
import { ChangePasswordFormValidation } from "../../../From/Validation";
import { useTranslation } from "react-i18next";

const defaultValue = {
  user_fname: "",
  user_lname: "",
  user_email: "",
  oldPwd: "",
  newPwd: "",
  con_newpwd: "",
};

export default function ChangePasswordForm() {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  const getUserData = async () => {
    try {
      const { data } = await ApiService.checkActiveSession();
      const userData = data?.user?.[0];
      setInitialValues({
        ...initialValues,
        user_fname: userData?.user_fname,
        user_lname: userData?.user_lname,
        user_email: userData?.user_email,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, [userId]);

  const onSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        ...values,
      };
      delete payload.con_newpwd;
      const data = await ApiService.changePassword(payload);
      if (data?.data?.err === "L_ERROR__INCORRECT_CURRENT_PASSWORD") {
        formikRef?.current?.setFieldError(
          "oldPwd",
          "Incorrect current password"
        );
        return;
      }
      if (data?.data?.msg === "L_LOGIN__PASSWORD_CHANGED") {
        setOpen(true);
        setShowSuccessMessage(true);
        resetForm();
        getUserData();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Layout>
      <Box sx={{ padding: "30px" }}>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={onSubmit}
          validationSchema={ChangePasswordFormValidation}
          validateOnMount={false}
          enableReinitialize
        >
          {({ getFieldProps, values, isSubmitting }) => (
            <Form>
              <Box sx={FormHeaderBox}>
                <Box sx={{ borderBottom: "1px solid #1175BB", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    {t("screens.rightSidebar.text.changePassword")}
                  </Typography>
                </Box>
                <div style={{ width: "100%" }}>
                  <Body style={{ gap: "10px 20px" }}>
                    <Column>
                      <FormGroup
                        name="user_fname"
                        label={`${t("screens.formData.firstName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("user_fname")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="user_lname"
                        label={`${t("screens.formData.lastName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("user_lname")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="user_email"
                        label={`${t("screens.formData.email")}`}
                      >
                        <InputField {...getFieldProps("user_email")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="oldPwd"
                        requiredField
                        label={`${t("screens.rightSidebar.text.oldPassword")}`}
                      >
                        <InputField
                          type="password"
                          {...getFieldProps("oldPwd")}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="newPwd"
                        requiredField
                        label={`${t("screens.rightSidebar.text.newPassword")}`}
                      >
                        <InputField
                          type="password"
                          {...getFieldProps("newPwd")}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="con_newpwd"
                        requiredField
                        label={`${t(
                          "screens.rightSidebar.text.confirmPassword"
                        )}`}
                      >
                        <InputField
                          type="password"
                          {...getFieldProps("con_newpwd")}
                        />
                      </FormGroup>
                    </Column>
                  </Body>
                </div>
                {/* </Grid> */}
                <Box sx={ButtonBoxStyle}>
                  <Box display="flex">
                    <CustomButton
                      label={
                        isSubmitting
                          ? `${t("screens.formData.submitting")}`
                          : `${t("screens.formData.submit")}`
                      }
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {showSuccessMessage && (
          <CustomSnackbar
            title={`${t("screens.successMessage.dataUpdated")}`}
            desc={`${t("screens.successMessage.passwordUpdated")}`}
            open={open}
            okOnclick={() => {
              setShowSuccessMessage(false);
            }}
          />
        )}
      </Box>
    </Layout>
  );
}
