import React from "react";

export default function FeatureIcons() {
  return (
    <div>
      <div className="services-sections">
        <h4 className="services-section-title">Services Overview</h4>
        <p className="service-sections-description">
          Footprint ID® members, their loved ones or medical personnel will be
          able to access that person's medical information via the web,
          smartphone app or by calling our live representatives who are
          available 24 hours a day. Knowing that critical health information is
          available can help save lives in an emergency, provide medical history
          in times of stress, and gives each member invaluable peace of mind.
        </p>
      </div>
      <div
        className="services-overview-card ng-scope"
        ng-controller="servicesData"
      >
        <div className="row flex-row text-center item-center">
          <div
            className="col-sm-3 col-xs-6 ng-scope"
            ng-repeat="(key,data ) in serviceItem"
          >
            <div>
              <img
                ng-src="assets/svg/fill.svg"
                className="img-fluid"
                src="assets/svg/fill.svg"
                alt=""
              />
              <h6 className="service-item-block text-orange">Its Simple</h6>
            </div>
          </div>
          <div
            className="col-sm-3 col-xs-6 ng-scope"
            ng-repeat="(key,data ) in serviceItem"
          >
            <div>
              <img
                ng-src="assets/svg/secure.svg"
                className="img-fluid"
                src="assets/svg/secure.svg"
                alt=""
              />
              <h6 className="service-item-block text-sky-blue">It’s secure</h6>
            </div>
          </div>
          <div
            className="col-sm-3 col-xs-6 ng-scope"
            ng-repeat="(key,data ) in serviceItem"
          >
            <div>
              <img
                ng-src="assets/svg/call.svg"
                className="img-fluid"
                src="assets/svg/call.svg"
                alt=""
              />
              <h6 className="service-item-block text-green">On Call24/7</h6>
            </div>
          </div>
          <div
            className="col-sm-3 col-xs-6 ng-scope"
            ng-repeat="(key,data ) in serviceItem"
          >
            <div>
              <img
                ng-src="assets/svg/card.svg"
                className="img-fluid"
                src="assets/svg/card.svg"
                alt=""
              />
              <h6 className="service-item-block text-gray">Affordable</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="latest-news-sections">
        <h6 className="latest-news-title">Latest News</h6>
        <div className="latest-news-card ng-scope" ng-controller="latestData">
          <div
            ng-repeat="(key,data ) in latestItems"
            className="bg-dark-blue col-lg-3 latest-news-card-inner"
          >
            <div className="latest-card">
              <img
                ng-src="assets/images/insight.png"
                className="img-fluid"
                src="assets/images/insight.png"
                alt=""
              />
            </div>
            <div className="latest-card ng-scope" ng-if="$index % 2 === 0">
              <img
                ng-src="assets/images/travelParkImg.png"
                className="img-fluid"
                src="assets/images/travelParkImg.png"
                alt=""
              />
            </div>
            <div className="latest-button">
              <button className="read-more-button">Read More</button>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in latestItems"
            className="bg-ency-600 col-lg-3 latest-news-card-inner"
          >
            <div className="latest-card">
              <img
                ng-src="assets/images/clsion.png"
                className="img-fluid"
                src="assets/images/clsion.png"
                alt=""
              />
            </div>
            <p
              className="latest-news-card-label ng-binding ng-scope"
              ng-if="$index % 2 !== 0"
            >
              HealthyHabits Announces Partnership with FootprintID® The
              partnership will revolutionize employer-backed wellness benefits
            </p>

            <div className="latest-button">
              <button className="read-more-button">Read More</button>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in latestItems"
            className="bg-gray col-lg-3 latest-news-card-inner"
          >
            <div className="latest-card">
              <img
                ng-src="assets/images/travelPark.png"
                className="img-fluid"
                src="assets/images/travelPark.png"
                alt=""
              />
            </div>
            <div className="latest-card ng-scope" ng-if="$index % 2 === 0">
              <img
                ng-src="assets/images/insightImg.png"
                className="img-fluid"
                src="assets/images/insightImg.png"
                alt=""
              />
            </div>
            <div className="latest-button">
              <button className="read-more-button">Read More</button>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in latestItems"
            className="bg-ency-600 col-lg-3 latest-news-card-inner"
          >
            <div className="latest-card">
              <img
                ng-src="assets/images/ipmim.png"
                className="img-fluid"
                src="assets/images/ipmim.png"
                alt=""
              />
            </div>
            <p
              className="latest-news-card-label ng-binding ng-scope"
              ng-if="$index % 2 !== 0"
            >
              Generali Global Assistance Has Selected FootprintID® To Enhance
              Trip Mate Travel Protection Plans.
            </p>

            <div className="latest-button">
              <button className="read-more-button">Read More</button>
            </div>
          </div>
        </div>
        <div className="article-main-sections latest-article">
          <div
            className="article-inner-sections ng-scope"
            ng-controller="articleData"
          >
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-orange"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  Generali global assistance has selected FootprintID® to
                  enhance trip mate travel protection plans
                </h2>
                <p className="article-inner-description ng-binding">
                  This partnership provides travelers with secure access to
                  personal portable medical records when traveling
                </p>
              </div>
              <div className="article-inner-btn">
                <a
                  href="https://us.generaliglobalassistance.com/press/press-releases/generali-global-assistance-selected-footprintid-enhance-trip-mate-travel-protection-plans/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-sky-blue"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  FootprintID®: “your entire health history is available at your
                  fingertips”
                </h2>
                <p className="article-inner-description ng-binding">
                  Health Tech World hears from the co-founder and president of
                  FootprintID®, Beth Tofel, about the platform’s mission to save
                  the lives of business travelers and help organisations fulfill
                  their duty of care.
                </p>
              </div>
              <div className="article-inner-btn">
                <a
                  href='"https://www.htworld.co.uk/leadership/interviews/footprintid-your-entire-health-history-is-available-at-your-fingertips/"'
                  target="_blank"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-green"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  How Better Sensors Could Make the New Apple Watch Great
                </h2>
                <p className="article-inner-description ng-binding">
                  Enhanced sensors can help in detecting early signs of health
                  issues that might otherwise go unnoticed, like irregular heart
                  rhythms or drops in oxygen levels, Beth Tofel, the president
                  of FootprintID®, a medical information tracking company, said
                  via email.
                </p>
              </div>
              <div className="article-inner-btn">
                <a
                  href="https://www.yahoo.com/lifestyle/better-sensors-could-apple-watch-143632603.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-black"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  Travel Insurance Companies Are Upgrading Their Policies.
                  Here’s What’s New
                </h2>
                <p className="article-inner-description ng-binding">
                  World Nomads added a feature called FootprintID®, an app that
                  allows travelers to securely store their medical records
                  including allergies, medications and emergency contact
                  information, which they can then easily share with first
                  responders, healthcare providers and family in an emergency
                </p>
              </div>
              <div className="article-inner-btn">
                <a
                  href="https://www.forbes.com/sites/christopherelliott/2023/08/12/travel-insurance-companies-are-upgrading-their-policies-heres-whats-new/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-sky-yellow"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  Beth Tofel On The Five Things You Can Do To Become More
                  Resilient During Turbulent Times
                </h2>
                <p className="article-inner-description ng-binding">
                  As part of my series about 'developments in the travel
                  industry over the next five years', I had the pleasure of
                  interviewing Beth Tofel of FootprintID. … Between raising her
                  own children and helping to enhance the lives of children in
                  the community, FootprintID®'s President and Co-Founder has
                  been on the frontlines when it comes to knowing what parents
                  need and want to increase their peace of mind.
                </p>
                <div
                  ng-if="data.cardLink &amp;&amp; data.cardLink1"
                  className="article-inner-link ng-scope"
                >
                  <a
                    className="ng-binding"
                    rel="noreferrer"
                    target="_blank"
                    href="https://community.thriveglobal.com/beth-tofel-on-the-five-things-you-can-do-to-become-more-resilient-during-turbulent-times/"
                  >
                    … www.facebook.com/footprintid www.linkedin.com/bethtofel
                  </a>
                  <br />
                  <a
                    className="ng-binding"
                    rel="noreferrer"
                    target="_blank"
                    href="https://community.thriveglobal.com/beth-tofel-on-the-five-things-you-can-do-to-become-more-resilient-during-turbulent-times/"
                  >
                    www.linkedin.com/footprintid @footprintid
                  </a>
                </div>
              </div>
              <div className="article-inner-btn">
                <a
                  href="https://community.thriveglobal.com/beth-tofel-on-the-five-things-you-can-do-to-become-more-resilient-during-turbulent-times/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
            <div
              ng-repeat="(key,data ) in articleItems"
              className="article-inner-block border-top-red"
            >
              <div>
                <h2 className="article-inner-title ng-binding">
                  Travel experts share their predictions for 2023’s best bets
                </h2>
                <p className="article-inner-description ng-binding">
                  They are over buying big TV's and Peloton and want to
                  ‘live,’'Beth Tofel, president of FootprintID, added. “As well
                  as with remote work people can work from anywhere. So they can
                  travel and work simultaneously.'
                </p>
              </div>
              <div className="article-inner-btn">
                <a
                  href="https://www.consumeraffairs.com/news/travel-experts-share-their-predictions-for-2023s-best-bets-012323.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn" type="button">
                    Read More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="latest-get-date text-center">
          <p>Read to get started?</p>
          <div>
            <button
              className="btn-deep-orange"
              style={{ padding: "15px 50px" }}
            >
              Schedule a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
