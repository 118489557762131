import styled from "styled-components";

// --- custom-start
export const gridCenter = {
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
};
export const gridLeftStyle = {
  display: "grid",
  alignItems: "left",
  justifyContent: "left",
};
export const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  background: rgba(255, 255, 255, 1);
  border-radius: 37px;
  backdrop-filter: blur(15px);

  @media (max-width: 1130px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
  @media (max-width: 425px) {
    left: 20px;
  }
`;

export const inputCustomStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#1175BB",
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiFormControl-root": {
    padding: "0px 0px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
  {
    transition: "background-color 5000s ease-in-out 0s",
    background: "#FFA500 !important",
    color: "black !important",
    padding: "0px 14px  !important",
    appearance: "none  !important",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};
// ----custom-end-
// ---- pet info start
export const smallFieldLeftBoxStyle = {
  width: { sm: 270, md: 270, xs: 177, lg: 177, xl: 177 },
  display: "flex",
  alignItems: "center",
  marginRight: "0.5vw",
  justifyContent: { md: "left", sm: "left" },
};
export const smallFieldGenderBoxStyle = {
  width: { sm: 270, md: 270, xs: 300, lg: 300, xl: 300 },
  display: "flex",
  alignItems: "center",
  justifyContent: { md: "left", sm: "left" },
};

export const headerGridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "left",
};

export const PrintBtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px",
  width: 150,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 37,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
//   pet info end
// -- user information --start--
export const smallFieldBoxStyle = {
  width: { sm: 392, md: 400, xs: 300 },
  display: "flex",
  alignItems: "center",
  justifyContent: { md: "center", sm: "left" },
};

export const gridStyleLeft = {
  display: "grid",
  justifyContent: { md: "left", sm: "left" },
};
export const userTextStyle = {
  "& .MuiButtonBase-root": {
    backgroundColor: "#1175BB !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    height: "40px",
  },
  "& .MuiInputBase-root ": {
    width: { sm: 130, md: 180, xs: 130 },
    height: "40px",
  },
  "& .MuiFormControl-root": {
    height: "40px",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  ...inputCustomStyle,
};
export const textFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "40px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#1175BB",

    "&:hover fieldset": {
      borderColor: "grey",
    },
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiInputBase-root ": {
    width: { sm: 300, md: 400, xs: 300 },
    height: "40px",
  },
  "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
  {
    transition: "background-color 5000s ease-in-out 0s",
    background: "#FFA500 !important",
    color: "black !important",
    padding: "0px 14px  !important",
    appearance: "none  !important",
  },
  "& .MuiFormControl-root": {
    padding: "0px 0px",
    height: "40px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};
export const smallTextStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    height: "40px",
  },
  "& .MuiInputBase-root ": {
    width: { sm: 170, md: 180, xs: 170 },
    height: "40px",
  },
  "& .MuiFormControl-root": {
    height: "40px",
  },
};
export const mediumTextStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    height: "40px",
    "&:hover fieldset": {
      borderColor: "grey",
    },
  },
  "& .MuiInputBase-root ": {
    width: { sm: 380, md: 400, xs: 380 },
    height: "40px",
  },
  "& .MuiFormControl-root": {
    height: "40px",
  },
};

export const inputstyle = {
  color: "black",
  fontSize: "14px",
};
//--- user information -- end
// ---- pet info start----
export const PetInfoTextStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    height: "35px",
  },
  "& .MuiInputBase-root ": {
    height: "38px",
    color: "Black",
  },
  "& .MuiFormControl-root": {
    height: "35px",
  },
};
export const PetInfoTextWidth = {
  "& .MuiInputBase-root": {
    width: { sm: 144, md: 127, xs: 144, xl: 167, lg: 167 },
  },
  ...PetInfoTextStyle,
  ...inputCustomStyle,
};
export const weightStyle = {
  "& .MuiInputBase-root": {
    width: { sm: 65, md: 65, xs: 65, xl: 82, lg: 79 },
  },
  ...PetInfoTextStyle,
  ...inputCustomStyle,
};
export const petInputDateStyle = {
  "&:hover fieldset": {
    borderColor: "grey",
  },
  "& .MuiInputBase-root": {
    width: { sm: 150, md: 190, xs: 160 },
  },
  ...PetInfoTextStyle,
  ...inputCustomStyle,
};
export const petTextFieldStyle = {
  "& .MuiInputBase-root": {
    width: { xs: 250, sm: 300, md: 270, lg: 350, xl: 350 },
  },
  ...PetInfoTextStyle,
  ...inputCustomStyle,
};
export const DOBStyle = {
  paddingLeft: { xl: "2.7vw", lg: "3vw", md: "12.5vw", sm: "0vw", xs: "0vw" },
  width: { sm: 298, md: 400, xs: 250 },
};
export const genderStyle = {
  ...PetInfoTextStyle,
  ...inputCustomStyle,
  "& .MuiSelect-select": {
    padding: "0px 10px",
    height: "35px",
    color: "black",
    alignItems: "center",
    justifyContent: "left",
    display: "flex",
  },
};
//--- pet info end ----
// ----emergency-from-start--
export const emergencyFieldStyle = {
  "& .MuiSvgIcon-root": {
    color: "rgba(0,0,0,0.4)",
    position: "absolute",
    top: "50%",
    right: "8px",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    zIndex: 2,
  },
  ...petTextFieldStyle,
};
export const AutoComplete = {
  height: "35px",
  color: "black",
};
export const autoCompleteStyle = {
  "& .MuiFormControl-root": {
    width: { xs: 250, sm: 300, md: 270, lg: 350, xl: 350 },
    ...AutoComplete,
  },
};
export const PreCustomStyle = {
  width: { sm: 304, md: 270, xs: 300, lg: 350, xl: 400 },
  display: "flex",
  alignItems: "start",
  justifyContent: { md: "left", sm: "center" },
  color: "black",
};
export const PrescriptionFieldBoxStyle = {
  ...PreCustomStyle,
  paddingLeft: { xl: "0vw", sm: "0vw", md: "0vw" },
};

export const PrescriptionTextAreaStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    margin: "none",
    padding: "0px",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#1175BB",
    "&:hover fieldset": {
      borderColor: "grey",
    },
    width: { xs: 250, sm: 300, md: 270, lg: 350, xl: 350 },
  },
  "& .MuiOutlinedInput-root": {
    width: { xs: 250, sm: 300, md: 270, lg: 350, xl: 350 },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};
export const smallAutoCompleteStyle = {
  "& .MuiFormControl-root": {
    width: { sm: 144, md: 125, xs: 144, xl: 167, lg: 167 },
    ...AutoComplete,
  },
};
export const PresEditFieldBoxStyle = {
  ...PreCustomStyle,
  paddingLeft: { xl: "2.2vw", sm: "21vw", md: "0vw" },
};
export const RightGridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: { md: "left", sm: "center", xs: "center" },
};
//--- emergency-from-end
// ---- pet-insurance-Information-- start
export const PetAutoCompleteStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "sans-serif",
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    width: "300px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 133, 51, 1)",
  },
  "& .MuiFormControl-root": {
    width: { xs: 160, sm: 313, md: 313, lg: 313, xl: 313 },
    ...AutoComplete,
  },
};
// ---- pet-insurance-Information--end
// ----Immunization-Information -- start
export const BoxSizeWidth = {
  width: { xs: 300, sm: 443, md: 443, lg: 443, xl: 443 },
};
export const BoxFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "sans-serif",
    height: "40px",
  },
  "& .MuiInputBase-root ": {
    ...BoxSizeWidth,
    height: "38px",
  },
  "& .MuiFormControl-root": {
    height: "35px",
  },
  "& .MuiSvgIcon-root": {
    zIndex: 2,
  },
  "& .MuiButtonBase-root": {
    zIndex: 2,
  },
  ...inputCustomStyle,
};
export const immunizationStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    height: "40px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  },
  ...inputCustomStyle,
  "& .MuiFormControl-root": {
    ...BoxSizeWidth,
    height: "40px",
    color: "black",
  },
};
export const immunizationTextAreaStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    "&:hover fieldset": {
      borderColor: "grey",
    },
    ...BoxSizeWidth,
  },
  ...inputCustomStyle,
  "& .MuiOutlinedInput-root": {
    ...BoxSizeWidth,
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};
export const documentEditStyle = {
  "& .MuiFormControl-root": {
    width: { xs: 316, sm: 459, md: 459, lg: 459, xl: 444 },
    ...AutoComplete,
  },
};
export const inputDateStyle = {
  ...BoxFieldStyle,
  "& .MuiInputBase-root ": {
    width: { sm: 150, md: 190, xs: 160 },
    height: "38px",
  },
};

export const newSmallFieldBoxStyle = {
  width: "100%",
  display: "flex",
  alignItems: "start",
  justifyContent: { md: "left", sm: "left" },
  paddingBottom: { sm: "12px", md: "12px", lg: "20px" },
};

export const Body = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 0px 20px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 1271px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media (max-width: 965px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  // @media (max-width: 1400px) {
  //   // margin: 0 12px;
  //   // padding: 20px;
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-gap: 0px 20px;
  // }

  // @media (max-width: 650px) {
  //   // margin: 0 12px;
  //   // padding: 20px;
  //   grid-template-columns: repeat(1, 1fr);
  //   grid-gap: 12px;
  // }
`;

export const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;
`;
export const iFrame = styled.iframe`
position: fixed;
top: 0;
left: 0;
z-index: 9999;"
`;

export const FormHeaderBox = {
  border: "1px solid #1175BB",
  padding: { xs: "5px", sm: "24px" },
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "24px",
  boxShadow: "0px 50px 100px 0px rgba(0, 75, 117, 0.30)",
  backdropFilter: "blur(20px)",
};

export const FormHeaderTitle = {
  color: "#26272E",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "120%",
  paddingBottom: "10px",
};

export const CancelButton = {
  backgroundColor: "white !important",
  color: "#1175BB",
  border: "1px solid #1175BB",
};

export const ButtonBoxStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "end",
  alignItems: "center",
  borderTop: "1px solid #1175BB",
  paddingTop: "16px",
};

export const Scroll = styled("div")(() => ({
  // overflowY: "auto",
  // height: "calc(100vh - 139px)",
  // width: "calc(100% - 45px)",
  margin: "10px 5px",
  padding: "20px",
  textAlign: "justify",

  // "@media (max-width: 890px)": {
  //   height: "calc(100vh - 300px)",
  // },
  // "&::-webkit-scrollbar": {
  //   width: 7,
  // },
  // "&::-webkit-scrollbar-track": {
  //   WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
  //   borderRadius: 5,
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   borderRadius: 5,
  //   WebkitBoxShadow: "inset 0 0 6px #ffffff",
  //   backgroundColor: "#1175BB",
  // },
}));
