import { useState } from 'react'

import { CardCvcElement } from '@stripe/react-stripe-js'


const StripeCardCvcInput = ({ onChange, className }) => {
  const [error, setError] = useState()
  const inputStyle = {
    fontFamily:
      'sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#363741',
    width: '-webkit-fill-available',
    '::placeholder': {
      color: '#898A93'
    },
    backgroundColor: '#FFFFFF',
  }
  const mainStyle = {
    fontSize: '16px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid rgb(66, 139, 202)',
    width:' -webkit-fill-available',
    backgroundColor: '#FFFFFF',
  }
  return (
    <div aria-invalid={!!error} className={className}>
      <div>
        <div
        style={mainStyle}
          >
          <CardCvcElement
            onChange={event => {
              setError(event?.error?.message)
              onChange?.(event, event?.error?.message)
            }}
            options={{
              style: {
                base: inputStyle
              }
            }}
          />
        </div>
        {error && <p className={``}>{error}</p>}
      </div>
    </div>
  )
}

export default StripeCardCvcInput