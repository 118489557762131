import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function MenuButton(props) {
  const { item } = props;
  let navigate = useNavigate();
  return (
    <div>
      <HamburgerIcon
        onClick={() => {
          navigate(item.link);
        }}
        // style={{ margin: "16px 0px" }}
      >
        <MenuItem title={item.title}>
          <img src={item.icon} alt={item.title} width={30} height={30} />
          {item.title}
        </MenuItem>
      </HamburgerIcon>
    </div>
  );
}

export default MenuButton;
const HamburgerIcon = styled.div`
  background: #008fd3 !important;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 5px;
`;

const MenuItem = styled.div`
  display: grid;
  grid-template-columns: 28px auto;
  gap: ${(props) => (props.title ? "10px" : "0px")};
  background: white;
  padding: 5px 10px;
  font-size: 14px;
  text-align: left;

  :hover {
    background: #333;
  }
`;
