import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { immunizationValidation } from "./Validation";
import TextArea from "../Common/TextArea";
import { useTranslation } from "react-i18next";

const defaultValue = {
  immu_vaccineslist: "",
  vaccines_other: "",
  vaccineslist: "",
  immu_date: {},
  immu_info: {},
  immu_titer1_dates: {},
  immu_titers_info: {},
};

export default function ImmunizationForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  vaccinesList,
  allVaccinesList,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isEdit) {
      const vaccineDetail = allVaccinesList?.filter(
        (x) => x?.id?.toString() === data?.immu_vaccineslist?.toString()
      );
      setInitialValues({
        ...initialValues,
        ...data,
        immu_vaccineslist: data?.immu_vaccineslist.toString(),
        vaccineItem: vaccineDetail?.[0],
      });
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  const handleVaccines = (item, values) => {
    const immu_date_obj = {};
    const immu_titer_date_obj = {};
    const vaccineDetail = allVaccinesList?.find(
      (x) => x?.id?.toString() === item?.value?.toString()
    );
    for (let i = 0; i < item?.immu_dates_count; i++) {
      immu_date_obj[`val${i + 1}`] = "";
    }
    for (let i = 0; i < item?.titers_dates_count; i++) {
      immu_titer_date_obj[`val${i + 1}`] = null;
    }
    setInitialValues({
      ...values,
      immu_vaccineslist: item.value,
      immu_date: immu_date_obj,
      immu_info: immu_date_obj,
      immu_titer1_dates: immu_titer_date_obj,
      immu_titers_info: immu_titer_date_obj,
      vaccineItem: vaccineDetail,
    });
  };

  const onSubmit = async (values) => {
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
          // vaccineslist: values?.vaccineItem?.vaccineslist,
          vaccineItem: values?.vaccineItem,
        };
        const res = await ApiService.editImmunizations(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addImmunizations(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {}
  };

  const scrollToError = (errors) => {
    for (const key in errors) {
      let errorField;
      if (typeof errors[key] === "object") {
        const [[firstKey, firstValue]] = Object.entries(errors[key]);
        errorField = document.getElementsByName(`${key}.${firstKey}`);
        if (errorField.length) {
          errorField?.[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      } else {
        errorField = document.getElementsByName(key)[0];
        if (errorField) {
          errorField.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      break;
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={useMemo(
          () =>
            immunizationValidation(
              Object.keys(initialValues?.immu_date)?.length
            ),
          [initialValues]
        )}
        onSubmit={onSubmit}
        validateOnMount={true}
        enableReinitialize
      >
        {({
          getFieldProps,
          setFieldValue,
          values,
          isSubmitting,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.immunizations.editImmunizations")}`
                    : `${t("screens.immunizations.addImmunizations")}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="immu_vaccineslist"
                      label={`${t("screens.immunizations.vaccines")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("immu_vaccineslist")}
                        name="immu_vaccineslist"
                        options={vaccinesList}
                        onChange={(e, item) => handleVaccines(item, values)}
                        isOnChange
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {(values?.vaccineslist ||
                  values?.vaccineItem?.vaccineslist) && (
                  <div
                    style={{
                      color: "#000000",
                      backgroundColor: "#1976d22e",
                      borderColor: "#bce8f1",
                      padding: "3px 10px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      margin: "10px 0px",
                      fontWeight: 700,
                    }}
                  >
                    <p>
                      {values?.vaccineslist ||
                        values?.vaccineItem?.vaccineslist}
                    </p>
                  </div>
                )}

                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  {Object.keys(values?.immu_date).map((item, index) => {
                    return (
                      <>
                        <Grid item sm={3} xs={12}>
                          <FormGroup
                            name={`immu_date.val${index + 1}`}
                            label={`${t("screens.immunizations.date")}`}
                            requiredField
                          >
                            <InputField
                              id={index}
                              type="date"
                              // inputProps={{ max: "9999-12-12" }}
                              // onBlur={() =>
                              //   setFieldTouched(
                              //     `immu_date.val${index + 1}`,
                              //     true
                              //   )
                              // }
                              {...getFieldProps(`immu_date.val${index + 1}`)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid sm={3} xs={12} />
                        <Grid item sm={6} xs={12}>
                          <FormGroup
                            name={`immu_info.val${index + 1}`}
                            label={`${t("screens.immunizations.information")}`}
                          >
                            <TextArea
                              rows={5}
                              {...getFieldProps(`immu_info.val${index + 1}`)}
                            />
                          </FormGroup>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  {Object.keys(values?.immu_titer1_dates).map((item, index) => {
                    return (
                      <>
                        <Grid item sm={3} xs={12}>
                          <FormGroup
                            name={`immu_titer1_dates.val${item.charAt(
                              item.length - 1
                            )}`}
                            label={`${t(
                              "screens.immunizations.titersCalendar"
                            )} ${index + 1}:`}
                          >
                            <InputField
                              type="date"
                              inputProps={{ max: "9999-12-12" }}
                              defaultValue={
                                getFieldProps(
                                  `immu_titer1_dates.val${index + 1}`
                                ).value &&
                                new Date(
                                  getFieldProps(
                                    `immu_titer1_dates.val${index + 1}`
                                  ).value
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }
                              onBlur={(e) => {
                                setFieldValue(
                                  `immu_titer1_dates.val${index + 1}`,
                                  e?.target?.value
                                );
                              }}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid sm={3} xs={12} />
                        <Grid item sm={6} xs={12}>
                          <FormGroup
                            name={`immu_titers_info.val${item.charAt(
                              item.length - 1
                            )}`}
                            label={`${t(
                              "screens.immunizations.titersInformation"
                            )} ${index + 1}`}
                          >
                            {/*
                            <InputField
                              {...getFieldProps(
                                `immu_titers_info.val${item.charAt(
                                  item.length - 1
                                )}`
                              )}
                            />
                          */}
                            <TextArea
                              rows={5}
                              {...getFieldProps(
                                `immu_titers_info.val${item.charAt(
                                  item.length - 1
                                )}`
                              )}
                            />
                          </FormGroup>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={() => scrollToError(errors)}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.immunizationUpdated")}`
              : `${t("screens.successMessage.immunizationCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
