import {
  ADMIN,
  ADMIN_ROLE,
  GENERAL_AUTHORIZED_USER,
  SUPER_ADMIN_USER,
  USER,
  VIEW_ONLY_USER,
} from "./Constant";

export const ADMIN_ROLES = [
  USER,
  GENERAL_AUTHORIZED_USER,
  ADMIN,
  VIEW_ONLY_USER,
];
const SUPER_ADMIN_ROLE = [SUPER_ADMIN_USER];
export const config = {
  sidebar: [
    {
      title: "Personal Information ",
      link: "/user-information",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Emergency Contacts",
      link: "/emergency-contact",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Physicians/Therapists",
      link: "/physician-therapist",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Medical Insurance",
      link: "/medical-insurance",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Prescription & Medications",
      link: "/prescription-medications",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Allergies",
      link: "/allergy",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Conditions",
      link: "/conditions",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Surgeries & Treatments",
      link: "/surgeries-treatments",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Immunizations",
      link: "/immunizations",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Family History",
      link: "/family-history",
      icon: "",
      roles: ADMIN_ROLES,
    },
    {
      title: "Documents",
      link: "/document",
      icon: "",
      roles: ADMIN_ROLES,
    },
  ],
};

export const myProfileRoutes = [
  {
    title: "",
    link: "/myFootprintID",
    isHide: true,
    role: "ALL",
  },
  {
    title: "Change Password",
    link: "/settings/changePassword",
    role: "ALL",
  },
  {
    title: "Update Shipping Info",
    link: "/settings/shippingInfo",
    role: ADMIN_ROLE,
  },
  {
    title: "Update Billing Info",
    link: "/settings/billingInfo",
    role: ADMIN_ROLE,
  },
  {
    title: "Buy Additional Memberships",
    link: "/settings/upgradeMembers",
    role: ADMIN_ROLE,
  },
];
