import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import { Button } from "@mui/material";

import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { documentsValidation } from "./Validation";
import viewIcon from "../../Assets/Images/pdfIcon.png";
import { useTranslation } from "react-i18next";

const defaultValue = {
  docum_date: "",
  docum_name: "",
  docum_file: "",
};

export default function DocumentsForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  handleView,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const formikRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        emer_relation_id: data?.emer_relation_id?.toString(),
      });
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  const handleOnChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      formikRef?.current?.setFieldValue("docum_file", file);
    }
  };

  const onSubmit = async (values) => {
    try {
      let fileName = "";
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const uploadDocumentResponse = await ApiService.uploadDocument(
          formData
        );
        const filePath = uploadDocumentResponse?.data?.src;
        if (filePath) {
          fileName = filePath.substring(filePath.lastIndexOf("files/") + 6);
        }
      }
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        if (fileName) {
          payload.docum_file = fileName;
        }

        const res = await ApiService.editDocuments(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        if (fileName) {
          values.docum_file = fileName;
          const { data } = await ApiService.addDocuments(userId, values);
          if (data?.OK) {
            setOpen(true);
            setShowSuccessMessage(true);
            fetchAPIData();
          }
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={documentsValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting, setFieldValue }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.documents.editDocuments")}`
                    : `${t("screens.documents.addDocuments")}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="docum_date"
                      label={`${t("screens.documents.date")}`}
                      requiredField
                    >
                      <InputField
                        {...getFieldProps("docum_date")}
                        type="date"
                        inputProps={{ max: "9999-12-12" }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="docum_name"
                      label={`${t("screens.documents.documentName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("docum_name")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12} className="contained-button-file">
                    <FormGroup
                      name="docum_file"
                      label={`${t("screens.documents.documentFile")}`}
                      requiredField
                    >
                      <input
                        id="contained-button-file"
                        name="docum_file"
                        type="file"
                        onChange={handleOnChange}
                      />
                    </FormGroup>
                  </Grid>
                  {values?.id && (
                    <Grid item sm={3} xs={12}>
                      <Button
                        sx={{
                          minWidth: "40px",
                          background: "#1976d22e !important",
                          borderRadius: "10px",
                          marginRight: "5px",
                          width: "24px",
                        }}
                        onClick={() => handleView(values)}
                      >
                        <img
                          alt="view"
                          src={viewIcon}
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.documentUpdated")}`
              : `${t("screens.successMessage.documentCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
