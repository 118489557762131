import { Switch } from "@mui/material";
import React, { useState, useRef, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { STORE_LANGUAGE_KEY } from "../../Constant";

import useAuth from "../../ContextProvider/AuthProvider";

const MemberDropDown = memo(function MyProfileDropdown() {
  const { doLogout, loginUser } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [lang, setLang] = useState("en");
  const { t, i18n } = useTranslation();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    setUserlang();
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onToggleSwitch = () => {
    const newLanguage = lang === "sp" ? "en" : "sp";
    i18n.changeLanguage(newLanguage);
    setLang(newLanguage);
  };

  const setUserlang = async () => {
    const userlang = await localStorage.getItem(STORE_LANGUAGE_KEY);
    setLang(userlang ?? "en");
    // i18n.changeLanguage(userlang ?? "en");
  };

  return (
    <div ref={dropdownRef}>
      <button
        type="button"
        className="btn-default dropdown-toggle ng-binding show btn-header"
        data-bs-toggle="dropdown"
        aria-expanded="true"
        onClick={() => toggleDropdown()}
      >
        {/* Hi, {loginUser?.user_fname} {loginUser?.user_lname}
        <span className="caret"></span> */}
        <img
          alt="user"
          style={{
            height: "35px",
            width: "35px",
          }}
          src={require("../../../Assets/Images/Navbar/hamburger-menu.png")}
        />
      </button>
      <ul
        className={`dropdown-menu dropdown-menu-item top-menu-items ${
          dropdownOpen ? "show" : ""
        }`}
        role="menu"
        id="dropdownMenu"
      >
        <li ng-if="!menu.emergency" className="ng-scope">
          <a target="_self" href="/myFootprintID">
            <button className="btn btn-outline-primary ng-binding">
              {t("screens.profile.myFootprintID®")}
            </button>
          </a>
        </li>
        <li ng-if="!menu.emergency" className="ng-scope">
          <a href="/user-information">
            <button className="btn btn-outline-primary ng-binding">
              {t("screens.profile.medicalHistory")}
            </button>
          </a>
        </li>
        <li>
          <a target="_self" href="/contactus">
            <button className="btn btn-outline-primary ng-binding">
              {t("screens.profile.contactUs")}
            </button>
          </a>
        </li>
        <li>
          <button
            className="btn btn-outline-primary ng-binding"
            onClick={() => doLogout()}
          >
            {t("screens.logOut.text")}
          </button>
        </li>
        <li>
          <p
            className="switch-text"
            style={{ color: lang === "en" ? "#45AFE1" : "#D2D2D2" }}
          >
            English
          </p>
          <Switch
            checked={lang === "en"}
            onChange={onToggleSwitch}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p
            className="switch-text"
            style={{ color: lang !== "en" ? "#45AFE1" : "#D2D2D2" }}
          >
            Spanish
          </p>
        </li>
      </ul>
    </div>
  );
});

export default MemberDropDown;
