import React from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

import successIcon from "../../Assets/Images/successIcon.svg";
import warningIcon from "../../Assets/Images/warning.svg";

function DetailsModal({
  label,
  buttonList,
  handleClose,
  title,
  severity = "success",
  open,
}) {
  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { width: "100%", maxWidth: "511px", borderRadius: '10px', },
          }}
        >
          <Box sx={{ position: 'absolute', right: '10px', top: '10px'}}>
              <Close onClick={handleClose} style={{ color: '#1976D2', cursor: 'pointer'}} />
            </Box>
          <DialogTitle
            id="alert-dialog-title0"
            sx={{
              pt: 3,
              pb: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <img src={severity === "success" ? successIcon : warningIcon} height={severity === "success" ? '' : '34px'} width={severity === "success" ? '' : '34px'} style={{ margin: '10px 0px'}}/>{" "}
            {title && (
              <Typography component="div" fontWeight={600}>
                {title}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent sx={{ pb: 1 }}>
            <DialogContentText
              id="alert-dialog-description"
              color="black"
              textAlign="center"
              sx={{color: '#565868', fontSize: '18px', fontWeight: 400, margin: '10px 0px'}}
            >
              {label}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pt: 0, pb: 2 }}>
            {buttonList.map((item, index) => (
              <div key={index} className="sm:py-0 py-1">
                <Button size="small" onClick={item.onClick} sx={btnStyle}>
                  {item.name}
                </Button>
              </div>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default DetailsModal;

const btnStyle = {
  mx: 0.5,
  color: "white",
  background: "#1175BB !important",
  borderRadius: "10px",
  width: '84px',
  textTransform: "none",
  fontSize: "13px",
  fontWeight: 500,
  backdropFilter: "blur(10px)",
  height: '34px',
  marginBottom: '14px',
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid #1175BB",
  },
};
