import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Form, Formik } from "formik";
import FormGroup from "../../Components/Common/FormGroup";
import PasswordInput from "../../Components/Common/FormGroup/PasswordInput";
import { SetPasswordValidation } from "../../From/Validation";
import ApiService from "../../Frontend/Api/ApiServices";

const initialvalues = { pwd: "", conf_pwd: "" };
const RecoverPassword = () => {
  const { activationkey, id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const payload = {
        id: id,
        activationkey: activationkey,
        pwd: values?.pwd,
      };

      const { data } = await ApiService.setRecoveryPassword(payload);
      console.log("data: ", data);
      if (data?.OK) {
        swal({
          title: "Password successfully updated!",
          text: "Your password has been changed successfully",
          icon: "success",
        }).then(() => {
          navigate("/members");
        });
      } else {
        swal("Error!", "Cannot change now. Try again later.", "error");
      }
    } catch (error) {
      console.log("error: ", error);
      swal("Network Error!", "Cannot change now. Try again later.", "error");
    }
  };

  return (
    <div className="main">
      <div className="container">
        <Formik
          initialValues={initialvalues}
          onSubmit={handleSubmit}
          validationSchema={SetPasswordValidation}
          validateOnMount={false}
          enableReinitialize
        >
          {({ getFieldProps, values, isSubmitting }) => (
            <Form>
              {console.log("values: ", values)}
              <h1>Set Password</h1>

              <FormGroup
                label="New Password"
                name="pwd"
                className="loginField"
                svgPath={require("../../Assets/Images/lock.png")}
              >
                <PasswordInput {...getFieldProps("pwd")} />
              </FormGroup>
              <FormGroup
                label="Confirm Password"
                name="conf_pwd"
                className="loginField"
                svgPath={require("../../Assets/Images/lock.png")}
              >
                <PasswordInput {...getFieldProps("conf_pwd")} />
              </FormGroup>
              <button type="submit" className="btn1">
                {isSubmitting ? "Loading..." : "Set Password"}
              </button>
              <a id="l4" href="/members">
                Back to Log in
              </a>
              <br />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RecoverPassword;
