import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { isEqual } from "lodash";
import useSWR from "swr";

import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  filterAscDescData,
  formatDate,
  formatPhoneNumber,
} from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import EditIcon from "../../Assets/Images/EditIcon.svg";
import DeleteIcon from "../../Assets/Images/DeleteIcon.svg";
import ApiService from "../../Api/ApiServices.js";
import Layout from "../Side bar/Layout.js";
import PrescriptionAndMedicationForm from "../Forms/PrescriptionsMedicationsForm.js";
import { MainPageWrapper } from "./GlobalItemList.js";
import { useTranslation } from "react-i18next";

export default function PrescriptionAndMedication() {
  const { userId } = useAuth();
  const [filter, setFilter] = useState({});
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [prescriptionTypes, setPrescriptionTypes] = useState([]);
  const [presDosageOptions, setPresDosageOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [frequencyOptions2, setFrequencyOptions2] = useState([]);
  const [physicianOptions, setPhysicianOptions] = useState([]);
  const fetchIdRef = useRef(0);

  const { t } = useTranslation();
  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `PrescriptionAndMedication${userId}` : null,
    async () => {
      const { data } = await ApiService.getPrescriptionAndMidication(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { sort } = filter;
    if (filter && filter.from > -1) {
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort);
      setPrescriptionData(filteredResult);
    }
  }, [filter, data]);

  const handleView = (item) => {
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const getPrescriptionTypes = async () => {
    try {
      const { data } = await ApiService.getPrescriptionTypes();
      if (data?.length) {
        const mappedData = data.map((item) => ({
          label: item?.field_value,
          value: item?.id?.toString(),
        }));
        setPrescriptionTypes(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getPresDosageType = async () => {
    try {
      const { data } = await ApiService.getPresDosageTypes();
      if (data?.length) {
        const mappedData = data.map((item) => ({
          label: item?.field_value,
          value: item?.id?.toString(),
        }));
        setPresDosageOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getFrequencyTypes = async () => {
    try {
      const { data } = await ApiService.getPresFrequencyTypes();

      if (data?.length) {
        const mappedData = data.map((item) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setFrequencyOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const getFrequency2 = async () => {
    try {
      const { data } = await ApiService.getPresFrequency2();
      if (data?.length) {
        const mappedData = data.map((item) => ({
          label: item?.field_value,
          value: item?.id,
        }));
        setFrequencyOptions2(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getPresPhysician = async () => {
    try {
      const { data } = await ApiService.getPresPhysician();
      if (data?.length) {
        const mappedData = data.map((item) => ({
          label: `${item?.phy_fname} ${item?.phy_lname}`,
          value: `${item?.phy_fname} ${item?.phy_lname}`,
        }));
        setPhysicianOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    getPrescriptionTypes();
    getPresDosageType();
    getFrequencyTypes();
    getFrequency2();
    getPresPhysician();
  }, []);

  const handleEdit = async (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(true);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.prescriptionMedication.medicationType")}`,
        accessor: "medication_value",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.prescriptionMedication.medication")}`,
        accessor: "pres_medication",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.prescriptionMedication.dosage")}`,
        accessor: "dosage_input",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <img
                alt="view"
                src={EditIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original)}
            >
              <img
                alt="view"
                src={DeleteIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const defaultOptions = {
    pageSize: 5,
  };

  const props = {
    label: `${t("screens.prescriptionMedication.prescriptionsMedications")}`,
    isLoading: isLoading,
    columns,
    columnsData: prescriptionData,
    details: " There are no Prescriptions & Medications details for this user.",
    addOnclick: addOnclick,
    fetchData,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.deletePrescription(
          userId,
          askConfirmation
        );
        if (data?.OK) {
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t(
      "screens.prescriptionMedication.prescriptionsMedicationsInformation"
    )}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.prescriptionMedication.medicationType")}`,
          value: selectedData?.medication_value,
        },
        {
          name: `${t("screens.prescriptionMedication.medicationName")}`,
          value: selectedData?.pres_medication,
        },
        {
          name: `${t("screens.prescriptionMedication.dosage")}`,
          value: `${selectedData?.dosage_input} ${
            selectedData?.dosage_value ? selectedData?.dosage_value : ""
          }`,
        },
        {
          name: `${t("screens.prescriptionMedication.frequency")}`,
          value: `${selectedData?.pres_frequency}, ${selectedData?.frequency2}`,
        },
        {
          name: `${t("screens.prescriptionMedication.startDate")}`,
          value: selectedData?.pres_startdate
            ? formatDate(selectedData?.pres_startdate)
            : "",
        },
        {
          name: `${t("screens.prescriptionMedication.duration")}`,
          value: `${selectedData?.pres_duration} ${selectedData?.dentures_time}`,
        },
        {
          name: `${t("screens.prescriptionMedication.reasonForUse")}`,
          value: selectedData?.pres_reasonforuse,
        },
        {
          name: `${t("screens.prescriptionMedication.prescribingPhysician")}`,
          value: selectedData?.pres_physicianname,
        },
        {
          name: `${t("screens.prescriptionMedication.pharmacyName")}`,
          value: selectedData?.pres_pharm_name,
        },
        {
          name: `${t("screens.prescriptionMedication.pharmacyPhone")}`,
          value: selectedData?.pres_pharm_phone
            ? formatPhoneNumber(selectedData?.pres_pharm_phone)
            : "",
        },
        {
          name: `${t("screens.prescriptionMedication.prescriptionNumber")}`,
          value: selectedData?.pres_prescription,
        },
      ],
    ],
  };

  return (
    <Layout>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {isAdd && (
          <PrescriptionAndMedicationForm
            setIsAdd={setIsAdd}
            isEdit={isEdit}
            data={selectedData}
            fetchAPIData={fetchAPIData}
            prescriptionTypes={prescriptionTypes}
            presDosageOptions={presDosageOptions}
            frequencyOptions={frequencyOptions}
            frequencyOptions2={frequencyOptions2}
            physicianOptions={physicianOptions}
          />
        )}
      </MainPageWrapper>
    </Layout>
  );
}
