import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../Api/ApiServices.js";

export default function DocumentFile() {
  const [documentURL, setDocumentURL] = useState("");
  const { id } = useParams();

  const getDocuments = async () => {
    const { data } = await ApiService.getDocumentspdf(id);
    setDocumentURL(data?.url);
    return data || [];
  };
  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <iframe
      src={documentURL}
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      width="100%"
      height="100%"
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    />
  );
}
