import React from "react";

import styled from "styled-components";
// import MyProfileDropdown from "../Common/MyProfileDropdown";
import NewHeader from "../Header/newHeader";

import Sidebar from "./Sidebar";
function Layout({ children }) {
  return (
    <>
      <New>
        <Sidebar />
        <div
          style={{
            height: "100vh",
            width: "100%",
            background: "white",
            overflowY: "auto",
          }}
        >
          <NewHeader />
          {/* <div
            style={{
              display: "flex",
              justifyContent: "end",
              position: "relative",
            }}
          >
            <MyProfileDropdown />
          </div> */}
          {children}
        </div>
      </New>
    </>
  );
}

export default Layout;

const New = styled.div`
  display: flex;
  @media (max-width: 890px) {
    display: block;
  }
`;
