import axios from "axios";

import * as Sentry from "@sentry/react";
import { TOKEN_KEY } from "../Constant";
const token = localStorage.getItem("pawprint_token");

// const REACT_APP_BACKEND_BASE_URL = "https://dlan12b5p4qwh.cloudfront.net/api/";
export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,

  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  },
});

const apiAxiosFormData = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log errors to Sentry
    if (error.response) {
      Sentry.captureException(error);
    }

    // Forward the error to the calling code
    return Promise.reject(error);
  }
);

apiAxiosFormData.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem(TOKEN_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export { apiAxiosFormData };

export const ImageAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});
