import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { physicianTherapistFormValidation } from "./Validation";
import { RadioBtn } from "../../../Css";
import { useTranslation } from "react-i18next";
import { getCountryAsOption, getStateAsOptions } from "../../Utils";

const defaultValue = {
  physician_value: "",
  phy_fname: "",
  phy_lname: "",
  phy_email: "",
  phy_address1: "",
  phy_address2: "",
  phy_city: "",
  phy_state: "",
  phy_zipcode: "",
  phy_country: "",
  phy_primaryphone: "",
  phy_fax: "",
  phy_primary_hospital: "",
  phy_last_appoin_date: "",
  phy_next_appoin_date: "",
  primary_physician: 0,
};

export default function PhysicianTherapistForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const [typeOptions, setTypeOptions] = useState([]);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const maxDate = new Date().toISOString().substr(0, 10);
  var minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  var minDateFormatted = minDate.toISOString().substr(0, 10);
  const { t } = useTranslation();

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
    if (typeOptions.length > 0) {
      if (isEdit) {
        setInitialValues({
          ...initialValues,
          ...data,
        });
        const countryCode = country?.find(
          (country) => country.label === data.phy_country
        );
        handleCountryChange("", countryCode);
      } else {
        setInitialValues(defaultValue);
      }
    }
  }, [isEdit, data, typeOptions]);

  const getPhysicianTypes = async () => {
    try {
      const { data } = await ApiService.getPhysicianTypes();

      const mappedData = data.map((item, index) => ({
        label: item?.field_value,
        value: item?.id,
      }));
      setTypeOptions(mappedData);
    } catch (err) {}
  };

  useEffect(() => {
    // getState();
    getPhysicianTypes();
  }, []);

  const onSubmit = async (values) => {
    const type = typeOptions.find((x) => {
      if (x.label === values.physician_value) {
        return x.value;
      }
    });
    values.physiciantype = type.value;
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
          physician_value: data?.physician_value,
          physiciantype_value: values.physician_value,
        };
        const res = await ApiService.editPhysiciansAndTherapists(
          userId,
          payload
        );
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const payload = {
          ...values,
          physiciantype_value: values.physician_value,
        };
        const { data } = await ApiService.addPhysiciansAndTherapists(
          userId,
          payload
        );
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {}
  };

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("phy_state", "");
  };

  const scrollToError = () => {
    if (Object.keys(formikRef.current.errors).length > 0) {
      const firstErrorField = Object.keys(formikRef.current.errors)[0];
      const firstErrorFieldRef = document.getElementsByName(firstErrorField)[0];
      if (firstErrorFieldRef) {
        const topPosition =
          firstErrorFieldRef.getBoundingClientRect().top +
          window.pageYOffset -
          30;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={physicianTherapistFormValidation}
        validateOnMount={true}
        enableReinitialize
      >
        {({
          getFieldProps,
          values,
          setValues,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t(
                        "screens.physiciansTherapists.editPhysicianTherapist"
                      )}`
                    : `${t(
                        "screens.physiciansTherapists.addPhysicianTherapist"
                      )}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="physician_value"
                      label={`${t(
                        "screens.physiciansTherapists.physicianType"
                      )}`}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("physician_value")}
                        name="physician_value"
                        options={typeOptions.map((data) => {
                          return { label: data.label, value: data.label };
                        })}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                  {values?.physician_value === "Other" && (
                    <Grid item sm={3} xs={12}>
                      <FormGroup
                        name="physician_other"
                        label={`${t(
                          "screens.physiciansTherapists.physicianOther"
                        )}`}
                      >
                        <InputField {...getFieldProps("physician_other")} />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="phy_fname"
                      label={`${t("screens.formData.firstName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("phy_fname")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="phy_lname"
                      label={`${t("screens.formData.lastName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("phy_lname")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="phy_email"
                      label={`${t("screens.formData.email")}`}
                    >
                      <InputField {...getFieldProps("phy_email")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="phy_primaryphone"
                      label={`${t("screens.formData.primaryPhone")}`}
                      requiredField
                    >
                      <CustomPhoneInput
                        {...getFieldProps("phy_primaryphone")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="phy_fax"
                      label={`${t("screens.physiciansTherapists.fax")}`}
                    >
                      <CustomPhoneInput {...getFieldProps("phy_fax")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="phy_address1"
                      label={`${t("screens.formData.streetAddress")}`}
                    >
                      <InputField {...getFieldProps("phy_address1")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="phy_address2"
                      label={`${t("screens.formData.address2")}`}
                    >
                      <InputField {...getFieldProps("phy_address2")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_country"
                      label={`${t("screens.formData.country")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("phy_country")}
                        name="phy_country"
                        options={countryOptions}
                        placeholder={t("screens.formData.selectCountry")}
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_state"
                      label={`${t("screens.formData.stateProvinceRegion")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("phy_state")}
                        name="phy_state"
                        otherType="Other"
                        options={stateOptions}
                        placeholder={t("screens.formData.selectState")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_city"
                      label={`${t("screens.formData.city")}`}
                    >
                      <InputField {...getFieldProps("phy_city")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_zipcode"
                      label={`${t("screens.formData.zipCode")}`}
                    >
                      <InputField {...getFieldProps("phy_zipcode")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_primary_hospital"
                      label={`${t(
                        "screens.physiciansTherapists.primaryHospital"
                      )}`}
                    >
                      <InputField {...getFieldProps("phy_primary_hospital")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_last_appoin_date"
                      label={`${t(
                        "screens.physiciansTherapists.lastAppointmentDate"
                      )}`}
                    >
                      <InputField
                        {...getFieldProps("phy_last_appoin_date")}
                        type="date"
                        inputProps={{ max: maxDate }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="phy_next_appoin_date"
                      label={`${t(
                        "screens.physiciansTherapists.nextAppointmentDate"
                      )}`}
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("phy_next_appoin_date")}
                        inputProps={{ min: minDateFormatted }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="primary_physician"
                      label={`${t(
                        "screens.physiciansTherapists.primaryPhysician"
                      )}`}
                      className="pysician-checkbox-label"
                    >
                      <RadioBtn>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setValues({
                              ...values,
                              primary_physician:
                                values.primary_physician === 0 ? 1 : 0,
                            });
                          }}
                          checked={values.primary_physician === 1}
                        ></input>
                      </RadioBtn>
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={scrollToError}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.physicianUpdated")}`
              : `${t("screens.successMessage.physicianCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
