import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { precriptionFormValidation } from "./Validation";
import { useTranslation } from "react-i18next";

const defaultValue = {
  pres_medicationtype: "",
  pres_medication: "",
  dosage_input: "",
  dosage_value: "",
  pres_dosage: "",
  pres_startdate: "",
  pres_duration: "",
  pres_frequency: "",
  frequency2: "",
  pres_reasonforuse: "",
  dentures_time: "",
  pres_physicianname: "",
  pres_pharm_name: "",
  pres_pharm_phone: "",
  pres_prescription: "",
};

export default function PrescriptionAndMedicationForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
  prescriptionTypes,
  presDosageOptions,
  frequencyOptions,
  frequencyOptions2,
  physicianOptions,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  const durationOptions = [
    { value: "day", label: "Day(s)" },
    { value: "week", label: "Week(s)" },
    { value: "month", label: "Month(s)" },
    { value: "ongoing", label: "Ongoing" },
  ];

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
      });
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  const onSubmit = async (values) => {
    try {
      if (values?.pres_dosage) {
        const dose = presDosageOptions?.find(
          (x) => x.value === values?.pres_dosage
        );
        values.dosage_value = dose?.label;
      }
      if (values?.pres_medicationtype) {
        const dose = prescriptionTypes?.find(
          (x) => x.value === values?.pres_medicationtype
        );
        values.pres_medicationtype_value = dose?.label;
      }
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editPrescription(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addPrescription(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const scrollToError = () => {
    if (Object.keys(formikRef.current.errors).length > 0) {
      const firstErrorField = Object.keys(formikRef.current.errors)[0];
      const firstErrorFieldRef = document.getElementsByName(firstErrorField)[0];
      if (firstErrorFieldRef) {
        const topPosition =
          firstErrorFieldRef.getBoundingClientRect().top +
          window.pageYOffset -
          30;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={precriptionFormValidation}
        validateOnMount={true}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            {console.log("values: ", values)}
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t(
                        "screens.prescriptionMedication.editPrescriptionsMedications"
                      )}`
                    : `${t(
                        "screens.prescriptionMedication.addPrescriptionsMedications"
                      )}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="pres_medicationtype"
                      label={`${t(
                        "screens.prescriptionMedication.medicationType"
                      )}`}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("pres_medicationtype")}
                        name="pres_medicationtype"
                        options={prescriptionTypes}
                        value={values.pres_medicationtype}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="pres_medication"
                      label={`${t(
                        "screens.prescriptionMedication.medicationName"
                      )}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("pres_medication")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="dosage_input"
                      label={`${t("screens.prescriptionMedication.dosage")}`}
                    >
                      <InputField {...getFieldProps("dosage_input")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup name="pres_dosage" label={"."}>
                      <AutoComplete
                        {...getFieldProps("pres_dosage")}
                        name="pres_dosage"
                        options={presDosageOptions}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="pres_frequency"
                      label={`${t("screens.prescriptionMedication.frequency")}`}
                    >
                      <AutoComplete
                        {...getFieldProps("pres_frequency")}
                        name="pres_frequency"
                        options={frequencyOptions}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup name="frequency2" label={"."}>
                      <AutoComplete
                        {...getFieldProps("frequency2")}
                        name="frequency2"
                        options={frequencyOptions2}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="pres_startdate"
                      label={`${t("screens.prescriptionMedication.startDate")}`}
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("pres_startdate")}
                        value={
                          getFieldProps("pres_startdate").value &&
                          new Date(getFieldProps("pres_startdate").value)
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="pres_duration"
                      label={`${t("screens.prescriptionMedication.duration")}`}
                    >
                      <InputField {...getFieldProps("pres_duration")} />
                    </FormGroup>
                  </Grid>
                  {values?.pres_duration && (
                    <Grid item xs={12} sm={3}>
                      <FormGroup name="dentures_time" label={"."}>
                        <AutoComplete
                          {...getFieldProps("dentures_time")}
                          name="dentures_time"
                          options={durationOptions}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="pres_reasonforuse"
                      label={`${t(
                        "screens.prescriptionMedication.reasonForUse"
                      )}`}
                    >
                      <InputField {...getFieldProps("pres_reasonforuse")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="pres_physicianname"
                      label={`${t(
                        "screens.prescriptionMedication.prescribingPhysician"
                      )}`}
                    >
                      <AutoComplete
                        {...getFieldProps("pres_physicianname")}
                        name="pres_physicianname"
                        options={physicianOptions}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="pres_pharm_name"
                      label={`${t(
                        "screens.prescriptionMedication.pharmacyName"
                      )}`}
                    >
                      <InputField {...getFieldProps("pres_pharm_name")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="pres_pharm_phone"
                      label={`${t(
                        "screens.prescriptionMedication.pharmacyPhone"
                      )}`}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("pres_pharm_phone")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="pres_prescription"
                      label={`${t(
                        "screens.prescriptionMedication.prescriptionNumber"
                      )}`}
                    >
                      <InputField {...getFieldProps("pres_prescription")} />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={scrollToError}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.prescriptionMedicationUpdated")}`
              : `${t("screens.successMessage.prescriptionMedicationCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
