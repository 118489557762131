import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../Api/ApiServices";

export default function EditMemberProfile() {
  const [relationOptions, setRelationOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [memberData, setMemberData] = useState({});
  const [relation, setRelation] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const getMemberInformation = async () => {
    try {
      if (id) {
        const { data } = await ApiService.getMember(id);
        setMemberData(data);
        setRelation(data?.relation_id);
        setRole(data?.role_id);
      }
    } catch (error) {}
  };

  const getRelationships = async () => {
    try {
      const { data } = await ApiService.relationsEmergency();
      if (data?.length) {
        const mappedData = data.map((item, index) => {
          return {
            label: item.mem_rel_type,
            value: item?.id,
          };
        });
        const filteredData = mappedData.filter((item) => item?.value !== 1);
        setRelationOptions(filteredData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const getRoles = async () => {
    try {
      const { data } = await ApiService.getRoles();
      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item.name,
          value: item?.id,
        }));
        setRoleOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  useEffect(() => {
    getRelationships();
    getRoles();
    getMemberInformation();
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        ...memberData,
        relation_id: relation,
        role_id: role,
      };
      await ApiService.updateMember(id, payload);
      setLoading(false);
      navigate("/myFootprintID");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div className="main">
      <div className="container-forget">
        <form>
          {role !== 1 && (
            <>
              <h1>{t("screens.accountInformations.memberProfileEdit")}</h1>
              <div className="field-forget">
                <label>{t("screens.accountInformations.relation")}</label>
                <div style={{ position: "relative" }}>
                  <Select
                    fullWidth
                    onChange={(e) => setRelation(e.target?.value)}
                    value={relation}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #3CA899",
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "transparent",
                        },
                      "& .MuiSelect-icon": { color: "#AEAFB4" },
                      "& .MuiChip-root": {
                        color: "white",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3CA899",
                        },
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3CA899",
                      },
                      "&.Mui-focused .MuiChip-root": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                  >
                    {relationOptions?.length ? (
                      relationOptions?.map((item, i) => (
                        <MenuItem key={i} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="null">{`${t(
                        "screens.formData.noItemFound"
                      )}`}</MenuItem>
                    )}
                  </Select>
                </div>
              </div>
              <div className="field-forget">
                <label>{t("screens.accountInformations.permission")}</label>
                <div style={{ position: "relative" }}>
                  <Select
                    fullWidth
                    onChange={(e) => setRole(e?.target?.value)}
                    value={role}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #3CA899",
                        borderRadius: "20px",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "transparent",
                        },
                      "& .MuiSelect-icon": { color: "#AEAFB4" },
                      "& .MuiChip-root": {
                        color: "white",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3CA899",
                        },
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3CA899",
                      },
                      "&.Mui-focused .MuiChip-root": {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                  >
                    {roleOptions?.length ? (
                      roleOptions?.map((item, i) => (
                        <MenuItem key={i} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="null">{`${t(
                        "screens.formData.noItemFound"
                      )}`}</MenuItem>
                    )}
                  </Select>
                </div>
              </div>
            </>
          )}
          <button className="btn1-forget" onClick={handleUpdateProfile}>
            {loading
              ? `${t("screens.formData.updating")}`
              : `${t("screens.formData.update")}`}
          </button>
        </form>
      </div>
    </div>
  );
}
