import React, { useEffect } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import { useFormikContext } from "formik";
import StripeCreditCardFormData from "./StripeCardFormData";

function PaymentInfo({ onPrevious, setValueinFormData }) {
  const { values } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    setValueinFormData();
  }, [values]);

  return (
    <div>
      <Label>Order Confirmation</Label>
      <MainGrid>
        <Column>
          <Wrapper>
            <Header>
              <p>Personal Information</p>
            </Header>
            <Body>
              <Row>
                <Title>Name:</Title>
                <Text>
                  {values.firstname} {values.lastname}
                  {/* {firstNameUser} {lastNameUser} */}
                </Text>
              </Row>
              <Row>
                <Title>Email ID:</Title>
                <Text>{values.email}</Text>
              </Row>
              <Row>
                <Title>Phone Number:</Title>
                <Country>
                  <PhoneInput
                    disabled={true}
                    country={"us"}
                    enableAreaCodes={true}
                    value={`+${values?.phone_code}${values?.phoneNumber}`}
                    inputStyle={{
                      paddingTop: "10px",
                      height: "16px",
                      width: "auto",
                    }}
                  />
                </Country>
              </Row>
              <Row>
                <Title>Members</Title>
                <Text>{values.numberOfMembers}</Text>
              </Row>
              <Row>
                <Title>Amount:</Title>
                <Text>${values.totalAmmount}</Text>
              </Row>
            </Body>
          </Wrapper>
        </Column>
        <Column>
          <Wrapper>
            <Header>
              <p>Shipping Information</p>
            </Header>
            <Body>
              <Row>
                <Title>Name:</Title>
                <Text>
                  {values.fName} {values.lName}
                </Text>
              </Row>
              <Row>
                <Title>Address:</Title>
                <Text>
                  {values.address_line_1} {values.address_line_2}
                </Text>
              </Row>
              <Row>
                <Title>City, State, Zip:</Title>
                <Text>
                  {values.admin_area_2} {values.admin_area_1}{" "}
                  {values.billing_postal_code}
                </Text>
              </Row>
              <Row>
                <Title>Phone Number:</Title>
                <Country>
                  <PhoneInput
                    disabled={true}
                    country={"us"}
                    enableAreaCodes={true}
                    value={`+${values?.shipping_phone_code}${values?.shipping_phone_number}`}
                    inputStyle={{
                      paddingTop: "10px",
                      height: "16px",
                      width: "auto",
                    }}
                  />
                </Country>
              </Row>
            </Body>
          </Wrapper>
        </Column>
        <Column>
          <Wrapper>
            <Header>
              <p>
                Billing System{" "}
                {values?.hasMembershipNumber ? "" : `($${values.totalAmmount})`}
              </p>
            </Header>
            <Body>
              <StripeCreditCardFormData onPrevious={onPrevious} />
            </Body>
          </Wrapper>
        </Column>
      </MainGrid>
    </div>
  );
}

export default PaymentInfo;

const Wrapper = styled.div`
  font-family: sans-serif;
  //padding: 10px 0 10px 0px;
  text-align: center;
`;
const Header = styled.div`
  background-color: #1976d22e;
  // margin: 0 50px 0 250px;
  padding: 3px 10px;
  text-align: left;
  border-radius: 5px 5px 0 0;
  display: flex;

  p {
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  a {
    float: right;
    text-decoration: none;
    padding-left: 500px;
  }

  @media (max-width: 1060px) {
    margin: 0 200px;
  }
  @media (max-width: 950px) {
    margin: 0 100px;
  }
  @media (max-width: 750px) {
    margin: 0 50px;
  }
  @media (max-width: 650px) {
    margin: 0 12px;
    padding: 10px;
  }
`;
const Body = styled.div`
  background-color: #fff;
  // margin: 0 50px 0 250px;
  padding: 15px;
  display: block;
  text-align: left;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: 1060px) {
    margin: 0 200px;
  }
  @media (max-width: 950px) {
    margin: 0 100px;
  }
  @media (max-width: 750px) {
    margin: 0 50px;
  }
  @media (max-width: 650px) {
    margin: 0 12px;
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

const Title = styled.p`
  color: #000;
  font-size: 14px;
  font-family: "Omnes", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
`;
const Label = styled.p`
  margin: 0px 0px 0px 0px;
  text-align: center;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #06476c;
`;
const Text = styled.p`
  color: #313131;
  font-size: 16px;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 300;
  margin-bottom: 0;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: none;
    /* height: 41px !important;
    width: 100%; */
  }

  .react-tel-input .flag-dropdown {
    top: 7px;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: none;
  }

  .react-tel-input .selected-flag {
    padding: 0;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;

const MainGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  margin: 0px auto;
  max-width: 80%;

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
  @media (max-width: 1060px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;
  border: 1px solid #428bca;
  border-radius: 8px;

  .membershipInput {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;

    :focus {
      border: none;
    }
  }
`;

const Check = styled.div`
  padding: 0px 0px 10px 0px;
  text-align: left;

  button {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  input {
    transform: scale(1.5);
    margin-right: 10px;
  }

  a {
    font-family: sans-serif;
    text-decoration: underline;
    color: blue;
  }
`;
