import React from "react";

import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { get } from "lodash";

function InputField({
  id,
  name,
  label,
  autoComplete = "off",
  sx,
  helperText,
  onBlur,
  onChange,
  width,
  required,
  value,
  errorMessage,
  inputSx,
  boxStyle,
  hasError,
  InputProps,
  ...params
}) {
  const { errors, touched } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  return (
    <>
      <Box sx={{ ...BoxFieldStyle, ...boxStyle }}>
        <TextField
          fullWidth
          id={id}
          name={name}
          autoComplete={autoComplete}
          sx={{
            height: 35,
          }}
          InputProps={InputProps}
          width={width}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={fieldTouched && error}
          InputLabelProps={{
            shrink: false, // This prevents the label from floating to the top
          }}
          {...params}
        />
      </Box>
    </>
  );
}

export default InputField;

const BoxFieldStyle = {
  "& .mui-focused":{
    border: "0px solid #1175BB",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "sans-serif",
    border: "1px solid #1175BB",
    borderRadius: "6px",
    width: '100%',
    height: "37px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    top: 0,
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#FFF",
    // boxShadow:'none'
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
    padding: "5px 14px"
  },
  "& .MuiInputBase-root ": {
    // width: "230px",
    width: "100%",
  },
  "& .MuiFormControl-root": {
    width: "100%",
    padding: "0px 0px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1175BB",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#1175BB",
    },
    "& .MuiSvgIcon-root": {
      zIndex: 2,
    },
    "& .MuiButtonBase-root": {
      zIndex: 2,
    },
  },
  "& input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 100px #FFF inset !important",
    height: "30px",
    padding: 0,
    margin: "16px"
  },
  "@media (max-width: 600px)": {
    width: "99%",
  },
};
