import React, { useEffect, useMemo } from "react";

import useSWR from "swr";

import "./GlobalItemList.css";
import useAuth from "../../ContextProvider/AuthProvider";
import PawItemList from "../Common/PawItemList.js";
import ApiService from "../../Api/ApiServices.js";
import moment from "moment";

export default function EmergencyContact() {
  const { userId } = useAuth();

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  const { data: userList, mutate: fetchDataUser } = useSWR(
    userId ? `user${userId}` : null,
    async () => {
      const { data } = await ApiService.getSelfInfo(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const {
    data: emergenctData,
    mutate: fetchEmergencyData,
    isLoading,
  } = useSWR(
    userId ? `Emergencydata${userId}` : null,
    async () => {
      const { data } = await ApiService.getAllEmergencyUserId(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const {
    data: documentData,
    mutate: fetchDocumentData,
    isDocumentDataLoading,
  } = useSWR(
    userId ? `getDocuments${userId}` : null,
    async () => {
      const { data } = await ApiService.getDocuments(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: PhysiciansAndTherapistsData } = useSWR(
    userId ? `getPhysiciansAndTherapists${userId}` : null,
    async () => {
      const { data } = await ApiService.getPhysiciansAndTherapists(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: MedicalInsuranceData } = useSWR(
    userId ? `getMedicalInsurance${userId}` : null,
    async () => {
      const { data } = await ApiService.getMedicalInsurance(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: PrescriptionAndMidicationData } = useSWR(
    userId ? `getPrescriptionAndMidication${userId}` : null,
    async () => {
      const { data } = await ApiService.getPrescriptionAndMidication(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: AlleregiesData } = useSWR(
    userId ? `getAlleregies${userId}` : null,
    async () => {
      const { data } = await ApiService.getAlleregies(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: ConditionsData } = useSWR(
    userId ? `getConditions${userId}` : null,
    async () => {
      const { data } = await ApiService.getConditions(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: SurgeriesTreatmentsData } = useSWR(
    userId ? `getSurgeriesTreatments${userId}` : null,
    async () => {
      const { data } = await ApiService.getSurgeriesTreatments(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: ImmunizationsData } = useSWR(
    userId ? `getImmunizations${userId}` : null,
    async () => {
      const { data } = await ApiService.getImmunizations(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const { data: FamilyHistoryData } = useSWR(
    userId ? `getFamilyHistory${userId}` : null,
    async () => {
      const { data } = await ApiService.getFamilyHistory(userId);
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const emergencyColumns = useMemo(() => {
    return [
      {
        Header: "Name",
        textAlign: "center",
        accessor: "emer_fname",
        width: "12.5vw",
      },
      {
        Header: `Relation`,
        textAlign: "center",
        accessor: "emer_lname",
        width: "12.5vw",
      },
      {
        Header: "Primary Phone No",
        accessor: "emer_primaryphone",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Secondary Phone No",
        accessor: "emer_secondaryphone",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Relationship",
        accessor: "emer_relation",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Address",
        accessor: "emer_address1",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "City",
        accessor: "emer_city",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "State / Province / Region",
        accessor: "emer_state",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Zipcode",
        accessor: "emer_zipcode",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Country",
        accessor: "emer_country",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: "Email",
        accessor: "emer_email",
        textAlign: "center",
        width: "12.5vw",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const physiciansColumns = useMemo(() => {
    return [
      {
        Header: "Physician Type",
        textAlign: "center",
        accessor: "physician_value",
        width: "12.5vw",
      },
      {
        Header: "First Name",
        textAlign: "center",
        accessor: "phy_fname",
        width: "12.5vw",
      },
      {
        Header: "Last Name",
        textAlign: "center",
        accessor: "phy_lname",
        width: "12.5vw",
      },
      {
        Header: "Phone",
        textAlign: "center",
        accessor: "phy_primaryphone",
        width: "12.5vw",
      },
      {
        Header: "Address",
        textAlign: "center",
        accessor: "phy_address1",
        width: "12.5vw",
      },
      {
        Header: "City",
        textAlign: "center",
        accessor: "phy_city",
        width: "12.5vw",
      },
      {
        Header: "State / Province / Region",
        textAlign: "center",
        accessor: "phy_state",
        width: "12.5vw",
      },
      {
        Header: "Zipcode",
        textAlign: "center",
        accessor: "phy_zipcode",
        width: "12.5vw",
      },
      {
        Header: "E-mail",
        textAlign: "center",
        accessor: "phy_email",
        width: "12.5vw",
      },
      {
        Header: "Primary Hospital",
        textAlign: "center",
        accessor: "phy_primary_hospital",
        width: "12.5vw",
      },
      {
        Header: "Last Appointment",
        textAlign: "center",
        accessor: "phy_last_appoin_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.phy_last_appoin_date) {
            return (
              <div>
                {moment(row?.original?.phy_last_appoin_date).format(
                  "MM/DD/YYYY"
                )}
              </div>
            );
          }
        },
      },
      {
        Header: "Next Appointment",
        textAlign: "center",
        accessor: "phy_next_appoin_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.phy_next_appoin_date) {
            return (
              <div>
                {moment(row?.original?.phy_next_appoin_date).format(
                  "MM/DD/YYYY"
                )}
              </div>
            );
          }
        },
      },
    ];
  }, []);

  const medicalInsuranceColumns = useMemo(() => {
    return [
      {
        Header: "Member Name",
        textAlign: "center",
        accessor: "ins_member",
        width: "12.5vw",
      },
      {
        Header: "Subscriber Name",
        textAlign: "center",
        accessor: "ins_subcriber",
        width: "12.5vw",
      },
      {
        Header: "Insurance Provider Name",
        textAlign: "center",
        accessor: "ins_provider",
        width: "12.5vw",
      },
      {
        Header: "Phone",
        textAlign: "center",
        accessor: "ins_providerphone",
        width: "12.5vw",
      },
      {
        Header: "Member ID",
        textAlign: "center",
        accessor: "ins_memberid",
        width: "12.5vw",
      },
      {
        Header: "Group ID",
        textAlign: "center",
        accessor: "ins_group",
        width: "12.5vw",
      },
      {
        Header: "Plan Name",
        textAlign: "center",
        accessor: "ins_plan",
        width: "12.5vw",
      },
      {
        Header: "Effective Date",
        textAlign: "center",
        accessor: "ins_effective_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.ins_effective_date) {
            return (
              <div>
                {moment(row?.original?.ins_effective_date).format("MM/DD/YYYY")}
              </div>
            );
          }
        },
      },
    ];
  }, []);

  const prescriptionsColumns = useMemo(() => {
    return [
      {
        Header: "Medication Type",
        textAlign: "center",
        accessor: "medication_value",
        width: "12.5vw",
      },
      {
        Header: "Medication",
        textAlign: "center",
        accessor: "pres_medication",
        width: "12.5vw",
      },
      {
        Header: "Dosage",
        textAlign: "center",
        accessor: "dosage_input",
        width: "12.5vw",
      },
      {
        Header: "Frequency",
        textAlign: "center",
        accessor: "frequency2",
        width: "12.5vw",
      },
      {
        Header: "Reason for Use",
        textAlign: "center",
        accessor: "pres_reasonforuse",
        width: "12.5vw",
      },
      {
        Header: "Physicians Name",
        textAlign: "center",
        accessor: "pres_physicianname",
        width: "12.5vw",
      },
      {
        Header: "Pharmacy Name",
        textAlign: "center",
        accessor: "pres_pharm_name",
        width: "12.5vw",
      },
      {
        Header: "Pharmacy Phone",
        textAlign: "center",
        accessor: "pres_pharm_phone",
        width: "12.5vw",
      },
      {
        Header: "Prescription #",
        textAlign: "center",
        accessor: "pres_prescription",
        width: "12.5vw",
      },
    ];
  }, []);

  const allergiesColumns = useMemo(() => {
    return [
      {
        Header: "Allergy Type",
        textAlign: "center",
        accessor: "allergytype_value",
        width: "10.5vw",
      },
      {
        Header: "Allergy Name",
        textAlign: "center",
        accessor: "allergyname_value",
        width: "12.5vw",
      },
      {
        Header: "Reaction",
        textAlign: "center",
        accessor: "allergyreaction",
        width: "12.5vw",
      },
    ];
  }, []);

  const conditionsColumns = useMemo(() => {
    return [
      {
        Header: "Condition",
        textAlign: "center",
        accessor: "cond_info",
        width: "12.5vw",
      },
      {
        Header: "Condition Notes",
        textAlign: "center",
        accessor: "cond_notes",
        width: "12.5vw",
      },
    ];
  }, []);

  const surgeriesColumns = useMemo(() => {
    return [
      {
        Header: "Surgery Date",
        textAlign: "center",
        accessor: "surgery_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.surgery_date) {
            return (
              <div>
                {moment(row?.original?.surgery_date).format("MM/DD/YYYY")}
              </div>
            );
          }
        },
      },
      {
        Header: "Surgery Type",
        textAlign: "center",
        accessor: "surgery_type",
        width: "12.5vw",
      },
      {
        Header: "Physician Name",
        textAlign: "center",
        accessor: "physician_name",
        width: "12.5vw",
      },
      {
        Header: "Surgery Notes",
        textAlign: "center",
        accessor: "surgey_notes",
        width: "12.5vw",
      },
      {
        Header: "Post Op Complications",
        textAlign: "center",
        accessor: "surgery_pos",
        width: "12.5vw",
      },
      {
        Header: "Anesthesia Complications",
        textAlign: "center",
        accessor: "surgery_anes",
        width: "12.5vw",
      },
      {
        Header: "Chronic Anticoagulation",
        textAlign: "center",
        accessor: "sergery_chronic",
        width: "12.5vw",
      },
      {
        Header: "Ivc Filter",
        textAlign: "center",
        accessor: "ivc_filter",
        width: "12.5vw",
      },
      {
        Header: "Surgery Implant",
        textAlign: "center",
        accessor: "sergery_impan",
        width: "12.5vw",
      },
    ];
  }, []);

  const immunizationsColumns = useMemo(() => {
    return [
      {
        Header: "Vaccine Type",
        textAlign: "center",
        accessor: "vaccineslist",
        width: "12.5vw",
      },
      {
        Header: "Date",
        textAlign: "center",
        accessor: "immu_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          const immuDate = row?.original?.immu_date;
          if (!immuDate) return null;
          return (
            <div>
              {Object.values(immuDate).map((value, index) => (
                <div key={index}>{moment(value).format("MM/DD/YYYY")}</div>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Information",
        textAlign: "center",
        accessor: "immu_info",
        width: "12.5vw",
        Cell: ({ row }) => {
          const immuInfo = row?.original?.immu_info;
          if (!immuInfo) return null;
          return (
            <div>
              {Object.values(immuInfo).map((value, index) => (
                <div key={index}>{value}</div>
              ))}
            </div>
          );
        },
      },
    ];
  }, []);

  const familyColumns = useMemo(() => {
    return [
      {
        Header: "Family Member",
        textAlign: "center",
        accessor: "family_member",
        width: "12.5vw",
      },
      {
        Header: "Deceased",
        textAlign: "center",
        accessor: "deceased",
        width: "12.5vw",
      },
      {
        Header: "Date of Birth",
        textAlign: "center",
        accessor: "date_of_birth",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.date_of_birth) {
            return (
              <div>
                {moment(row?.original?.date_of_birth).format("MM/DD/YYYY")}
              </div>
            );
          }
        },
      },
      {
        Header: "Status",
        textAlign: "center",
        accessor: "status",
        width: "12.5vw",
      },
    ];
  }, []);

  const documentsColumns = useMemo(() => {
    return [
      {
        Header: "Document Date",
        textAlign: "center",
        accessor: "docum_date",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.docum_date) {
            return (
              <div>
                {moment(row?.original?.docum_date).format("MM/DD/YYYY")}
              </div>
            );
          }
        },
      },
      {
        Header: "Document Name",
        textAlign: "center",
        accessor: "docum_name",
        width: "12.5vw",
      },
      {
        Header: "Document File",
        textAlign: "center",
        accessor: "docum_file",
        width: "12.5vw",
        Cell: ({ row }) => {
          if (row?.original?.docum_file) {
            return (
              <a
                target="_blank"
                href={`/documents/${row?.original?.id}`}
                rel="noreferrer"
              >
                <img alt="" src="/assets/images/pdf_icon.png" />
              </a>
            );
          }
        },
      },
    ];
  }, []);

  const emergencyProps = {
    label: "Emergency Contacts",
    isLoading: isLoading,
    columns: emergencyColumns,
    columnsData: emergenctData,
    details: " There are no emergency contact details for this user.",
    isSelectHide: true,
    addButton: false,
  };
  const physiciansProps = {
    label: "Physicians & Therapists",
    isLoading: isLoading,
    columns: physiciansColumns,
    columnsData: PhysiciansAndTherapistsData,
    details: " There are no Physicians & Therapists details for this user.",
    isSelectHide: true,
    addButton: false,
  };
  const medicalInsuranceProps = {
    label: "Medical Insurance",
    isLoading: isLoading,
    columns: medicalInsuranceColumns,
    columnsData: MedicalInsuranceData,
    details: " There are no Medical Insurance details for this user.",
    isSelectHide: true,
    addButton: false,
  };
  const prescriptionsProps = {
    label: "Prescriptions & Medications",
    isLoading: isLoading,
    columns: prescriptionsColumns,
    columnsData: PrescriptionAndMidicationData,
    details: " There are no Prescriptions & Medications details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const allergiesProps = {
    label: "Allergies",
    isLoading: isLoading,
    columns: allergiesColumns,
    columnsData: AlleregiesData,
    details: " There are no Allergies details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const conditionsProps = {
    label: "Conditions",
    isLoading: isLoading,
    columns: conditionsColumns,
    columnsData: ConditionsData,
    details: " There are no Conditions details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const surgeriesProps = {
    label: "Surgeries & Treatments",
    isLoading: isLoading,
    columns: surgeriesColumns,
    columnsData: SurgeriesTreatmentsData,
    details: " There are no Surgeries & Treatments details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const immunizationsProps = {
    label: "Immunizations",
    isLoading: isLoading,
    columns: immunizationsColumns,
    columnsData: ImmunizationsData,
    details: " There are no Immunizations details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const familyProps = {
    label: "Family History",
    isLoading: isLoading,
    columns: familyColumns,
    columnsData: FamilyHistoryData,
    details: " There are no Family History details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  const documentProps = {
    label: "Document",
    isLoading: isDocumentDataLoading,
    columns: documentsColumns,
    columnsData: documentData,
    details: " There are no document details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  return (
    <>
      <div className="emergency-profile-container">
        <div className="personal-information container">
          <div className="form-group emergency-personal-information">
            <div className="table-responsive col-sm-12 table-title-contents">
              <div className="">
                <h3 className="table-title-info ng-binding">
                  Personal Information
                </h3>
                <div className="row flex-row">
                  <div className="col-sm-9">
                    <div className="emergency-profile-info-bottom">
                      <div className="row">
                        <div className="col-sm-5">
                          <label className="ng-binding">Member Name:</label>
                        </div>
                        <div className="col-sm-7">
                          <span className="ng-binding">
                            {userList?.user_fname} {userList?.user_lname}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5">
                          <label className="ng-binding">Email:</label>
                        </div>
                        <div className="col-sm-7">
                          <span className="ng-binding">
                            {userList?.user_email}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5">
                          <label className="ng-binding">User Role:</label>
                        </div>
                        <div className="col-sm-7">
                          <span className="ng-binding">
                            {userList?.user_role}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group avatar-container">
                      <img
                        alt=""
                        width="129"
                        height="129"
                        src={userList?.avatar}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="emergency-profile-title">Medical History Summary</div>
        <PawItemList {...emergencyProps} />
        <PawItemList {...physiciansProps} />
        <PawItemList {...medicalInsuranceProps} />
        <PawItemList {...prescriptionsProps} />
        <PawItemList {...allergiesProps} />
        <PawItemList {...conditionsProps} />
        <PawItemList {...surgeriesProps} />
        <PawItemList {...immunizationsProps} />
        <PawItemList {...familyProps} />
        <PawItemList {...documentProps} />
      </div>
    </>
  );
}
