import React from "react";

const Footer = () => {
  return (
    <div ng-hide="hideFooter" className="footer-sections show">
      <div className="container-fluid">
        <div className="row flex-row">
          <div className="col-sm-6">
            <div className="footer-data-left">
              <div>
                <p className="footer-content-block footer-con-style">
                  FOOTPRINTID® is a convenient way to store and quickly access
                  important medical information like allergens, immunizations,
                  prescriptions, emergency contacts and more.
                </p>
                <div className="row flex-row">
                  <div className="col-md-6 footer-second-part">
                    <div className="footer-link">
                      <li>
                        <a href="/er" target="_self">
                          Emergency Login
                        </a>
                      </li>
                      <li>
                        <a href="/why-footprint" target="_self">
                          About Us
                        </a>
                      </li>
                      <li>
                        <a href="/" target="_self">
                          Compare Services
                        </a>
                      </li>
                      <li>
                        <a href="/" target="_self">
                          Blog
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-6 footer-second-part">
                    <div className="footer-link">
                      <li>
                        <a href="/resources" target="_self">
                          Resources
                        </a>
                      </li>
                      <li>
                        <a href="/how-it-works" target="_self">
                          How it Works
                        </a>
                      </li>
                      <li>
                        <a href="/" target="_self">
                          FAQs
                        </a>
                      </li>
                      <li>
                        <a href="/privacy-policy" target="_self">
                          Privacy Policy
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div className="visit-pawprint-footer">
                <p className="footer-content-block">
                  Want a Footprint for your pet?
                </p>
                <a
                  className="footer-back underline text-inner-style"
                  rel="noreferrer"
                  href="/"
                >
                  Visit PawprintID® <img src="/assets/svg/right.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 text-right">
            <div className="footer-left-content">
              <div className="text-align-end footer-left-right">
                <div className="footer-back">
                  <img src="/assets/images/location-alt.png" alt="" />
                  P.O. Box 2412 Livingston, NJ 07039
                  <p></p>
                </div>
                <div>
                  <img src="/assets/images/phone.png" alt="" />
                  <a href="tel:+1(855)374-3411" className="footer-back">
                    +1(855)374-3411
                  </a>
                </div>
                <div>
                  <img src="/assets/images/email-fill.png" alt="" />
                  <a
                    href="mailto:support@footprintid.com"
                    className="underline footer-back"
                  >
                    support@footprintid.com
                  </a>
                </div>
              </div>
              <div className="social-icons-sections">
                <a
                  href="http://facebook.com/footprintid"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/svg/facebook.svg" alt="" />
                </a>
                <a
                  href="http://twitter.com/footprintid"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="assets/svg/twitter.svg" alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/footprintid/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/assets/svg/linkin.svg" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/footprint_i_d/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="assets/svg/instagram.svg" alt="" />
                </a>
                <a
                  href="https://www.pinterest.com/FootprintId1/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/assets/svg/pinterest.svg" alt="" />
                </a>
              </div>
              <div className="footer-btn-inner">
                <div>
                  <a
                    className="footer-btn-section"
                    href="https://www.apple.com/app-store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/images/appleIcon.png" alt="" />
                  </a>
                </div>
                <div>
                  <a
                    className="footer-btn-section"
                    href="https://play.google.com/store/apps"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/images/playStore.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="footer-bottom">
            @2024 Copyright FootprintID®. All Right Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
