import React, { useEffect } from "react";
import styled from "styled-components";

function SuccessPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Main>
      <Label>Registration Completed</Label>
      <SubLabel>
        Thank you for registering with FootprintID®. You have taken the first
        step towards protecting yourself and your family. You will receive two
        emails shortly, one confirming your order, and one with an activation
        link to safeguard your identity. Once you have activated your
        membership, you will be able to start setting up the medical history and
        information for each member.
      </SubLabel>
    </Main>
  );
}

export default SuccessPage;

const Label = styled.p`
  margin: 0px 0px 0px 0px;
  text-align: center;
  font-size: 20px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #06476c;
`;

const Main = styled.div`
  max-width: 1400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45vh;
`;
const SubLabel = styled.p`
  color: #069;
  font-weight: bold;
`;
