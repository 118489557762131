import React, { useState } from "react";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import DetailsModal from "./DetailsModal";
import { Scroll } from "../../Css";
import { useTranslation } from "react-i18next";

export default function PawDataView(props) {
  const {
    label = "",
    askConfirmation,
    setAskConfirmation,
    onDelete,
    isShow,
    title2,
  } = props;
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] =
    useState(false);
  const { t } = useTranslation();

  const onDeleteConfirm = async () => {
    try {
      await onDelete();
      setShowDeleteSuccessMessage(true);
    } catch (e) {
      console.error("Error in delete");
    }
  };

  const ButtonItem = [
    {
      name: `${t("screens.familyHistory.yes")}`,
      onClick: () => {
        setAskConfirmation(false);
        onDeleteConfirm();
      },
    },
    {
      name: `${t("screens.familyHistory.no")}`,
      onClick: () => {
        setAskConfirmation(false);
      },
    },
  ];
  const successPopupButton = [
    {
      name: "ok",
      onClick: () => {
        setShowDeleteSuccessMessage(false);
      },
    },
  ];

  return (
    <React.Fragment>
      {isShow && (
        <Scroll>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <MainBox>
              <CustomTable
                style={props.detailsData?.length !== 2 ? { width: "50%" } : {}}
              >
                <CustomTableHeader>
                  <Box
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "start",
                    }}
                  >
                    {label}
                  </Box>
                </CustomTableHeader>
                <Box sx={{ padding: "10px 20px" }}>
                  {props.detailsData?.[0]?.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: { xs: "15px", sm: "20px", md: "50px" },
                          width: { xs: "100%" },
                          overflowX: { xs: "auto" },
                        }}
                      >
                        <Typography sx={TextStyle}>{item?.name}</Typography>
                        <Typography sx={TextStyle}>
                          {item?.value || "-"}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </CustomTable>
              {props.detailsData?.length === 2 && (
                <CustomTable>
                  <CustomTableHeader>
                    <Box sx={{ fontSize: "18px", fontWeight: 600 }}>
                      {title2 || `${t("screens.formData.contactInformation")}`}
                    </Box>
                  </CustomTableHeader>
                  <Box sx={{ padding: "10px 20px" }}>
                    {props.detailsData?.[1]?.map((item, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={TextStyle}>{item?.name}</Typography>
                          <Typography sx={TextStyle}>
                            {item?.value || "NA"}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </CustomTable>
              )}
            </MainBox>
          </Box>
        </Scroll>
      )}
      {askConfirmation && (
        <>
          <DetailsModal
            label={`${t("screens.formData.performDeleteAction")}`}
            open={askConfirmation}
            severity="warning"
            buttonList={ButtonItem}
            handleClose={() => setAskConfirmation(!askConfirmation)}
          />
        </>
      )}
      {showDeleteSuccessMessage && (
        <>
          <DetailsModal
            label={`${t("screens.successMessage.dataDeleted")}`}
            open={showDeleteSuccessMessage}
            severity="success"
            buttonList={successPopupButton}
            handleClose={() =>
              setShowDeleteSuccessMessage(!showDeleteSuccessMessage)
            }
          />
        </>
      )}
    </React.Fragment>
  );
}

const CustomTableHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#1976d22e",
  padding: "10px 20px",
  borderBottom: "1px solid #1175BB",
}));

const TextStyle = {
  color: "black",
  fontSize: "16px",
  fontWeight: 400,
  paddingTop: "5px",
  textAlign: "start",
};

const CustomTable = styled("div")(() => ({
  border: "1px solid #1175BB",
  width: "100%",
  borderRadius: "10px",
  margin: "20px 0px 10px 0px",
  height: "auto",
  boxShadow: "0px 50px 100px 0px rgba(0, 75, 117, 0.30)",
  "@media (max-width: 900px)": {
    width: "100% !important",
  },
}));

const MainBox = styled("div")(() => ({
  display: "flex",
  gap: "20px",

  "@media (max-width: 900px)": {
    flexDirection: "column",
    gap: "0px",
  },
}));
