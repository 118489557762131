import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { EmergencyFormValidation } from "./Validation";
import { useTranslation } from "react-i18next";
import { getCountryAsOption, getStateAsOptions } from "../../Utils";

const defaultValue = {
  emer_fname: "",
  emer_lname: "",
  emer_email: "",
  emer_relation_id: "",
  emer_other_relation: "",
  emer_address1: "",
  emer_address2: "",
  emer_city: "",
  emer_state: "",
  emer_zipcode: "",
  emer_country: "",
  emer_primaryphone: "",
  emer_secondaryphone: "",
};

export default function EmergencyContactsForm({
  isEdit,
  data,
  setIsAdd,
  fetchEmergencyData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [relationOptions, setRelationOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const { t } = useTranslation();

  const getRelationships = async () => {
    try {
      const { data } = await ApiService.relationsEmergency();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item.mem_rel_type,
          value: String(index + 1),
        }));
        setRelationOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        emer_relation_id: data?.emer_relation_id?.toString(),
      });
      const countryCode = country?.find(
        (country) => country.label === data.emer_country
      );
      handleCountryChange("", countryCode);
    } else {
      setInitialValues(defaultValue);
      setStateOptions([]);
    }
  }, [isEdit, data]);

  useEffect(() => {
    getRelationships();
    // getState();
  }, []);

  const onSubmit = async (values) => {
    const type = relationOptions.find((x) => {
      if (x.value === values.emer_relation_id) {
        return x.label;
      }
    });
    values.emer_relation_value = type.label;
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editEmergency(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchEmergencyData();
        }
      } else {
        const { data } = await ApiService.addEmergency(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchEmergencyData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("emer_state", "");
  };

  const scrollToError = () => {
    if (Object.keys(formikRef.current.errors).length > 0) {
      const firstErrorField = Object.keys(formikRef.current.errors)[0];
      const firstErrorFieldRef = document.getElementsByName(firstErrorField)[0];
      if (firstErrorFieldRef) {
        const topPosition =
          firstErrorFieldRef.getBoundingClientRect().top +
          window.pageYOffset -
          30;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={EmergencyFormValidation}
        validateOnMount={true}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            {console.log("values: ", values)}
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #1175BB", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? t("screens.emergency.editEmergency")
                    : t("screens.emergency.addEmergencyContact")}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="emer_fname"
                      label={t("screens.emergency.firstName")}
                      requiredField
                    >
                      <InputField {...getFieldProps("emer_fname")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="emer_lname"
                      label={t("screens.emergency.lastName")}
                      requiredField
                    >
                      <InputField {...getFieldProps("emer_lname")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="emer_email"
                      label={t("screens.emergency.email")}
                    >
                      <InputField {...getFieldProps("emer_email")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="emer_relation_id"
                      label={t("screens.emergency.relationShip")}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("emer_relation_id")}
                        name="emer_relation_id"
                        options={relationOptions}
                        placeholder={t("screens.emergency.selectARelationship")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                {relationOptions.find(
                  (key) => key.value === values.emer_relation_id
                )?.label === "Other" && (
                  <Grid
                    container
                    spacing={3}
                    columns={12}
                    style={{ marginTop: "-7px" }}
                  >
                    <Grid item sm={3} xs={12}>
                      <FormGroup
                        name="emer_other_relation"
                        label={t("screens.emergency.relationName")}
                      >
                        <InputField {...getFieldProps("emer_other_relation")} />
                      </FormGroup>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="emer_address1"
                      label={t("screens.emergency.streetAddress")}
                    >
                      <InputField {...getFieldProps("emer_address1")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="emer_address2"
                      label={t("screens.emergency.address2")}
                    >
                      <InputField {...getFieldProps("emer_address2")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="emer_country"
                      label={t("screens.emergency.country")}
                    >
                      <AutoComplete
                        {...getFieldProps("emer_country")}
                        name="emer_country"
                        options={countryOptions}
                        placeholder={t("screens.formData.selectCountry")}
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="emer_state"
                      label={t("screens.emergency.state")}
                    >
                      <AutoComplete
                        {...getFieldProps("emer_state")}
                        name="emer_state"
                        otherType="Other"
                        options={stateOptions}
                        placeholder={t("screens.formData.selectState")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="emer_city"
                      label={t("screens.emergency.city")}
                    >
                      <InputField {...getFieldProps("emer_city")} />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="emer_zipcode"
                      label={t("screens.emergency.zipCode")}
                    >
                      <InputField {...getFieldProps("emer_zipcode")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="emer_primaryphone"
                      label={t("screens.emergency.primaryPhone")}
                      requiredField
                    >
                      <CustomPhoneInput
                        {...getFieldProps("emer_primaryphone")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="emer_secondaryphone"
                      label={t("screens.emergency.secondaryPhone")}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("emer_secondaryphone")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    onClick={scrollToError}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.emergencyContactUpdated")}`
              : `${t("screens.successMessage.emergencyContactCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
