import { useFormikContext } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import FormGroup from "../../Components/Common/FormGroup";
import InputField from "../../Components/Common/InputField";
import ApiService from "../../Frontend/Api/ApiServices";
import HowitWorkImg from "../../Frontend/Assets/Images/membershipCard.webp";
import InputMask from "react-input-mask";

function AlreadyMembership() {
  const [error, setError] = useState("");
  const [membershipCardDetails, setMembershipCardDetails] = useState([]);

  const { setFieldValue, values, getFieldProps } = useFormikContext();

  function formatUserName(number) {
    if (number) {
      const numericInput = number?.replace(/[^\d]/g, "");
      if (numericInput.length <= 2) {
        return numericInput;
      } else if (numericInput.length <= 5) {
        return `${numericInput.slice(0, 2)}-${numericInput.slice(2)}`;
      } else if (numericInput.length <= 8) {
        return `${numericInput.slice(0, 2)}-${numericInput.slice(
          2,
          5
        )}-${numericInput.slice(5, 8)}`;
      } else {
        return `${numericInput.slice(0, 2)}-${numericInput.slice(
          2,
          5
        )}-${numericInput.slice(5, 8)}`;
      }
    }
  }

  const checkMembershipCard = async () => {
    try {
      if (!values?.membershipNo || !values?.accessCode) {
        setError("You Must Enter At Least One Membership Card to Continue");
      }
      if (values?.membershipNo && values?.accessCode) {
        const payload = {
          membershipNo: values?.membershipNo,
          accessCode: values?.accessCode,
        };
        const { data } = await ApiService.checkMembershipCard(payload);
        console.log("data: ", data);
        if (data?.err) {
          setError(data?.err);
          return;
        }
        setError("");
        setMembershipCardDetails((prev) => {
          return [...prev, data];
        });
        setFieldValue("membershipNo", "");
        setFieldValue("accessCode", "");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onSubmitMembership = async () => {
    try {
      const validMembershipCards = membershipCardDetails?.map(item => {
        return {
          id: item?.id,
          access_code: item?.access_code,
          membershipNo: item?.membership_number,
        }
      });
      if (membershipCardDetails?.length) {
        const payload = {
          account: {
            user_fname: values?.firstname,
            user_lname: values?.lastname,
            user_email: values?.email,
            user_password: values?.password,
            payment_status: 1,
          },
          validMembershipCards: validMembershipCards,
        };
        const { data } = await ApiService.userRegisterTags(payload);
        setFieldValue("isAlreadyMemSubmitted", true);
        console.log("data:=-=- ", data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Main style={{ marginTop: 20 }}>
      <div className="panel panel-default member-ship-block">
        <div className="panel-heading">
          <h3 className="panel-title ng-binding">Membership</h3>
        </div>
        <div className="panel-body">
          <p className="ng-binding" style={{ color: "red" }}>
            {error}
          </p>
          <form className="register-membership ng-valid ng-dirty ng-valid-parse">
            <div className="inner-container" ng-show="tagsMode == 'enter'">
              <div className="row">
                <div className="col-sm-12">
                  <label
                    className="error ng-binding"
                    ng-bind="membership_err"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label
                      className="control-label ng-binding"
                      htmlFor="membershipNo"
                    >
                      Membership No
                    </label>
                    {/* <FormGroup
                      label=""
                      name="membershipNo"
                      labelClassName="margin-top"
                      requiredField
                    >
                      <InputField
                        type="text"
                        name="membershipNo"
                        placeholder="__-___-___"
                        mask="__-___-___"
                        {...getFieldProps("membershipNo")}
                        value={formatUserName(values.membershipNo)}
                      />
                    </FormGroup> */}
                    <FormGroup
                      label=""
                      name="membershipNo"
                      labelClassName="margin-top"
                      requiredField
                    >
                      <InputMask
                        mask="99-999-999"
                        maskChar="_"
                        {...getFieldProps("membershipNo")}
                      >
                        {(inputProps) => (
                          <InputField
                            type="text"
                            name="membershipNo"
                            className="textarea"
                            placeholder="__-___-___"
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label
                      className="control-label ng-binding"
                      htmlFor="access-code"
                    >
                      Access Code
                    </label>
                    <FormGroup
                      label=""
                      name="accessCode"
                      labelClassName="margin-top"
                    >
                      <InputField
                        type="text"
                        name="accessCode"
                        {...getFieldProps("accessCode")}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 save-btn">
                  <div className="form-group member-btn">
                    <button
                      className="btn member-btn btn-green form-control modal-next"
                      type="button"
                      role="button"
                      aria-disabled="false"
                      onClick={checkMembershipCard}
                    >
                      +
                      <span ng-hide="checkingMembership" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 Membership-data">
                  <p className="ng-binding">
                    Enter the Membership Number and Access Code From
                  </p>
                  <p className="ng-binding">
                    Your Membership Card or Multi Function Tag
                  </p>
                  <p className="ng-binding">Click On The + Sign</p>
                  <p className="ng-binding">
                    Continue Adding All Of Your Memberships The Same Way
                  </p>
                  <p className="ng-binding">
                    Once All Of Your Memberships Have Been Added Click On Save
                  </p>
                </div>
              </div>
              <div className="row">
                {membershipCardDetails?.map((item) => {
                  return (
                    <div
                      class="col-md-3 col-sm-12"
                      ng-repeat="card in validMembershipCards"
                    >
                      <img src={HowitWorkImg} /> <br />
                      <strong>
                        {item?.membership_number}
                      </strong>
                    </div>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <hr />
                  {/* <h5 className="ng-binding">
                    No of Members:
                    <span className="pull-right ng-binding">{membershipCardDetails?.membership_number ? 1 : 0}</span>
                  </h5> */}
                </div>
              </div>
              <div className="member-of-number">
                No of Members:
                <div className="pull-right ng-binding">
                  {membershipCardDetails?.length ? membershipCardDetails?.length : 0}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 save-btn">
                  <button
                    className="btn btn-primary btn-fixed pull-right"
                    type="button"
                    role="button"
                    aria-disabled="false"
                    disabled={!membershipCardDetails?.length}
                  >
                    <div className="ng-binding" onClick={onSubmitMembership}>
                      Save
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
}

export default AlreadyMembership;

const Main = styled.div`
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;

  .btn-footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  @media (max-width: 650px) {
    // margin: 0 12px;
    padding: 10px;
  }

  .form-group {
    text-align: left;
  }
  select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid #1175bb !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .member-ship-block .userIcon input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    background-color: var(--white-color-600);
    border: 1px solid #1175bb !important;
    border-radius: 6px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  .save-btn {
    display: flex;
    justify-content: end;
  }
  .save-btn button {
    width: fit-content;
    color: #fff;
    padding: 6px 12px;
    border-radius: 11px;
    background-color: #1175bb;
    margin: 15px 0 5px;
    font-weight: 600;
  }
`;
