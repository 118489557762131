import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  ButtonBoxStyle,
} from "../../Css";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import Layout from "../Side bar/Layout";
import { updateShippingInfoValidation } from "../../../From/Validation";
import AutoComplete from "../Common/AutoComplete";
import CustomPhoneInput from "../Common/PhoneInput";
import { useTranslation } from "react-i18next";

const defaultValue = {
  address1: "",
  address2: "",
  city: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  state: "",
  zipCode: "",
};

export default function UpdateShippingInformation() {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  const getState = async () => {
    try {
      const { data } = await ApiService.stateList();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item?.state,
          value: item?.id,
        }));
        setStateOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getShippingData = async () => {
    try {
      const { data } = await ApiService.getShippingInfo();
      const shippingInfo = data?.shippingInfo;
      setInitialValues({
        ...initialValues,
        address1: shippingInfo?.address1,
        address2: shippingInfo?.address2,
        city: shippingInfo?.city,
        firstName: shippingInfo?.firstName,
        lastName: shippingInfo?.lastName,
        phoneNumber: shippingInfo?.phoneNumber,
        state: shippingInfo?.state,
        zipCode: shippingInfo?.zipCode,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getShippingData();
    getState();
  }, [userId]);

  const onSubmit = async (values) => {
    try {
      const data = await ApiService.updateShippingInfo(values);
      if (
        data?.data?.msg === "Your shipping info has been successfully updated"
      ) {
        setOpen(true);
        setShowSuccessMessage(true);
        getShippingData();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Layout>
      <Box sx={{ padding: "30px" }}>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={onSubmit}
          validationSchema={updateShippingInfoValidation}
          validateOnMount={false}
          enableReinitialize
        >
          {({ getFieldProps, values, isSubmitting }) => (
            <Form>
              {console.log("values: ", values)}
              <Box sx={FormHeaderBox}>
                <Box sx={{ borderBottom: "1px solid #1175BB", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    {t("screens.rightSidebar.text.updateShippingInformation")}
                  </Typography>
                </Box>
                <div style={{ width: "100%" }}>
                  <Body style={{ gap: "10px 20px" }}>
                    <Column>
                      <FormGroup
                        name="firstName"
                        label={`${t("screens.formData.firstName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("firstName")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="lastName"
                        label={`${t("screens.formData.lastName")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("lastName")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="address1"
                        label={`${t("screens.formData.streetAddress")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("address1")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="address2"
                        label={`${t("screens.formData.address2")}`}
                      >
                        <InputField {...getFieldProps("address2")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="city"
                        label={`${t("screens.formData.city")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("city")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="state"
                        label={`${t("screens.formData.stateProvinceRegion")}`}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("state")}
                          name="state"
                          options={stateOptions}
                          placeholder={`${t("screens.formData.selectState")}`}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="zipCode"
                        label={`${t("screens.formData.zipCode")}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("zipCode")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="phoneNumber"
                        label={`${t("screens.formData.phoneNumber")}`}
                        requiredField
                      >
                        <CustomPhoneInput {...getFieldProps("phoneNumber")} />
                      </FormGroup>
                    </Column>
                  </Body>
                </div>
                {/* </Grid> */}
                <Box sx={ButtonBoxStyle}>
                  <Box display="flex">
                    <CustomButton
                      label={
                        isSubmitting
                          ? `${t("screens.formData.updating")}`
                          : `${t("screens.formData.update")}`
                      }
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {showSuccessMessage && (
          <CustomSnackbar
            title={`${t("screens.successMessage.dataUpdated")}`}
            desc={`${t("screens.successMessage.shippingUpdated")}`}
            open={open}
            okOnclick={() => {
              setShowSuccessMessage(false);
            }}
          />
        )}
      </Box>
    </Layout>
  );
}
