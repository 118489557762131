import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_CMS_BACKEND_BASE_URL,
  //baseURL: "http://ec2-44-213-168-246.compute-1.amazonaws.com:8000/",
});

export const getActivationkey = async (activationkey, uid) => {
  const res = await api.get(`/thankyou/${activationkey}/${uid}`);
  return res;
};
