import React from "react";

import { Box, FormHelperText, InputLabel, makeStyles } from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { STORE_LANGUAGE_KEY } from "../../../Constant";

function FormGroup({
  children,
  label,
  name,
  renderError = true,
  className,
  requiredField = false,
  labelClassName,
  direction = "vertical",
  type = "ltr",
  selectError,
  tooltipProps,
}) {
  const { touched } = useFormikContext();
  const { t } = useTranslation();
  const language = localStorage.getItem(STORE_LANGUAGE_KEY);

  const fieldTouched = get(touched, name);

  const renderLabel = () => (
    <InputLabel sx={{ ...inputstyle }}>
      {label}
      {requiredField ? <span>&nbsp;*</span> : ""}
    </InputLabel>
  );
  return (
    <div
      className={`
          ${className ?? ""}
        `}
    >
      {label && type === "ltr" && renderLabel()}
      <div>
        {children}
        {renderError && fieldTouched && (
          <ErrorMessage
            key={language}
            component="p"
            name={name}
            render={(msg) => (
              <FormHelperText
                sx={{
                  position: "relative",
                  color: "#d32f2f",
                }}
              >
                {t(msg)}
              </FormHelperText>
            )}
          />
        )}
      </div>
      {label && type === "rtl" && renderLabel()}
    </div>
  );
}

export default FormGroup;

const inputstyle = {
  color: "black",
  fontSize: "14px",
};
