import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

export default function BusinessComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home-video-main">
      <section
        className="secound-sections ng-scope"
        ng-controller="clientsData"
      >
        <h3 className="clients-say-businesses">
          <span>What Our Clients say </span>
        </h3>
        <div>
          <div className="row flex-row clinets-works-section">
            <div
              className="our-out-style ng-scope col-md-6"
              ng-repeat="(key,data ) in clientsItems"
            >
              <div className="clients-detalis ng-binding">
                "The team at FootprintID® has been super easy to work with and
                have made offering their product to our clients seamless. The
                product is a nice complement to our custom travel insurance
                protection plans. In my opinion, the value of the FootprintID®
                product is incredible especially when in the middle of an
                emergency and you need easy quick access to your important
                medical information."
              </div>
              <p className="clients-user-name ng-binding">
                Kelly P. Sahner, Chief Commercial Officer, Tripmate
              </p>
            </div>
            <div
              className="our-out-style ng-scope col-md-6"
              ng-repeat="(key,data ) in clientsItems"
            >
              <div className="clients-detalis ng-binding">
                "This partnership is a natural fit for both brands as
                FootprintID® helps elevate the level of care that we provide our
                clients.when people are traveling,having ready and secure access
                to their medical information can be vitally important. knowing
                critical medical data is available at their fingertips anywhere
                in the world provides Trip mate customers with peace of mind so
                that they can enjoy their trip."
              </div>
              <p className="clients-user-name ng-binding">
                Chris Carnicelli, Chief Executive Officer, Generali Global
                Assistance{" "}
              </p>
            </div>
            <div
              className="our-out-style ng-scope col-md-12"
              ng-repeat="(key,data ) in clientsItems"
            >
              <div className="clients-detalis ng-binding">
                "Getting good medical care starts with a picture of where you
                have been. Being able to store and share on demand medical care
                history is critical to anyone care plan. Footprint is a core
                feature for our medical plan clients."
              </div>
              <p className="clients-user-name ng-binding">
                Christian R. Stearns Co-Founder, CRO &amp; Product Lead HealthEE
                by HBG
              </p>
            </div>
          </div>
        </div>
        <div className="clinets-secound-sections">
          <p>
            Whether you're a business, a distributor of benefits, or a
            membership-based organization, FootprintID® is an excellent benefit
            for employees, members, or as a part of any benefits program.
          </p>
        </div>
      </section>
      <section ng-controller="employeesData" className="ng-scope">
        <div className="members-Card-data">
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-orange membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/travelers.svg"
                    className="img-fluid"
                    src="assets/svg/travelers.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  For Organizations and employers with workers who have pets
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-sky-blue membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/management.svg"
                    className="img-fluid"
                    src="assets/svg/management.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  For Organizations looking to enhance Membership benefits and
                  People risk management
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-green-600 membersCard-inner-block -600">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/programs.svg"
                    className="img-fluid"
                    src="assets/svg/programs.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Programs that support the Senior Population
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-gray-900 membersCard-inner-block -900">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/enhancement.svg"
                    className="img-fluid"
                    src="assets/svg/enhancement.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Membership and Loyalty Program enhancement
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-yellow membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/benefits.svg"
                    className="img-fluid"
                    src="assets/svg/benefits.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Enhance health and wellness employee benefits
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-blue membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/subcription.svg"
                    className="img-fluid"
                    src="assets/svg/subcription.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Subscription membership programs
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-green membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/data.svg"
                    className="img-fluid"
                    src="assets/svg/data.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Shared &amp; gig economy benefits
                </p>
              </div>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in teamDetails"
            className="BusinessCard ng-scope"
          >
            <div className="border-black membersCard-inner-block">
              <div className="members-card-membership">
                <div>
                  <img
                    ng-src="assets/svg/adventure.svg"
                    className="img-fluid"
                    src="assets/svg/adventure.svg"
                    alt=""
                  />
                </div>
                <p className="members-card-membership-style ng-binding">
                  Adventure &amp; Events Planning Organizations
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h2 className="partner-sections">It’s easy to partner with us.</h2>
        <p className="partner-data">
          Partnering with FootprintID and FootprintId is an easy and
          cost-effective way for organizations to provide a valuable benefit
          service, while also demonstrating a commitment to health and safety.
        </p>
        <div ng-controller="partnerData" className="ng-scope">
          <div
            ng-repeat="(key,data ) in partnerDetails"
            className="partner-card-block ng-scope"
          >
            <div className="partner-inner-section row flex-row">
              <div className="col-lg-1 col-md-2 col-sm-3  platform-inner-block">
                <img
                  ng-src="assets/images/platform.png"
                  className="img-fluid"
                  src="assets/images/platform.png"
                  alt=""
                />
              </div>
              <p className="partner-descripction col-lg-11 col-md-10 col-sm-9 ng-binding">
                Our platform is easy to integrate into existing systems and
                workflows, and our team provides comprehensive support to ensure
                a smooth partnership. Plus, with customizable branding options,
                businesses can co-brand or white-label FootprintID® and
                FootprintId® to fit seamlessly into their existing brand
                identity.
              </p>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in partnerDetails"
            className="partner-card-block ng-scope"
          >
            <div className="partner-inner-section row flex-row">
              <div className="col-lg-1 col-md-2 col-sm-3  platform-inner-block">
                <img
                  ng-src="assets/images/industryUser.png"
                  className="img-fluid"
                  src="assets/images/industryUser.png"
                  alt=""
                />
              </div>
              <p className="partner-descripction col-lg-11 col-md-10 col-sm-9 ng-binding">
                Whether you're in the travel industry looking to provide peace
                of mind to your customers, an organization with a duty of care
                for those they work with, or a gig economy platform looking to
                offer valuable benefits to workers (or anything else in
                between), FootprintID® and FootprintId® can help.
              </p>
            </div>
          </div>
          <div
            ng-repeat="(key,data ) in partnerDetails"
            className="partner-card-block ng-scope"
          >
            <div className="partner-inner-section row flex-row">
              <div className="col-lg-1 col-md-2 col-sm-3  platform-inner-block">
                <img
                  ng-src="assets/images/hart.png"
                  className="img-fluid"
                  src="assets/images/hart.png"
                  alt=""
                />
              </div>
              <p className="partner-descripction col-lg-11 col-md-10 col-sm-9 ng-binding">
                Don't just take our word for it - we've already helped hundreds
                of organizations - and over 5 million users worldwide - improve
                health outcomes and satisfaction rates.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="partnership-benefits-section">
        <div className="partnership-benefits-inner">
          <h3 className="clients-say-businesses">
            <span>Partnership Benefits</span>
          </h3>
          <p className="partnership-benefits-detalis">
            Here are just a few of the benefits that organizations can expect
            from partnering with FootprintID and FootprintId:
          </p>
        </div>
      </section>
      <section
        ng-controller="partnershipData"
        className="partnership-content-main-card ng-scope"
      >
        <div className="partnership-content-block">
          <div
            ng-repeat="(key,data ) in partnershipDetails"
            className="partnership-content-card ng-scope"
          >
            <div className="benefits-content">
              <div className="benefits-content-sections">
                <img
                  ng-src="assets/images/partnership.jpg"
                  className="img-fluid benefits-content-img"
                  src="assets/images/partnership.jpg"
                  alt=""
                />
              </div>
              <h5 className="ng-binding">
                Avoid unnecessary doctor visits and spend more time focused on
                other tasks.
              </h5>
            </div>
            <p className="benefits-data-descripctions ng-binding">
              Increased Productivity
            </p>
          </div>
          <div
            ng-repeat="(key,data ) in partnershipDetails"
            className="partnership-content-card ng-scope"
          >
            <div className="benefits-content">
              <div className="benefits-content-sections">
                <img
                  ng-src="assets/images/partnership1.jpg"
                  className="img-fluid benefits-content-img"
                  src="assets/images/partnership1.jpg"
                  alt=""
                />
              </div>
              <h5 className="ng-binding">
                Get better health outcomes from more accurate diagnoses and
                treatment recommendations.
              </h5>
            </div>
            <p className="benefits-data-descripctions ng-binding">
              Improved Outcomes
            </p>
          </div>
          <div
            ng-repeat="(key,data ) in partnershipDetails"
            className="partnership-content-card ng-scope"
          >
            <div className="benefits-content">
              <div className="benefits-content-sections">
                <img
                  ng-src="assets/images/partnership2.jpg"
                  className="img-fluid benefits-content-img"
                  src="assets/images/partnership2.jpg"
                  alt=""
                />
              </div>
              <h5 className="ng-binding">
                By avoiding unnecessary doctor visits and procedures, all
                parties can save money on healthcare costs.
              </h5>
            </div>
            <p className="benefits-data-descripctions ng-binding">
              Reduced Costs
            </p>
          </div>
          <div
            ng-repeat="(key,data ) in partnershipDetails"
            className="partnership-content-card ng-scope"
          >
            <div className="benefits-content">
              <div className="benefits-content-sections">
                <img
                  ng-src="assets/images/partnership3.jpg"
                  className="img-fluid benefits-content-img"
                  src="assets/images/partnership3.jpg"
                  alt=""
                />
              </div>
              <h5 className="ng-binding">
                Help improve employee satisfaction and retention rates, while
                also attracting talent. top
              </h5>
            </div>
            <p className="benefits-data-descripctions ng-binding">
              Attract &amp;Retain
            </p>
          </div>
        </div>
      </section>
      <section className="integration-opections-sections">
        <h3 className="integration-title">
          <span>Integration Options</span>
        </h3>
        <p className="integration-descripctions">
          We understand the importance of seamless integration with your
          existing systems and workflows. That's why we've made it as easy as
          possible to integrate our solution into your operations.
        </p>
      </section>
      <section
        className="intergation-pections-card ng-scope"
        ng-controller="integrationData"
      >
        <div
          ng-repeat="(key,data ) in integrationDetails"
          className="intergation-card-block ng-scope"
        >
          <div className="intergation-card-inner-block row flex-row">
            <div className="col-lg-1 col-md-2 col-sm-3 intergation-card-img">
              <img
                ng-src="assets/images/react.png"
                className="img-fluid"
                src="assets/images/react.png"
                alt=""
              />
            </div>
            <p className="intergation-detalis col-lg-11 col-md-10 col-sm-9 ng-binding">
              Our APIs and integration tools are designed to work seamlessly
              with a wide range of systems, including HR platforms, benefits
              administration systems, and more. Our integration options allow
              access FootprintID® and FootprintId® with the same login
              credentials they use for other systems, creating a seamless
              experience.
            </p>
          </div>
        </div>
        <div
          ng-repeat="(key,data ) in integrationDetails"
          className="intergation-card-block ng-scope"
        >
          <div className="intergation-card-inner-block row flex-row">
            <div className="col-lg-1 col-md-2 col-sm-3 intergation-card-img">
              <img
                ng-src="assets/images/code.png"
                className="img-fluid"
                src="assets/images/code.png"
                alt=""
              />
            </div>
            <p className="intergation-detalis col-lg-11 col-md-10 col-sm-9 ng-binding">
              Our team will work closely with you to ensure a smooth integration
              process. We'll provide comprehensive documentation and support
              resources to help your IT team get up and running quickly. Our
              customer support team is also available 24/7 to answer any
              questions your employees may have about using FootprintID® and
              FootprintId.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div>
          <h3 className="clients-say-businesses">
            <span>Ready to get started? </span>
          </h3>
          <div className="become-member-data">
            <button className="btn-deep-orange">Become a Member</button>
          </div>
        </div>
      </section>
    </div>
  );
}

const animation = keyframes`
   from { opacity: 0; filter: blur(10px); }
  to { opacity: 1; filter: blur(0px);}
`;
export const BusinessComponentWrapper = styled.div``;
export const BusinessHeader = styled.h1`
  margin: 0;
  padding: 180px 0px 50px;
  text-align: left;
  /* font-weight: 400; */
  font-size: 50px;
  line-height: 112.4%;
  color: rgb(31, 85, 165);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  animation: ${animation} 1s 0s forwards;

  span {
    color: #008fd3;
  }
  @media (max-width: 1100px) {
    font-size: 45px;
  }
  @media (max-width: 900px) {
    font-size: 40px;
    text-align: center;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
  }
  @media (max-width: 850px) {
    padding: 140px 0 40px;
  }
`;
