import React, { useEffect, useRef, useState } from "react";
// import { navData } from "../Data/Header/Navdata";
import styled from "styled-components";
import Menutool from "../Header/Menutool";
import useAuth from "../../ContextProvider/AuthProvider";
import { USER_ROLE_KEY } from "../../Constant";
import ProfileDropDown from "../Common/MyProfileDropdown";
import MemberDropDown from "../Common/MemberDropdown";
import { config } from "../../config";

export default function NewHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const tooltipRef = useRef();
  const { user } = useAuth();
  const role = localStorage.getItem(USER_ROLE_KEY);
  const { sidebar } = config;

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }
  function handleClickOutside(event) {
    if (
      ref?.current &&
      !ref?.current?.contains(event.target) &&
      !tooltipRef?.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      topmenu=""
      className="navbar-header-top navbar-header-shadow"
      ng-hide="hideHeader"
    >
      <header
        ng-controller="TopMenuController"
        id="home"
        className="header layout-site-header navbar navbar-inverse ng-scope"
      >
        <div
          className="container-main container-fluid header-foodts-sections"
          style={{ justifyContent: "end", padding: "15px 25px" }}
        >
          {/* <div ng-if="!menu.show" className="navbar-header ng-scope">
            <a className="navbar-brand" href="/">
              <img
                alt="logo"
                className="img-fluid"
                src="/assets/images/headerlogo.png"
              />
            </a>
          </div> */}
          <div style={{ display: "flex", gap: 10 }}>
            <div id="mainmenu" ng-controller="navDetails" className=" ng-scope">
              <ul className="navbar-nav">
                <MemberDropDown />
              </ul>
            </div>
            <div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  //   lineHeight: "normal",
                }}
              >
                {user?.user_fname} {user?.user_lname}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: "grey",
                }}
              >
                {role}
              </div>
            </div>
            <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
              <HamburgerIcon>
                <MenuItem>
                  <img
                    src={require("../../../Assets/Images/Navbar/hamburger-menu.png")}
                    width={30}
                    height={30}
                  />
                </MenuItem>
              </HamburgerIcon>
            </HamburgerWrapper>
            <ProfileDropDown />
          </div>
        </div>
      </header>
      <Menutool isOpen={isOpen} SidebarItems={sidebar} />
    </div>
  );
}

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  background: #008fd3;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 5px;
`;

const MenuItem = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  text-align: left;

  :hover {
    background: #333;
  }
`;
