import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import FormGroup from "../../Common/FormGroup";
import SelectField from "../../Common/AutoComplete";
import PhoneInput from "../../Common/PhoneInput";
import InputField from "../../Common/InputField";
import { usStates } from "../../../../Components/register/constant";
// import { Column } from "../../../../Css";
import { Body, Column, Header, Wrapper } from "../../../../Css";
import ShippingInformationForm from "./ShippingInformationForm";
import ApiService from "../../../Api/ApiServices";
import { useTranslation } from "react-i18next";

function BillingInformationForm({ onPrevious, setInitialValue }) {
  const { getFieldProps, touched, values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const getShippingData = async () => {
    try {
      const { data } = await ApiService.getShippingInfo();
      const { data: billingData } = await ApiService.getBillingInfo();
      const billingInfo = billingData?.billingInfo;
      const shippingInfo = data?.shippingInfo;
      setInitialValue({
        ...values,
        firstnameB: shippingInfo?.firstName,
        lastnameB: shippingInfo?.lastName,
        streetAddB: shippingInfo?.address1,
        addressB: shippingInfo?.address2,
        CityB: shippingInfo?.city,
        stateB: shippingInfo?.state,
        zipcodeB: shippingInfo?.zipCode,
        shipping_phone_number: shippingInfo?.phoneNumber,
        address_line_1: billingInfo?.address1,
        address_line_2: billingInfo?.address2,
        admin_area_2: billingInfo?.city,
        fName: billingInfo?.firstName,
        lName: billingInfo?.lastName,
        state: billingInfo?.state,
        billing_postal_code: billingInfo?.zipCode,
        admin_area_1: billingInfo?.state,
        billing_phone_number: billingInfo?.phoneNumber,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    touched.firstnameB = false;
    touched.streetAddB = false;
    touched.CityB = false;
    touched.zipcodeB = false;
    touched.lastnameB = false;
    touched.stateB = false;
    getShippingData();
  }, []);

  return (
    <div className="container" style={{ gridTemplateColumns: "90%" }}>
      <Wrapper style={{ marginBottom: "50px" }}>
        <Header>{t("screens.upgradeMembership.billingInformation")}</Header>

        <Body>
          <Column>
            <FormGroup
              label={`${t("screens.formData.firstName")}`}
              name="firstnameB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="firstnameB"
                {...getFieldProps("firstnameB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.lastName")}`}
              name="lastnameB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lastnameB"
                {...getFieldProps("lastnameB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.streetAddress")}`}
              name="streetAddB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="streetAddB"
                {...getFieldProps("streetAddB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.address2")}`}
              name="addressB"
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="addressB"
                {...getFieldProps("addressB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.city")}`}
              name="CityB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="CityB"
                {...getFieldProps("CityB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.stateProvinceRegion")}`}
              name="stateB"
              labelClassName="margin-top"
            >
              <SelectField
                {...getFieldProps("stateB")}
                name="stateB"
                options={usStates}
                placeholder={`${t("screens.formData.selectState")}`}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.zipCode")}`}
              name="zipcodeB"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="zipcodeB"
                {...getFieldProps("zipcodeB")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.phoneNumber")}`}
              name="billing_phone_number"
              requiredField
              labelClassName="margin-top"
            >
              <Country>
                <PhoneInput
                  dialCodeName="phone_code"
                  name="billing_phone_number"
                  onChange={(value, country) => {
                    setFieldValue("billing_phone_code", country?.dialCode);
                  }}
                  value={values?.billing_phone_number}
                />
              </Country>
            </FormGroup>
          </Column>
        </Body>
      </Wrapper>
      <ShippingInformationForm
        onPrevious={onPrevious}
        setInitialValue={setInitialValue}
      />
    </div>
  );
}

export default BillingInformationForm;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: 1px solid gray;
    height: 41px !important;
    width: 100%;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;
