import React from "react";

export default function ScrollCard() {
    return (
        <div className="row flex-row beth-total-sections">
            <div className="col-md-3 key-light-section">
                <p className="beth-total-title">Key Insights</p>
            </div>
            <div className="col-md-9">
                <div
                    className="row flex-row ng-scope"
                    ng-controller="bethTotalSection"
                >
                    <div
                        className="col-sm-6 text-center key-insights-section ng-scope"
                        ng-repeat="(key,data ) in bethTotalDetails"
                    >
                        <div className="both-section-style">
                            <h6 className="beth-total-title ng-binding">
                                <span className="ng-binding">5</span>&nbsp;million
                            </h6>
                            <p className="beth-total-description ng-binding">Memberships</p>
                        </div>
                    </div>
                    <div
                        className="col-sm-6 text-center key-insights-section ng-scope"
                        ng-repeat="(key,data ) in bethTotalDetails"
                    >
                        <div className="both-section-style">
                            <h6 className="beth-total-title ng-binding">
                                <span className="ng-binding">93</span>&nbsp;countries
                            </h6>
                            <p className="beth-total-description ng-binding">Memberships</p>
                        </div>
                    </div>
                    <div
                        className="col-sm-6 text-center key-insights-section ng-scope"
                        ng-repeat="(key,data ) in bethTotalDetails"
                    >
                        <div className="both-section-style">
                            <h6 className="beth-total-title ng-binding">
                                <span className="ng-binding">20%</span>&nbsp;reduction
                            </h6>
                            <p className="beth-total-description ng-binding">
                                in unnecessary testing
                            </p>
                        </div>
                    </div>
                    <div
                        className="col-sm-6 text-center key-insights-section ng-scope"
                        ng-repeat="(key,data ) in bethTotalDetails"
                    >
                        <div className="both-section-style">
                            <h6 className="beth-total-title ng-binding">
                                <span className="ng-binding">72%</span>&nbsp;Of
                            </h6>
                            <p className="beth-total-description ng-binding">
                                Smartphone Users
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
