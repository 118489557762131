import React from "react";

import styled from "styled-components";

import MenuButton from "./MenuButton";

function Menutool(props) {
  const { isOpen } = props;

  return (
    <Wrapper isOpen={isOpen}>
      {props?.SidebarItems.map((item, i) => (
        <MenuButton key={i} item={item} />
      ))}
    </Wrapper>
  );
}

export default Menutool;
const Wrapper = styled.div`
  display: none;
  @media (max-width: 890px) {
    display: block;
    position: fixed;
    top: 120px;
    right: 15px;
    background: rgba(15, 14, 71, 0.3);
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25),
      inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    padding: 10px;
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
    z-index: 1;
    display: grid;
    gap: 1px;
    grid-template-columns: 200px;
    transition: 0.3s ease-in-out;
    overflow-y: auto;
    max-height: 80vh;
  }
`;
