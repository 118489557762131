import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container">
      <h3 className="privacy-policy-heading">Web Site Privacy Policy</h3>
      <p className="privacy-description">
        ("FootprintID®", "we" or "us") is committed to the protection of your
        personal information. Users of our site ("you", "your"), refers to any
        registered user, member or visitor to this website. Agreement &
        Acknowledgement: Please read this Privacy Policy carefully before
        accessing or using our website. By accessing or using FootprintID® (or
        permitting others to access or use FootprintID®), you acknowledge your
        understanding and agreement with this policy. This policy applies to all
        users/visitors of the FootprintID® site and explains how FootprintID®
        collects and uses your information. We may modify this policy from time
        to time, and such modifications will be effective immediately upon
        posting of the modified policy on our website. By continuing to access
        or use the website, you acknowledge your understanding and agreement
        with the modified policy. Therefore, it is your obligation to access and
        review this policy frequently. Security of Your Information: We are
        dedicated to using reasonable security measures to protect your
        information. In order to prevent and minimize unauthorized accesses or
        disclosures, we have put in place suitable physical, electronic and
        managerial procedures to safeguard and secure the information you store
        on FootprintID®. To store your personal health information on our
        secured ________ network, you will purchase access pursuant to our Terms
        and Conditions. We store your information on a ___________server and it
        is secured by_____________. Notwithstanding this, you acknowledge that
        there is always risk that unauthorized parties may breach established
        security systems. In such event, FootprintID® will follow applicable
        laws and notify you as and when required. You must take all reasonable
        steps to ensure that no unauthorized person has access to your
        membership card, which contains your user id, and would enable the
        holder to view your personal information. Note that only you, and anyone
        you authorize by giving them your unique password, are in control of,
        and able to edit, your personal information. You can increase the
        security of your information by creating a password that is easy for you
        to remember, but difficult for others to guess. It is your
        responsibility to promptly advise FootprintID® of any password that has
        been compromised and request that it be removed from your account.
        Responsibility for Your Information: You will be responsible for
        uploading your health information onto your account. FootprintID®
        neither takes responsibility for nor warrants the accuracy or
        completeness of any personal information uploaded, downloaded,
        submitted, saved, removed or deleted by you. Furthermore, FootprintID®
        takes no responsibility for any medical decisions undertaken or
        influenced via use of our product. FootprintID® is not a medical device
        and is not intended and should not be used in the diagnosis, cure,
        mitigation, treatment or prevention of disease or other condition of the
        body. Notification of Breach: The Internet, web, and email systems all
        carry inherent risks; therefore we cannot guarantee the security of any
        information you transmit in this manner. Although it is unlikely, if
        ever a security breach were to occur at FootprintID®, FootprintID® would
        notify you without undue delay and within 72 hours when feasible, by US
        Mail, email or both, after discovering the breach. Dependent on the
        circumstance, we may post a breach on our website or through other
        media. The Federal Trade Commission may also be notified of the breach.
        Please contact our Data Protection Officer via telephone,
        1-855-374-3411, email at help@footprintid.com, US Mail PO Box 2412,
        Livingston, NJ 07039 if you are aware of any security breach. We will
        respond to your notification promptly. What we collect and how we use
        and disclose it: We collect information that helps us identify you
        ("PII" or "Personal Information") as well as information that cannot be
        used to identify you ("non-PII" or "Non-Personal Information"). Examples
        of PII, your personal data, are, but are not limited to, things such as
        Name, Address, and Date of Birth. Examples of Non-PII statistical data
        are, but are not limited to, information as to how you use our products
        and site, newsletters, other informational communications. We store
        information such as, but not limited to, IP addresses, browsers,
        operating system, click stream data, and date/time stamps in log files.
        This information is used to ensure security, manage the website, and
        improve our site by seeing how our users interact with it. We also
        utilize cookies to help us make your visit to our site more personal. (A
        cookie is a small file which asks permission to be placed on your
        computer's hard drive. Cookies help analyze web traffic or lets you know
        when you visit a particular site.) You can delete, block or not accept
        our cookies; however that may affect the functionality of some areas of
        our site. We may use cookies to collect information on the pages that
        you view, the advertising sites you visit and other information with
        regard as to how you utilize our site. We may use your personal
        information to provide you with better customer service. It is also used
        to provide you with information about our products and services and to
        ensure that those products, services and our website properly function
        and remain secure. It may be used to analyze statistics, update account
        records, and comply with any legal requirements we may have.
        Additionally, it may be used to provide statistical feedback to third
        party vendors. We may also use it to investigate, prevent or act upon
        any violations of our customer agreements. In general, except as
        required by law, we do not disclose any personal information that can be
        used to identify you. FootprintID® may share information that cannot
        identify you. For example, but not limited to, we may publish the number
        of users of our site at any given time. Links to Other Websites: This
        policy applies only to transactions that take place through our sites
        and does not cover usage through third party sites. That is, although
        our website may contain links to other websites of interest, once you
        use these links to leave our site, we do not have any control over that
        other website. Therefore, we cannot be responsible for the protection
        and privacy of any information, which you provide while visiting such
        sites and such sites are not governed by this privacy policy. You should
        exercise caution and look at the privacy policy or statement applicable
        to the website in question. HIPAA: FootprintID® is not a covered entity
        as defined by HIPAA. HOWEVER, we fully maintain a level of commitment
        and dedication to member confidentiality and online privacy. Contact: If
        you have any questions regarding the foregoing, please contact the Data
        Protection Officer at FootprintID® at 1-855-374-3411.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
