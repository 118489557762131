import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import InputField from "../../Common/InputField";
import FormGroup from "../../Common/FormGroup";
import SelectField from "../../Common/AutoComplete";
import { Body, Check, Column, Header, Wrapper } from "../../../../Css";
import PhoneInput from "../../Common/PhoneInput";
import { usStates } from "../../../Options";
import { useTranslation } from "react-i18next";

function ShippingInformationForm({ onPrevious, setInitialValue }) {
  const { getFieldProps, values, touched, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    touched.fName = false;
    touched.lName = false;
    touched.address_line_1 = false;
    touched.admin_area_2 = false;
    touched.billing_postal_code = false;
    touched.admin_area_1 = false;
  }, []);

  const handleCheck = () => {
    if (!values.infoChecked) {
      setInitialValue({
        ...values,
        fName: values?.firstnameB,
        lName: values?.lastnameB,
        address_line_1: values?.streetAddB,
        address_line_2: values?.addressB,
        admin_area_2: values?.CityB,
        billing_postal_code: values?.zipcodeB,
        admin_area_1: values?.stateB,
        infoChecked: true,
        shipping_phone_number: values?.billing_phone_number,
      });
    } else {
      setInitialValue({
        ...values,
        fName: "",
        lName: "",
        address_line_1: "",
        address_line_2: "",
        admin_area_2: "",
        billing_postal_code: "",
        admin_area_1: "",
        infoChecked: false,
        shipping_phone_number: "",
      });
    }
  };

  return (
    <div>
      <Wrapper>
        <Header>{`${t(
          "screens.upgradeMembership.shippingInformation"
        )}`}</Header>
        <Check>
          <input
            type="checkbox"
            onChange={handleCheck}
            checked={values.infoChecked}
          ></input>
          {`${t("screens.rightSidebar.text.sameAsShippingAddress")}`}
        </Check>
        <Body>
          <Column>
            <FormGroup
              label={`${t("screens.formData.firstName")}`}
              name="fName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="fName"
                {...getFieldProps("fName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.lastName")}`}
              name="lName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lName"
                {...getFieldProps("lName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.streetAddress")}`}
              name="address_line_1"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_1"
                {...getFieldProps("address_line_1")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.address2")}`}
              name="address_line_2"
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_2"
                {...getFieldProps("address_line_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.city")}`}
              name="admin_area_2"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="admin_area_2"
                {...getFieldProps("admin_area_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.stateProvinceRegion")}`}
              name="admin_area_1"
              labelClassName="margin-top"
            >
              <SelectField
                {...getFieldProps("admin_area_1")}
                name="admin_area_1"
                options={usStates}
                placeholder="Select State"
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.zipCode")}`}
              name="billing_postal_code"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="billing_postal_code"
                {...getFieldProps("billing_postal_code")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label={`${t("screens.formData.phoneNumber")}`}
              name="shipping_phone_number"
              requiredField
              labelClassName="margin-top"
            >
              <Country>
                <PhoneInput
                  dialCodeName="phone_code"
                  name="shipping_phone_number"
                  onChange={(value, country) => {
                    setFieldValue("shipping_phone_code", country?.dialCode);
                  }}
                  value={values?.shipping_phone_number}
                />
              </Country>
            </FormGroup>
          </Column>
        </Body>
        <div className="shipping-btn">
          <button onClick={onPrevious} className="btn btn-primary">
            {`${t("screens.upgradeMembership.back")}`}
          </button>
          <button className="btn btn-primary" type="next">
            {t("screens.upgradeMembership.next")}
          </button>
        </div>
      </Wrapper>
    </div>
  );
}

export default ShippingInformationForm;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: 1px solid gray;
    height: 41px !important;
    width: 100%;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;
