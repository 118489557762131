import React from "react";
import styled from "styled-components";
import TermConditionC from "../../Components/TermConditions/TermCondition";

function TermCondition() {
  return (
    <Body>
      <TermConditionC />
    </Body>
  );
}

export default TermCondition;

const Body = styled.div`
  padding-top: 140px;
  padding-right: 90px;
  padding-left: 70px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, auto);
  @media (max-width: 500px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (min-width: 1400px) {
    height: calc(100vh - 437px);
  }

  button {
    width: 259px;
    height: 55px;
    background: #1175BB;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 30px;
    color: #ffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;

    :hover {
      transform: translateY(-1px);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 20px 20px rgba(0, 0, 0, 0.15),
        inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
    }
  }
`;