import React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import StripeCardForm from "./StripeCardForm";
import { useTranslation } from "react-i18next";
// import StripeCreditCardFormData from "./StripeCardFormData";

function PaymentInfoForm({ onPrevious }) {
  const { values } = useFormikContext();
  const { t } = useTranslation();

  return (
    <div>
      <Label>{t("screens.upgradeMembership.confirmation")}</Label>
      <MainGrid>
        <Column>
          <Wrapper>
            <Header>
              <p>{t("screens.personalInformation.title")}</p>
            </Header>
            <Body>
              <Row>
                <Title>{t("screens.accountInformations.name")}:</Title>
                <Text>
                  {values.firstname} {values.lastname}
                  {/* {firstNameUser} {lastNameUser} */}
                </Text>
              </Row>
              <Row>
                <Title>{t("screens.accountInformations.email")}:</Title>
                <Text>{values.email}</Text>
              </Row>
            </Body>
          </Wrapper>
        </Column>
        <Column>
          <Wrapper>
            <Header>
              <p>
                {t("screens.upgradeMembership.billingSystem")}{" "}
                {values?.hasMembershipNumber
                  ? ""
                  : `($${values?.totalAmmount})`}
              </p>
            </Header>
            <Body>
              <StripeCardForm onPrevious={onPrevious} />
            </Body>
          </Wrapper>
        </Column>
      </MainGrid>
    </div>
  );
}

export default PaymentInfoForm;

const Wrapper = styled.div`
  font-family: sans-serif;
  //padding: 10px 0 10px 0px;
  text-align: center;
`;

const Label = styled.div`
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 3.75rem;
  line-height: 4.688rem;
  color: #1175bb;
`;
const Header = styled.div`
  background-color: #1976d22e;
  // margin: 0 50px 0 250px;
  border-color: #bce8f1;
  padding: 3px 10px;
  text-align: left;
  border-radius: 8px 8px 0 0;
  display: flex;

  p {
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  a {
    float: right;
    text-decoration: none;
    padding-left: 500px;
  }

  @media (max-width: 1060px) {
    margin: 0 200px;
  }
  @media (max-width: 950px) {
    margin: 0 100px;
  }
  @media (max-width: 750px) {-color:
    margin: 0 50px;
  }
  @media (max-width: 650px) {
    margin: 0 12px;
    padding: 10px;
  }
`;
const Body = styled.div`
  background-color: #fff;
  // margin: 0 50px 0 250px;
  padding: 15px;
  display: block;
  text-align: left;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: 1060px) {
    margin: 0 200px;
  }
  @media (max-width: 950px) {
    margin: 0 100px;
  }
  @media (max-width: 750px) {
    margin: 0 50px;
  }
  @media (max-width: 650px) {
    margin: 0 12px;
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

const Title = styled.p`
  color: #000;
  font-size: 14px;
  font-family: "Omnes", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
`;
const Text = styled.p`
  color: #313131;
  font-size: 16px;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 300;
  margin-bottom: 0;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

const MainGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  margin: 0px auto;
  max-width: 80%;

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
  @media (max-width: 1060px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;
  border: 1px solid #428bca;
  border-radius: 8px;

  .membershipInput {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;

    :focus {
      border: none;
    }
  }
`;
