import React, { useEffect } from "react";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home-video-main">
      <section className="contact-shortly">
        <h6 className="shortly-title">We will get in touch shortly</h6>
        <div className="show-form-grop">
          <div className="form-control-comments">
            <label className="show-form-title" for="name">
              Name
            </label>
            <div className="input-icon">
              <input
                id="name"
                className="form-control show-form-control ng-pristine ng-untouched ng-valid ng-empty"
                type="text"
                ng-model="name"
              />
            </div>
          </div>
          <div className="form-control-comments">
            <label className="show-form-title" for="address">
              Address
            </label>
            <div className="input-icon">
              <input
                id="address"
                className="form-control show-form-control ng-pristine ng-untouched ng-valid ng-empty"
                type="text"
                ng-model="address"
              />
            </div>
          </div>
          <div className="form-control-comments">
            <label className="show-form-title" for="contact">
              Contact no
            </label>
            <div className="input-icon">
              <input
                id="contact"
                className="form-control show-form-control ng-pristine ng-untouched ng-valid ng-empty"
                type="number"
                ng-model="contact"
              />
            </div>
          </div>
          <div className="form-control-comments">
            <label className="show-form-title" for="email">
              Email
            </label>
            <div className="input-icon">
              <input
                id="email"
                className="form-control show-form-control ng-pristine ng-untouched ng-valid ng-empty"
                type="text"
                ng-model="email"
              />
            </div>
          </div>

          <div className="form-control-comments">
            <label className="show-form-title" for="comments">
              Questions/Comments
            </label>
            <div className="input-icon">
              <textarea
                id="notes"
                className="form-control emergency-input  show-form-textarea ng-pristine ng-untouched ng-valid ng-empty"
                rows="9"
                ng-model="emer.cond_notes"
              ></textarea>
            </div>
          </div>
          <div className="show-form-button">
            <button className="btn-green-contact" type="submit" ng-click="">
              submit
            </button>{" "}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
