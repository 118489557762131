import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../Assets/Styles/AboutUs/MeetTeam.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import useWindowDimensions from "./windowDiamension";

const teamMembers = [
  {
    image: "assets/images/joson.jpg",
    name: "Jason Hubert",
    post: "CEO/CTO",
  },
  {
    image: "assets/images/bethAdmin.png",
    name: "Beth Tofel",
    post: "President/COO",
  },
  {
    image: "assets/images/customerService.png",
    name: "Blair Huddy",
    post: "CMO",
  },
  {
    image: "assets/images/sales.png",
    name: "",
    post: "Sales Team",
  },
  {
    image: "assets/images/amanda.png",
    name: "Customer Service",
    post: "",
  },
];

function MeetTeam() {
  const { width } = useWindowDimensions();
  let sp = 1;
  if (width > 1350) {
    sp = 4;
  }
  if (width > 1100 && width < 1350) {
    sp = 3;
  }
  if (width > 650 && width < 1099) {
    sp = 2;
  }

  return (
    <div
      ng-controller="SwiperController"
      className="five-section-block ng-scope"
    >
      <div className="swiper mySwiper swiper-initialized swiper-horizontal swiper-backface-hidden">
        <Swiper
          className="ImgSlideShow"
          style={{ width: "100%" }}
          slidesPerView={sp}
          spaceBetween={1}
          slidesPerGroup={1}
          loop={false}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
        >
          {teamMembers?.map((item, index) => (
            <SwiperSlide className="Slide-component" key={index}>
              <div
                className="swiper-slide ng-scope swiper-slide-active"
                ng-repeat="(key,data ) in teamDetails"
                role="group"
                aria-label="1 / 5"
                style={{ width: "360px" }}
              >
                <div>
                  <img
                    ng-src={item?.image}
                    className="img-fluid img-team-details"
                    src={item?.image}
                    alt=""
                  />
                </div>
                <p className="tame-data-details ng-binding">{item?.name}</p>
                <p className="tame-data-details ng-binding">{item?.post}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MeetTeam;
