import React from "react";

import CustomTable from "./Table";
import { FullPageWrapper } from "../Item-List/GlobalItemList";

export default function PawItemList(props) {
  const {
    label,
    setSelectedUserName,
    items,
    dataAddLink,
    columns = [],
    columnsData = [],
    isLoading = false,
    placeholder,
    details,
    isArchive,
    AddPetArchive,
    fetchData,
    isSelectHide = false,
    filterOptions,
    pageCount,
    defaultOptions,
    back,
    addOnclick,
    addButton = true,
    pagination = true,
  } = props;

  return (
    <>
      <FullPageWrapper>
        <CustomTable
          columns={columns}
          fetchData={fetchData}
          data={columnsData}
          pagination={pagination}
          filterOptions={filterOptions}
          items={items}
          setSelectedUserName={setSelectedUserName}
          dataAddLink={dataAddLink}
          placeholder={placeholder}
          AddPetArchive={AddPetArchive}
          isSelectHide={isSelectHide}
          loading={isLoading}
          label={label}
          isArchive={isArchive}
          pageCount={pageCount}
          defaultOptions={defaultOptions}
          details={details}
          back={back}
          addOnclick={addOnclick}
          addButton={addButton}
        />
      </FullPageWrapper>
    </>
  );
}
