import React, { useCallback, useEffect, useMemo, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useSWR from "swr";

import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { formatDate, formatPhoneNumber } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";
import PawDataView from "../Common/PawDataView.js";
import EditIcon from "../../Assets/Images/EditIcon.svg";
import DeleteIcon from "../../Assets/Images/DeleteIcon.svg";
import PersonalInformationForm from "../Forms/PersonalInformationForm.js";
import ApiService from "../../Api/ApiServices.js";
import { MainPageWrapper } from "../Item-List/GlobalItemList";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function PersonalInformation() {
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { userId } = useAuth();
  const { t } = useTranslation();
  const {
    data = [],
    mutate: fetchAPIData,
    isLoading,
  } = useSWR(
    userId ? `userData_${userId}` : null,
    async () => {
      const { data } = await ApiService.getPersonalInformation(userId);
      const filterData = data.map((item) => {
        return {
          ...item,
          name: `${item.fname} ${item.lname}`,
          id: item.id,
          phone_number: formatPhoneNumber(item?.contact_no),
        };
      });
      return filterData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleView = (item) => {
    console.log("item: ", item);
    setIsShow(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsEdit(false);
  };

  const addOnclick = () => {
    setIsAdd(true);
    setSelectedData({});
    setIsShow(false);
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedData(item);
    setIsAdd(false);
    setIsShow(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.personalInformation.text.avatar")}`,
        textAlign: "center",
        accessor: "avatar",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.avatar ? (
            <img
              alt="userImage"
              src={row?.original?.avatar}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: `${t("screens.accountInformations.name")}`,
        accessor: "name",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.personalInformation.text.email")}`,
        accessor: "email",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <img
                alt="view"
                src={EditIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: `${t("screens.formData.delete")}`,
        accessor: "delete",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => setAskConfirmation(row?.original?.user_id)}
            >
              <img
                alt="view"
                src={DeleteIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, [t]);

  const props = {
    label: `${t("screens.personalInformation.title")}`,
    isLoading: isLoading,
    columns,
    columnsData: data,
    details: "There are no other Personal Information.",
    addButton: !data?.length,
    addOnclick: addOnclick,
  };

  const handleDelete = async () => {
    try {
      if (askConfirmation) {
        const { data } = await ApiService.personalInfoDelete(askConfirmation);
        if (data?.OK) {
          fetchAPIData();
          setIsShow(false);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const viewProps = {
    label: `${t("screens.personalInformation.title")}`,
    askConfirmation,
    setAskConfirmation,
    onDelete: handleDelete,
    isShow,
    detailsData: [
      [
        {
          name: `${t("screens.accountInformations.name")}`,
          value: `${selectedData?.title} ${selectedData?.name}`,
        },
        {
          name: `${t("screens.personalInformation.text.dateOfBirth")}`,
          value: selectedData?.dob ? formatDate(selectedData?.dob) : "-",
        },

        {
          name: `${t("screens.personalInformation.text.email")}`,
          value: selectedData?.email ? selectedData?.email : "-",
        },
        {
          name: `${t("screens.personalInformation.text.gender")}`,
          value: selectedData?.sex ? selectedData?.sex : "-",
        },
        {
          name: `${t("screens.personalInformation.text.height")}`,
          value: `${selectedData?.height_ft}.${selectedData?.height_in}`,
        },
        {
          name: `${t("screens.personalInformation.text.Weight")}`,
          value: selectedData?.weight_kg ? selectedData?.weight_kg : "-",
        },
        {
          name: `${t("screens.emergency.address")}`,
          value: `${selectedData?.address1} ${selectedData?.city} ${selectedData?.state} ${selectedData?.country}`,
        },
        {
          name: `${t("screens.formData.apt/Ste")}`,
          value: selectedData?.address2 ? selectedData?.address2 : "-",
        },
        {
          name: `${t("screens.personalInformation.text.zipCode")}`,
          value: selectedData?.zipcode ? selectedData?.zipcode : "-",
        },
        {
          name: `${t("screens.personalInformation.text.homePhone")}`,
          value: selectedData?.homephone
            ? formatPhoneNumber(selectedData?.homephone)
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.workPhone")}`,
          value: selectedData?.workphone
            ? formatPhoneNumber(selectedData?.workphone)
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.callPhone")}`,
          value: selectedData?.cellphone
            ? formatPhoneNumber(selectedData?.cellphone)
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.primaryLanguage")}`,
          value: selectedData?.primarylanguage
            ? selectedData?.primarylanguage
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.primaryLanguageOther")}`,
          value: selectedData?.primarylanguage_other
            ? selectedData?.primarylanguage_other
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.hairColor")}`,
          value: selectedData?.haircolor ? selectedData?.haircolor : "-",
        },
        {
          name: `${t("screens.personalInformation.text.hairColorOther")}`,
          value: selectedData?.haircolor_other
            ? selectedData?.haircolor_other
            : "-",
        },
        {
          name: `${t("screens.personalInformation.text.eyeColor")}`,
          value: selectedData?.eyecolor ? selectedData?.eyecolor : "-",
        },
        {
          name: `${t("screens.personalInformation.text.eyeColorOther")}`,
          value: selectedData?.eyecolor_other
            ? selectedData?.eyecolor_other
            : "",
        },
        {
          name: `${t("screens.personalInformation.text.bloodType")}`,
          value: selectedData?.bloodtype ? selectedData?.bloodtype : "-",
        },
        {
          name: `${t("screens.personalInformation.text.prosthetics")}`,
          value: selectedData?.prosthetics ? selectedData?.prosthetics : "-",
        },
        {
          name: `${t("screens.personalInformation.text.dentures")}`,
          value: selectedData?.dentures === 0 ? "No" : "Yes",
        },
        {
          name: `${t("screens.personalInformation.text.contactLenses")}`,
          value: selectedData?.lenses === 0 ? "No" : "Yes",
        },
        {
          name: `${t("screens.personalInformation.text.identifyingMarks")}`,
          value: selectedData?.identifying_marks,
        },
        {
          name: `${t(
            "screens.personalInformation.text.identifyingMarksOther"
          )}`,
          value: selectedData?.identifying_marks_other,
        },
        {
          name: `${t("screens.personalInformation.text.religiousPreference")}`,
          value: selectedData?.religious,
        },
        {
          name: `${t(
            "screens.personalInformation.text.religiousPreferenceOther"
          )}`,
          value: selectedData?.religious_other,
        },
        {
          name: `${t("screens.personalInformation.text.maritalStatus")}`,
          value: selectedData?.marital_status,
        },
        {
          name: `${t("screens.personalInformation.text.ethnicity")}`,
          value: selectedData?.ethnicity,
        },
        {
          name: `${t("screens.personalInformation.text.ethnicityOther")}`,
          value: selectedData?.ethnicity_other,
        },
      ],
    ],
  };

  return (
    <>
      <MainPageWrapper>
        <PawItemList {...props} />
        <PawDataView {...viewProps} />
        {(isAdd || isEdit) && (
          <PersonalInformationForm
            isEdit={isEdit}
            data={selectedData}
            setIsAdd={setIsAdd}
            fetchAPIData={fetchAPIData}
            setIsEdit={setIsEdit}
          />
        )}
      </MainPageWrapper>
    </>
  );
}
