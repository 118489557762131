import React, { useState } from "react";
import "./ForgetPassword.css";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Frontend/Api/ApiServices";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [Errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        email: email,
      };
      const { data } = await ApiService.forgetPassword(payload);
      if (data?.OK) {
        swal("Email Sent!", "Check your inbox.", "success");
        setEmail("");
      } else {
        setErrors({ message: "Email Address is not found" });
        setEmail("");
      }
    } catch (error) {
      console.error(error);
      if (error.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
        return;
      }
      swal("Failed!", "Failed to send email.", "error");
    }
  };

  return (
    <div className="main">
      <div className="container-forget">
        <form>
          <h1>Forgot Password</h1>

          <div className="field-forget">
            <label>Enter Your Email</label>
            <div style={{ position: "relative" }}>
              <img
                className="uname-forget"
                src={require("../../Assets/Images/mail-account.png")}
                alt="user"
              />
              <input
                type="text"
                name="email"
                className="textarea"
                value={email}
                onChange={(e) => {
                  setErrors({});
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          {Errors.message && <p>{Errors.message}</p>}
          <button className="btn1-forget" onClick={handleSubmit}>
            Send email
          </button>
          <p
            id="l4"
            style={{
              paddingTop: "10px",
              fontSize: "12px",
              cursor: "pointer",
              textAlign: "center",
              margin: 0,
            }}
            onClick={() => {
              navigate("/members");
            }}
          >
            Return to Login
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
