import React, { useState } from "react";

import { useFormikContext } from "formik";

import styled from "styled-components";
import FormGroup from "../../Common/FormGroup";
import StripeCardNumberInput from "../../../../Components/Common/StripeCardNumberInput";
import StripeCardCvcInput from "../../../../Components/Common/StripeCardCvcInput";
import StripeCardExpiryInput from "../../../../Components/Common/StripeCardExpiryInput";
import { useTranslation } from "react-i18next";

const StripeCardForm = ({ onPrevious }) => {
  const { isSubmitting } = useFormikContext();

  const [cardNumberError, setCardNumberError] = useState(true);
  const [cardExpiryError, setCardExpiryError] = useState(true);
  const [cardCvcError, setCardCvcError] = useState(true);
  const [checkBox, setCheckBox] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Column>
        <Row>
          <FormGroup
            label={`${t("screens.accountInformations.cardNumber")}`}
            name=""
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardNumberInput
              onChange={(_, error) => setCardNumberError(Boolean(error))}
            />
          </FormGroup>
          <FormGroup
            label={`${t("screens.accountInformations.ExpirationDate")}`}
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardExpiryInput
              onChange={(_, error) => setCardExpiryError(Boolean(error))}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup
            label="CVC"
            name=""
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardCvcInput
              onChange={(_, error) => setCardCvcError(Boolean(error))}
            />
          </FormGroup>
        </Row>
      </Column>
      <Check>
        <input
          type="checkbox"
          onChange={() => {
            setCheckBox(!checkBox);
          }}
        />
        <a target="_blank" href="/terms-conditions">
          {`${t("screens.upgradeMembership.acceptTermsConditions")}`} *
        </a>
        <Buttons>
          <button onClick={onPrevious}>
            {t("screens.upgradeMembership.back")}
          </button>
          {isSubmitting ? (
            <button disabled={!checkBox || isSubmitting}>
              <Loader />
            </button>
          ) : (
            <button
              disabled={
                !checkBox ||
                isSubmitting ||
                cardNumberError ||
                cardExpiryError ||
                cardCvcError
              }
              type="submit"
            >
              {t("screens.upgradeMembership.completeOrder")}
            </button>
          )}
        </Buttons>
      </Check>
    </>
  );
};

export default StripeCardForm;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 10px;

  label {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }

  input {
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgb(66, 139, 202);
    width: -webkit-fill-available;

    :focus {
      border: none;
    }
  }

  p {
    color: red;
    font-size: 14px;
    padding: 0px;
    height: 16.8px;
    margin: 7px 0 7px 0;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
  }
`;

const Check = styled.div`
  padding: 30px 0px 0px 0px;
  text-align: left;

  button {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    border-radius: 11px;
    min-width: 93px;
    min-height: 39px;
    align-items: center;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  input {
    transform: scale(1.5);
    margin-right: 10px;
  }

  a {
    font-family: sans-serif;
    text-decoration: underline;
    color: #45b1d6;
  }
`;
const Buttons = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;

  button {
    height: max-content;
    width: max-content;
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;
const Loader = styled.div`
  margin: auto;
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
