import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { AddMemberFormValidation } from "./Validation";
import AutoComplete from "../Common/AutoComplete";
import { useTranslation } from "react-i18next";

const defaultValue = {
  membership: {
    id: "",
    account_id: "",
    user_id: "",
  },
  membership_num: "",
  relation_id: "",
  user_fname: "",
  user_lname: "",
  role_id: "",
  user_email: "",
  relation_name: "",
};

export default function AssignMembershipForm({
  setAddInfo,
  data,
  fetchAPIData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [relationOptions, setRelationOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  const getRelationships = async () => {
    try {
      const { data } = await ApiService.relationsEmergency();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item.mem_rel_type,
          value: item?.id,
        }));
        setRelationOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const getRoles = async () => {
    try {
      const { data } = await ApiService.getRoles();

      if (data?.length) {
        const mappedData = data.map((item, index) => ({
          label: item.name,
          value: item?.id,
        }));
        setRoleOptions(mappedData);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...initialValues,
        membership: {
          id: data?.id,
          account_id: data?.account_id,
          membership_num: data?.membership_number,
          user_id: data?.user_id,
        },
        membership_num: data?.membership_number,
        role_id: 2,
      });
    }
  }, [data]);

  useEffect(() => {
    getRelationships();
    getRoles();
  }, []);

  const onSubmit = async (values) => {
    try {
      const isEmailValid = await handleEmailBlur();
      if (!isEmailValid) {
        return;
      }
      const { data } = await ApiService.addMember(values);
      if (data?.err === "Self relation already has an account") {
        formikRef?.current?.setFieldError("relation_id", data?.err);
        return;
      }
      setOpen(true);
      setShowSuccessMessage(true);
      fetchAPIData();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleEmailBlur = async () => {
    try {
      formikRef?.current?.setFieldTouched("user_email", true);
      if (formikRef?.current?.errors?.user_email) {
        return;
      }
      if (formikRef?.current?.values?.user_email) {
        const payload = {
          email: formikRef?.current?.values?.user_email,
        };
        const { data } = await ApiService.checkAvailabilityEmail(payload);
        if (data?.err === "Email already taken") {
          formikRef?.current?.setFieldError("user_email", data?.err);
          return false;
        }
        return true;
      }
      return true;
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={AddMemberFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting, touched, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {t("screens.accountInformations.assignMembership")}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup
                      name="membership_num"
                      label={`${t("screens.accountInformations.cardNumber")}`}
                      requiredField
                    >
                      <InputField
                        inputProps={{
                          readOnly: true,
                        }}
                        {...getFieldProps("membership_num")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="relation_id"
                      label={`${t("screens.accountInformations.relation")}`}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("relation_id")}
                        name="relation_id"
                        options={relationOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Column>
                  {(values?.relation_id === 9 || values?.relation_id === 7) && (
                    <Column>
                      <FormGroup
                        name="relation_name"
                        label={`${t(
                          "screens.accountInformations.relationName"
                        )}`}
                        requiredField
                      >
                        <InputField {...getFieldProps("relation_name")} />
                      </FormGroup>
                    </Column>
                  )}
                  {values?.relation_id !== 1 && (
                    <>
                      <Column>
                        <FormGroup
                          name="role_id"
                          label={`${t(
                            "screens.accountInformations.permission"
                          )}`}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("role_id")}
                            name="role_id"
                            options={roleOptions}
                            placeholder="Select a Permission"
                          />
                        </FormGroup>
                      </Column>
                      <Column>
                        <FormGroup
                          name="user_email"
                          label={`${t("screens.formData.email")}`}
                          requiredField
                        >
                          <InputField
                            {...getFieldProps("user_email")}
                            onBlur={handleEmailBlur}
                          />
                        </FormGroup>
                      </Column>
                      <Column>
                        <FormGroup
                          name="user_fname"
                          label={`${t("screens.formData.firstName")}`}
                          requiredField
                        >
                          <InputField {...getFieldProps("user_fname")} />
                        </FormGroup>
                      </Column>
                      <Column>
                        <FormGroup
                          name="user_lname"
                          label={`${t("screens.formData.lastName")}`}
                          requiredField
                        >
                          <InputField {...getFieldProps("user_lname")} />
                        </FormGroup>
                      </Column>
                    </>
                  )}
                </Body>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setAddInfo({})}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={`${t("screens.successMessage.dataAdded")}`}
          desc={`${t("screens.successMessage.membershipAssignCreated")}`}
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setAddInfo({});
          }}
        />
      )}
    </Box>
  );
}
