import React, { useState } from "react";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import styled from "styled-components";

function ImageUpload({
  id,
  alt,
  image,
  setImage,
  accept,
  boxSx,
  width,
  height,
}) {
  let updateImage;
  if (image && image instanceof File) {
    updateImage = false;
  } else if (image) {
    updateImage = true;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // background: "rgba(255, 255, 255, 0.3)",
        borderRadius: "20px",
        pointerEvents: "auto",
        border: "3px solid rgba(255, 255, 255, 0.5)",
        boxShadow: updateImage ? "" : "0px 4px 10px rgba(0, 0, 0, 0.25)",
        backgroundColor: updateImage ? "" : "#1175BB",
        "&:hover": {
          cursor: "pointer",
          transform: "translateY(-1px)",
          boxShadow: updateImage ? "" : "0px 20px 20px rgba(0, 0, 0, 0.15)",
        },
        marginRight: { sm: "3vw", md: "3vw", xs: "3vw", lg: "3vw", xl: "2vw" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginBottom: "1vw",
          flexDirection: "column",
          width: width || "130px",
          height: height || "130px",
          ...boxSx,
        }}
      >
        <input
          id="imageInput"
          type="file"
          accept={accept}
          onChange={(event) => {
            const file = event.target.files[0];
            if (file && file.type.substring(0, 5) === "image") {
              setImage(file);
            } else {
              setImage(null);
            }
          }}
          style={{ display: "none" }}
        />
        {image && image instanceof File ? (
          <Box
            sx={{
              width: width || "130px",
              height: height || "130px",
              position: "relative",
            }}
          >
            <img
              src={URL.createObjectURL(image)}
              alt={alt}
              style={{
                width: width || "130px",
                height: height || "130px",
                borderRadius: "15px",
                objectFit: "fill",
              }}
            />
            <CloseIcon
              onClick={() => {
                setImage(null);
              }}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: 1,
                // fontSize: "2rem",
                cursor: "pointer",
                // padding: "0.1rem",
                // color: "white",
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "50%",
              }}
            />
          </Box>
        ) : image ? (
          <img
            src={image}
            style={{
              objectFit: "cover",
              width: width || "130px",
              height: height || "130px",
              borderRadius: "20px",
              cursor: "pointer",
              border: "2px solid rgba(255, 255, 255, 0.5)",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            }}
            alt=" "
            onClick={() => document.getElementById("imageInput").click()}
            onMouseEnter={(e) => {
              e.target.style.opacity = 0.8;
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = 1;
            }}
          />
        ) : (
          <>
            <input
              onClick={() => document.getElementById("imageInput").click()}
              style={{
                position: "absolute",
                opacity: 0,
                cursor: "pointer",
                marginTop: "11px",
                width: width || "130px",
                height: height || "143px",
              }}
            />
            <AddPhotoAlternateIcon sx={{ fontSize: 48, color: "#FFFFFF" }} />
          </>
        )}
      </Box>
    </Box>
  );
}

export default ImageUpload;
