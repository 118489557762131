import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { FamilyHistoryFormValidation } from "./Validation";
import { useTranslation } from "react-i18next";

const familyMemberOptions = [
  { value: "father", label: "Father" },
  { value: "mother", label: "Mother" },
  { value: "brother", label: "Brother" },
  { value: "sister", label: "Sister" },
  { value: "grandmother", label: "Grandmother" },
  { value: "grandfather", label: "Grandfather" },
  { value: "aunt", label: "Aunt" },
  { value: "uncle", label: "Uncle" },
  { value: "other", label: "Other" },
];

const defaultValue = {
  family_member: "",
  family_mem_other: "",
  deceased: "",
  date_of_birth: "",
  conditions: "",
  status: "",
};

export default function FamilyHistoryForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [taskInput, setTaskInput] = useState("");
  const { t } = useTranslation();

  const DeceasedOption = [
    {
      label: `${t("screens.familyHistory.yes")}`,
      value: "Yes",
    },
    {
      label: `${t("screens.familyHistory.no")}`,
      value: "No",
    },
  ];

  const addTask = (values, setFieldValue) => {
    if (taskInput) {
      setFieldValue("conditionTest", [
        ...(values?.conditionTest || []),
        { text: taskInput, completed: false },
      ]);
      const conditionsData = JSON.stringify(
        [
          ...(values?.conditionTest || []),
          { text: taskInput, completed: false },
        ].map((task) => task.text)
      );
      setFieldValue("conditions", conditionsData);
      setTaskInput("");
    }
  };
  const renderTasks = (values, setFieldValue) => {
    return values?.conditionTest?.map((task, index) => (
      <div key={index} style={{ flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            // flexDirection: "row",
            gap: 10,
            padding: 3,
            borderWidth: 1,
            borderRadius: 3,
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <button
            type="button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "18px",
              height: "18px",
              margin: 0,
            }}
            onClick={() => deleteTask(index, values, setFieldValue)}
          >
            <RemoveIcon />
          </button>

          <span>{task.text}</span>
        </div>
      </div>
    ));
  };

  const deleteTask = (index, values, setFieldValue) => {
    const newValues =
      values?.conditionTest?.filter((task, i) => i !== index) || [];
    setFieldValue("conditionTest", newValues);
    const conditionsData = JSON.stringify(newValues?.map((task) => task.text));
    setFieldValue("conditions", conditionsData);
  };

  const styles = {
    radio: {
      "& .MuiRadio-root": {
        color: `#194daa`,
        "&.Mui-checked": {
          color: `#194daa`,
        },
      },
    },
  };

  useEffect(() => {
    if (isEdit) {
      setInitialValues((prev) => ({
        ...prev,
        ...data,
        conditionTest: data?.conditions
          ? JSON.parse(data?.conditions).map((text) => ({
              text,
              completed: false,
            }))
          : [],
      }));
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  const onSubmit = async (values) => {
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editFamilyHistory(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addFamilyHistory(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={FamilyHistoryFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting, setFieldValue }) => (
          <Form>
            {console.log("values1: ", values)}
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.familyHistory.editFamilyHistory")}`
                    : `${t("screens.familyHistory.addFamilyHistory")}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="family_member"
                      label={`${t("screens.familyHistory.familyMember")}`}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("family_member")}
                        name="family_member"
                        options={familyMemberOptions}
                        placeholder=""
                      />
                    </FormGroup>
                  </Grid>
                  {familyMemberOptions.find(
                    (key) => key.value === values.family_member
                  )?.label === "Other" && (
                    <Grid item xs={12} sm={3}>
                      <FormGroup
                        name="family_mem_other"
                        label={`${t("screens.familyHistory.familyMemberName")}`}
                      >
                        <InputField {...getFieldProps("family_mem_other")} />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item sm={3} xs={12}>
                    <FormGroup
                      name="deceased"
                      label={`${t("screens.familyHistory.deceased")}`}
                      requiredField
                    >
                      <RadioGroup
                        name="deceased"
                        {...getFieldProps("deceased")}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "white",
                        }}
                        sx={styles.radio}
                      >
                        {DeceasedOption?.map((item) => {
                          return (
                            <FormControlLabel
                              value={item?.value}
                              control={<Radio />}
                              label={item?.label}
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "0.9rem",
                                  color: "black",
                                },
                              }}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="date_of_birth"
                      label={`${t("screens.familyHistory.dateofBirth")}`}
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("date_of_birth")}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <div className="family-history-note">
                  <p>{t("screens.familyHistory.multipleCondition")}</p>
                </div>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="conditions"
                      label={`${t("screens.familyHistory.conditions")}`}
                    >
                      <InputField
                        value={taskInput}
                        onChange={(e) => setTaskInput(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              position="end"
                              onClick={() => {}}
                              sx={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                zIndex: 1,
                                padding: 0,
                                cursor: "pointer",
                                width: "auto",
                              }}
                            >
                              <AddIcon
                                onClick={() => addTask(values, setFieldValue)}
                                sx={{ backgroundColor: "#1175BB" }}
                                fontSize="small"
                              />
                            </IconButton>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 10,
                      }}
                    >
                      {renderTasks(values, setFieldValue)}
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="status"
                      label={`${t("screens.familyHistory.status")}`}
                    >
                      <InputField {...getFieldProps("status")} />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.familyHistoryUpdated")}`
              : `${t("screens.successMessage.familyHistoryCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
