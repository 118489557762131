import axios from "axios";
const REACT_APP_BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
const instance = axios.create({
  baseURL: REACT_APP_BACKEND_BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
});
export default instance;
