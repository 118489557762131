import React, { useEffect, useRef, useState } from "react";
import { navData } from "../Data/Header/Navdata";
import styled from "styled-components";
import MenuButton from "../Header/MenuButton";
import Menutool from "../Header/Menutool";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { TOKEN_KEY } from "../../Frontend/Constant";
import useAuth from "../../Frontend/ContextProvider/AuthProvider";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const tooltipRef = useRef();
  const navigate = useNavigate();

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }
  function handleClickOutside(event) {
    if (
      ref?.current &&
      !ref?.current?.contains(event.target) &&
      !tooltipRef?.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    const dropdownMenu = document.getElementById("dropdownMenu");
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("show");
    }
  };
  const currentPageURL = window.location.pathname;

  const loginOption = [
    {
      label: "Existing User LogIn",
      link: "/members",
    },
    {
      label: "Become a Member",
      link: "/register",
    },
    {
      label: "Emergency Login",
      link: "/er",
    },
  ];

  const emergencyLoginUserOption = [
    {
      label: "Contact Us",
      link: "/contactus",
    },
    {
      label: "Logout",
      link: "/members",
      onClick: () => {
        localStorage.clear();
        window.location.href = "/members";
      },
    },
  ];

  const token = localStorage.getItem(TOKEN_KEY);
  const checkToken = token && token !== "null" ? token : "";
  const isLogin =
    !!checkToken && window?.location?.pathname === "/emergencyMedicalHistory";
  const listOptions = isLogin ? emergencyLoginUserOption : loginOption;

  return (
    <div topmenu="" className="navbar-header-top" ng-hide="hideHeader">
      <header
        ng-controller="TopMenuController"
        id="home"
        className="header layout-site-header navbar navbar-inverse ng-scope"
      >
        <div className="container-main container-fluid header-foodts-sections">
          <div ng-if="!menu.show" className="navbar-header ng-scope">
            <a className="navbar-brand" href="/">
              <img
                alt="logo"
                className="img-fluid"
                src="/assets/images/headerlogo.png"
              />
            </a>
          </div>
          <div style={{ display: "flex" }}>
            <div
              id="mainmenu"
              ng-controller="navDetails"
              className="collapse navbar-collapse ng-scope"
            >
              <ul className="navbar-nav">
                {navData.map((item, index) => (
                  <li
                    className={`ng-scope ${
                      currentPageURL === item?.link ? "active" : ""
                    }`}
                    key={index}
                  >
                    <Link to={item.link} target="_self" className="ng-binding">
                      {item?.icon && <img src={item.icon} alt={item.title} />}
                      {item.title}
                    </Link>
                  </li>
                ))}
                <div className="btn-group pull-right">
                  <button
                    type="button"
                    className="btn btn-default dropdown-toggle top-menu-button"
                    onClick={() => toggleDropdown()}
                  >
                    <div className={`header-profile-container`}>
                      <img alt="user" src="/assets/svg/menuUser.svg" />
                      Sign In/Sign Up
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu top-menu-items"
                    role="menu"
                    id="dropdownMenu"
                  >
                    {listOptions?.map((item) => {
                      return (
                        <li className="ng-scope">
                          <Link target="_self" to={item?.link}>
                            <button
                              className="btn btn-outline-primary"
                              onClick={
                                item?.onClick
                                  ? item?.onClick
                                  : () => toggleDropdown()
                              }
                            >
                              {item?.label}
                            </button>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </ul>
            </div>
            <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
              <HamburgerIcon>
                <MenuItem>
                  <img
                    src={require("../../Assets/Images/Navbar/hamburger-menu.png")}
                    width={30}
                    height={30}
                  />
                </MenuItem>
              </HamburgerIcon>
            </HamburgerWrapper>
            <li>
              <div ng-controller="TopMenuController" className="ng-scope">
                <div>
                  {/* <div className="btn-group pull-right">
                    <button
                      type="button"
                      className="btn btn-default dropdown-toggle top-menu-button header-profile-container"
                      onClick={() => toggleDropdown()}
                    >
                      <img alt="user" src="/assets/svg/menuUser.svg" />
                      Sign In/Sign Up
                    </button>
                    <ul
                      className="dropdown-menu top-menu-items"
                      role="menu"
                      id="dropdownMenu"
                    >
                      {listOptions?.map((item) => {
                        return (
                          <li className="ng-scope">
                            <Link target="_self" to={item?.link}>
                              <button
                                className="btn btn-outline-primary"
                                onClick={
                                  item?.onClick
                                    ? item?.onClick
                                    : () => toggleDropdown()
                                }
                              >
                                {item?.label}
                              </button>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div> */}
                </div>
              </div>
            </li>
          </div>
        </div>
      </header>
      <Menutool isOpen={isOpen} />
    </div>
  );
}

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  background: #008fd3;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 5px;
`;

const MenuItem = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  text-align: left;

  :hover {
    background: #333;
  }
`;
