import React from "react";

export default function HomeSection1() {
  return (
    <section
      className="home-video content-offset container-fluid"
      data-stellar-background-ratio="0.2"
    >
      <div className="video-section row flex-row">
        <div className="col-md-6 col-sm-12">
          <p className="video-letter">
            Keeping track of your
            <span className="text-blue"> medical records</span>
            <span className="text-gray">doesn't have to be</span>
            <span className="text-sky-blue"> so darn stressful.</span>
          </p>
          <p className="video-letter-2">
            Only FootprintID® lets you prepare, share, and protect your medical
            history with our secure, easy to use app.
          </p>
          <button className="btn-deep-orange">Get in Touch</button>
        </div>
        <div className="col-md-6 col-sm-12 video-div">
          <object
            id="video-container"
            width="627"
            height="354"
            className="video-play"
            data="https://www.youtube.com/v/P_r3Wm4UiQk?rel=0"
          ></object>
        </div>
      </div>
    </section>
  );
}
