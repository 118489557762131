import React, { useMemo, useState } from "react";

import useSWR from "swr";

import viewIcon from "../../../Assets/Images/viewIcon.svg";
import useAuth from "../../../ContextProvider/AuthProvider";
import PawItemList from "../../Common/PawItemList.js";
import { Button, Typography } from "@mui/material";
import PawDataView from "../../Common/PawDataView.js";
import EditIcon from "../../../Assets/Images/EditIcon.svg";
import Layout from "../../Side bar/Layout.js";
import ApiService from "../../../Api/ApiServices.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AssignMembershipForm from "../../Forms/AssignMembershipForm";
import {
  ADMIN_ROLE,
  LOGIN_USER_ROLE_KEY,
  POWER_USER_ROLE,
  USER_ROLE_KEY,
} from "../../../Constant";
import { useTranslation } from "react-i18next";

let membersData2 = [];
export default function AccountInformation() {
  const { setUserId, setUser } = useAuth();
  const loginUserRole = localStorage.getItem(LOGIN_USER_ROLE_KEY);
  const navigate = useNavigate();
  const [AddInfo, setAddInfo] = useState({});
  const { t } = useTranslation();

  const { data = [] } = useSWR(
    `accountInfo`,
    async () => {
      const { data } = await ApiService.accountInfo();
      return data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const {
    data: membersData,
    mutate: fetchmembersData,
    isLoading: isMembersDataLoading,
  } = useSWR(
    `getMembershipCards`,
    async () => {
      const { data } = await ApiService.getMembershipCards();
      const { data: memberData } = await ApiService.getMembers();
      membersData2 = memberData?.members;
      const allData = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        let memberData = {};
        if (element?.user_id) {
          const { data } = await ApiService.getMember(element?.user_id);
          memberData = data;
        }
        allData.push({
          ...memberData,
          ...element,
          name: `${element?.user_fname} ${element?.user_lname}`,
        });
      }
      return allData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleView = async (item) => {
    const memberId = item?.user_id;
    try {
      setUserId(memberId);
      const { data } = await ApiService.getSelfInfo(memberId);
      localStorage.setItem(USER_ROLE_KEY, data?.user_role);
      const userData = membersData2?.find((x) => x?.user_id === memberId);
      setUser(userData);
      navigate("/user-information");
    } catch (error) {}
  };

  const handleEdit = (item) => {
    navigate(`/members/${item?.user_id}`);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: `${t("screens.accountInformations.membershipID")}`,
        textAlign: "center",
        accessor: "membership_number",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.accountInformations.accessCode")}`,
        textAlign: "center",
        accessor: "access_code",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.accountInformations.name")}`,
        accessor: "name",
        textAlign: "center",
        width: "12.5vw",
        Cell: ({ row }) => {
          return row.original?.name !== "null null" ? (
            <Typography
              sx={{
                padding: "5px",
                cursor: "pointer",
                fontSize: "12px",
                color: "#008fd3",
                ":hover": {
                  color: "red",
                },
              }}
              onClick={() => handleView(row.original)}
            >
              {row.original?.name}
            </Typography>
          ) : (
            <Typography
              sx={{
                padding: "5px",
                cursor: "pointer",
                fontSize: "12px",
                color: "#008fd3",
                ":hover": {
                  color: "red",
                },
              }}
              onClick={() => setAddInfo(row.original)}
            >
              {t("screens.accountInformations.assignMembership")}
            </Typography>
          );
        },
      },
      {
        Header: `${t("screens.accountInformations.email")}`,
        accessor: "user_email",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.accountInformations.relationship")}`,
        accessor: "relation",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.accountInformations.role")}`,
        accessor: "user_role",
        textAlign: "center",
        width: "12.5vw",
      },
      {
        Header: `${t("screens.formData.view")}`,
        accessor: "view",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original?.name !== "null null" ? (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleView(row.original)}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          ) : (
            <></>
          );
        },
      },
      {
        Header: `${t("screens.formData.edit")}`,
        accessor: "edit",
        width: "4vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row.original?.name !== "null null" ? (
            <Button
              sx={{
                minWidth: "40px",
                background: "#1976d22e !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => handleEdit(row.original)}
            >
              <img
                alt="view"
                src={EditIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          ) : (
            <></>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const props = {
    label: "",
    isLoading: isMembersDataLoading,
    columns,
    columnsData: membersData,
    details: " There are no details for this user.",
    isSelectHide: true,
    addButton: false,
  };

  function isUnixDateFormat(date) {
    if (date) {
      const dateString = date.toString();
      const unixFormatRegex = /^\d+$/;
      return unixFormatRegex.test(dateString);
    }
  }

  const viewProps = {
    label: `${t("screens.accountInformations.accountInformation")}`,
    isShow: true,
    title2: `${t("screens.accountInformations.accountInformationMemberships")}`,
    detailsData: [
      [
        {
          name: `${t("screens.accountInformations.name")}`,
          value: `${data?.account?.user_fname} ${data?.account?.user_lname}`,
        },
        {
          name: `${t("screens.accountInformations.email")}`,
          value: data?.account?.user_email,
        },
        {
          name: `${t("screens.accountInformations.role")}`,
          value: data?.account?.user_role,
        },
        {
          name: `${t("screens.accountInformations.MemberSince")}`,
          value: data?.sum?.end_date
            ? isUnixDateFormat(data?.sum?.payment_date)
              ? moment(data?.sum?.payment_date * 1).format("MM/DD/YYYY")
              : moment(data?.sum?.payment_date).format("MM/DD/YYYY")
            : "",
        },
        {
          name: `${t("screens.accountInformations.ExpirationDate")}`,
          value: data?.sum?.end_date
            ? isUnixDateFormat(data?.sum?.end_date)
              ? moment(data?.sum?.end_date * 1).format("MM/DD/YYYY")
              : moment(data?.sum?.end_date).format("MM/DD/YYYY")
            : "",
        },
      ],
      [
        {
          name: `${t("screens.accountInformations.TotalMemberships")}`,
          value: data?.sum?.total_memberships,
        },
        {
          name: `${t("screens.accountInformations.MembershipsUsed")}`,
          value: `${data?.sum?.total_memberships} / ${data?.sum?.used_memberships}`,
        },
      ],
    ],
  };

  return (
    <Layout>
      <PawDataView {...viewProps} />
      {!Object.keys(AddInfo).length && (
        <>
          {(loginUserRole === ADMIN_ROLE ||
            loginUserRole === POWER_USER_ROLE) && <PawItemList {...props} />}
          {loginUserRole === ADMIN_ROLE && (
            <Button
              sx={{ height: "50px", width: "auto" }}
              style={{
                backgroundColor: "#1175BB",
                color: "#FFFFFF",
                margin: "8px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "20px",
              }}
              onClick={() => {
                navigate("/settings/upgradeMembers");
              }}
            >
              {t("screens.rightSidebar.text.buyAdditionalMemberships")}
            </Button>
          )}
        </>
      )}
      {(loginUserRole === ADMIN_ROLE || loginUserRole === POWER_USER_ROLE) &&
        Object.keys(AddInfo).length > 0 && (
          <AssignMembershipForm
            data={AddInfo}
            setAddInfo={setAddInfo}
            fetchAPIData={fetchmembersData}
          />
        )}
    </Layout>
  );
}
