import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getActivationkey } from "../../api/axios";
const ThankYouPage = () => {
  const { activationkey, uid } = useParams(); // Extract parameters from route
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getActivationkey(activationkey, uid);
        setData(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      }
    };

    if (activationkey && uid) { // Check if parameters exist before fetching
      fetchData();
    }
  }, [activationkey, uid]); // D Dependency array for useEffect

  useEffect(() => {
    if (data) {
      // history('/members'); // Navigate to "/members" on successful data fetch
      window.location.href = "/members";
    } else if (error) {
      history('/error'); // Navigate to "/error" on error
    }
  }, [data, error, history]); // Dependency array for useEffect

  // ... rest of your component logic and rendering using data and error state

  return (
    <div>
      Loading data...
    </div>
  );
};

export default ThankYouPage;