import styled from "styled-components";
import { navData, tooltipData } from "../Data/Header/Navdata";
import MenuButton from "./MenuButton";

function Menutool(props) {
  const { isOpen } = props;
  return (
    <Wrapper isOpen={isOpen}>
      {navData.map((item, index) => (
        <MenuButton item={item} key={index} />
      ))}
    </Wrapper>
  );
}

export default Menutool;
const Wrapper = styled.div`
  text-align: center;
  position: fixed;
  top: 60px;
  right: 50px;
  background: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  backdrop-filter: blur(40px);
  border-radius: 20px;
  padding: 10px 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  z-index: 100;
  display: grid;
  gap: 1px;
  grid-template-columns: 200px;
  transition: 0.3s ease-in-out;
  @media (max-width: 768px) {
    grid-template-columns: 180px;
  }
`;
