import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { STORE_LANGUAGE_KEY } from "../Frontend/Constant";
import { en, sp } from "./translations";

const languageDetectorPlugin = {
  type: "languageDetector",
  async: true,
  init: () => {},
  detect: async (callback) => {
    try {
      // get stored language from localstorage
      // put your own language detection logic here
      const language = await localStorage.getItem(STORE_LANGUAGE_KEY);

      if (language) {
        // if the language was stored before, use this language in the app
        callback(language);
      } else {
        // if the language was not stored yet, use english
        callback("en");
      }
    } catch (error) {
      console.log("Error reading language", error);
    }
  },
  cacheUserLanguage: async (language) => {
    try {
      // save a user's language choice in localstorage
      await localStorage.setItem(STORE_LANGUAGE_KEY, language);
    } catch (error) {}
  },
};

const resources = {
  en: {
    translation: en,
  },
  sp: {
    translation: sp,
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .init({
    resources,
    compatibilityJSON: "v3",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
