import React, { createContext, useContext, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import {
  CMS_ROUTES,
  LOGIN_USER_ROLE_KEY,
  TOKEN_KEY,
  USERID_KEY,
  USER_ROLE_KEY,
} from "../../Constant";
import ApiService from "../../Api/ApiServices";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const _token = localStorage.getItem(TOKEN_KEY);

  const [isAuthenticated, setIsAuthenticated] = useState(!!_token);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loginUser, setLoginUser] = useState(null);

  const redirectToLogin = () => {
    window.location.href = "/members";
  };

  const setUserToken = async (value) => {
    await localStorage.setItem(TOKEN_KEY, value);
    setToken(value);
  };
  const removeUserToken = async () => {
    await localStorage.removeItem(TOKEN_KEY);
    setToken(undefined);
  };

  const isStoragePresent = async () => {
    if (
      localStorage.getItem(USERID_KEY) === null &&
      localStorage.getItem(TOKEN_KEY) === null
    ) {
      localStorage.clear();
      redirectToLogin();
    }
  };

  const getAuthenticatedUsers = async (getUserId, token) => {
    try {
      if (
        (!getUserId || !token) &&
        (!CMS_ROUTES.includes(window?.location?.pathname) ||
          window?.location?.pathname === "/emergencyMedicalHistory")
      ) {
        if (
          !(window?.location?.pathname?.split("/")?.[1] === "password-recovery" ||
          window?.location?.pathname?.split("/")?.[1] === "thankyou")
        ) {
          redirectToLogin();
        }
        return;
      }
      const { data: userInfo } = await ApiService.getSelfInfo(getUserId);
      if (userInfo) {
        setUser(userInfo);
        setLoginUser(userInfo);
        localStorage.setItem(USER_ROLE_KEY, userInfo?.user_role);
        localStorage.setItem(LOGIN_USER_ROLE_KEY, userInfo?.user_role);
      }
    } catch (e) {
      if (e?.response?.status === 403) {
        if (
          CMS_ROUTES.includes(window?.location?.pathname) &&
          window?.location?.pathname !== "/emergencyMedicalHistory"
        ) {
          return;
        } else {
          redirectToLogin();
          return;
        }
      }
      throw e;
    }
  };

  const { isLoading } = useSWRImmutable(
    "checkAuth",
    async () => {
      try {
        const getUserId = await localStorage.getItem(USERID_KEY);
        const token = await localStorage.getItem(TOKEN_KEY);

        await setUserToken(token && token !== "null" ? token : "");
        await setUserId(getUserId);
        await getAuthenticatedUsers(getUserId, token);
        if (token) {
          setIsAuthenticated(true);
        }
      } catch (e) {
        setIsAuthenticated(false);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const login = () => {
    // Implement your login logic here
    setIsAuthenticated(true);
  };

  const resetData = async () => {
    // removeCookie({ key: 'token', params: { path: '' } })
    await removeUserToken(null);
    setIsAuthenticated(false);
    localStorage.clear();
  };

  const doLogout = async () => {
    try {
      await resetData();
      redirectToLogin();
    } catch (e) {
      console.error({ e });
    }
  };

  useEffect(() => {
    isStoragePresent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const value = {
    isAuthenticated,
    login,
    doLogout,
    user,
    redirectToLogin,
    userId,
    setUserId,
    setUser,
    token,
    loginUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#1175BB" }} />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
