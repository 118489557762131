import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import ApiService from "../../Api/ApiServices";
import { medicalInsuranceFormValidation } from "./Validation";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const defaultValue = {
  ins_member: "",
  ins_subcriber: "",
  ins_provider: "",
  ins_memberid: "",
  ins_plan: "",
  ins_group: "",
  ins_memberid: "",
  ins_memberid: "",
  ins_providerphone: "",
  ins_effective_date: "",
};

export default function MedicalInsuranceForm({
  isEdit,
  data,
  setIsAdd,
  fetchAPIData,
}) {
  const [initialValues, setInitialValues] = useState(defaultValue);
  const { userId } = useAuth();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        ...initialValues,
        ...data,
        ins_memberid: data?.ins_memberid?.toString(),
        ins_effective_date: moment(data?.ins_effective_date).format(
          "YYYY-MM-DD"
        ),
      });
    } else {
      setInitialValues(defaultValue);
    }
  }, [isEdit, data]);

  const onSubmit = async (values) => {
    try {
      if (isEdit && data?.id) {
        const payload = {
          ...values,
          id: data?.id,
          user_id: data.user_id,
        };
        const res = await ApiService.editMedicalInsurance(userId, payload);
        if (res?.data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      } else {
        const { data } = await ApiService.addMedicalInsurance(userId, values);
        if (data?.OK) {
          setOpen(true);
          setShowSuccessMessage(true);
          fetchAPIData();
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Box sx={{ padding: "30px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={medicalInsuranceFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #194daa", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  {isEdit
                    ? `${t("screens.medicalInsurance.editMedicalInsurance")}`
                    : `${t("screens.medicalInsurance.addMedicalInsurance")}`}
                </Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Grid container spacing={3} columns={12}>
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="ins_member"
                      label={`${t("screens.medicalInsurance.memberName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("ins_member")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup
                      name="ins_subcriber"
                      label={`${t("screens.medicalInsurance.subscriberName")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("ins_subcriber")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="ins_provider"
                      label={`${t(
                        "screens.medicalInsurance.insuranceProviderName"
                      )}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("ins_provider")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="ins_providerphone"
                      label={`${t(
                        "screens.medicalInsurance.insuranceProviderPhone"
                      )}`}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("ins_providerphone")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="ins_memberid"
                      label={`${t("screens.medicalInsurance.memberID")}`}
                      requiredField
                    >
                      <InputField {...getFieldProps("ins_memberid")} />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  columns={12}
                  style={{ marginTop: "-7px" }}
                >
                  <Grid item sm={6} xs={12}>
                    <FormGroup
                      name="ins_plan"
                      label={`${t("screens.medicalInsurance.plan")}`}
                    >
                      <InputField {...getFieldProps("ins_plan")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="ins_group"
                      label={`${t("screens.medicalInsurance.group")}`}
                    >
                      <InputField {...getFieldProps("ins_group")} />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormGroup
                      name="ins_effective_date"
                      label={`${t("screens.medicalInsurance.effectiveDate")}`}
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("ins_effective_date")}
                        value={values?.ins_effective_date}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label={`${t("screens.formData.cancel")}`}
                    type="button"
                    style={CancelButton}
                    onClick={() => setIsAdd(false)}
                  />
                  <CustomButton
                    label={
                      isSubmitting
                        ? `${t("screens.formData.submitting")}`
                        : `${t("screens.formData.submit")}`
                    }
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title={
            isEdit
              ? `${t("screens.successMessage.dataUpdated")}`
              : `${t("screens.successMessage.dataAdded")}`
          }
          desc={
            isEdit
              ? `${t("screens.successMessage.medicalInsuranceUpdated")}`
              : `${t("screens.successMessage.medicalInsuranceCreated")}`
          }
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            setIsAdd(false);
          }}
        />
      )}
    </Box>
  );
}
