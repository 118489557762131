import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../Login/login.css";
import HttpCommon from "../../Utils/http-common";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import { ErrorMessage, Form, Formik } from "formik";
import { LoginAccountValidation } from "../../From/Validation";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import PasswordInput from "../Common/FormGroup/PasswordInput";
import {
  LOGIN_TYPE,
  LOGIN_USER_ROLE_KEY,
  TOKEN_KEY,
  USERID_KEY,
  USER_ROLE_KEY,
} from "../../Frontend/Constant";
import ApiService from "../../Frontend/Api/ApiServices";

const defaultValue = {
  username: "",
  password: "",
};

function Login() {
  const formRef = useRef();
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  const [initialValue, setInitialValue] = useState(defaultValue);

  useEffect(() => {
    if (localStorage.getItem("password") !== null) {
      setInitialValue({
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      });
    }
    setCheck(localStorage.getItem("checkbox") || false);
  }, []);

  //send data
  const handleSubmit = async (Values, { setFieldError, setSubmitting }) => {
    try {
      const res = await HttpCommon.post("/login", Values);
      console.log("res: ", res);
      if (res.data.isAuthenticated) {
        const user_id = res.data.user?.[0]?.id;
        const token = res.data.token;
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(USERID_KEY, user_id);
        localStorage.setItem(LOGIN_TYPE, "user_login");
        // localStorage.setItem(USER_ROLE_KEY, res.data.user?.[0]?.user_role);
        if (res?.data?.user?.[0].user_role !== "Administrator") {
          window.location.href = "/user-information";
        } else {
          const { data } = await ApiService.getMembersByUserId(user_id);
          for (var i = 0; i < data.members.length; i++) {
            if (data.members[i].relation === "Self") {
              const { data: userInfo } = await ApiService.getSelfInfo(
                data?.members?.[i]?.user_id
              );
              localStorage.setItem(USER_ROLE_KEY, userInfo?.user_role);
              localStorage.setItem(LOGIN_USER_ROLE_KEY, userInfo?.user_role);
              window.location.href = "/user-information";
              return;
            }
          }
          window.location.href = "/myFootprintID";
        }
      } else {
        if (
          res.data.err ===
          "Too many failed attempts. Try again after 30 minutes."
        ) {
          setFieldError("password", res.data.err);
          setSubmitting(false);
        }
        if (res.data.err === "L_LOGIN__INCORRECT_CREDENTIALS") {
          setFieldError("password", "Invalid login credential");
          setSubmitting(false);
        }
        if (res.data.err === "L_LOGIN__NOT_ACTIVATED") {
          setFieldError("password", "Please activate your account");
          setSubmitting(false);
        }
        if (res.data.err === "L_LOGIN__RESTRICTED_USER") {
          setFieldError(
            "password",
            "Dependent user cannot login via standard login method"
          );
          setSubmitting(false);
        }
      }
    } catch (err) {
      console.log("error: ", err);
      if (err.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
        setSubmitting(false);
      }
    }
  };

  const handleMember = () => {
    navigate("/register");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main">
        <div className="container">
          <div className="wel-come-title">Welcome back</div>
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={LoginAccountValidation}
            // enableReinitialize={true}
            innerRef={formRef}
          >
            {({ getFieldProps, values, isSubmitting, errors }) => (
              <Form>
                <h1>Log In to your account</h1>
                <FormGroup
                  label="Username"
                  name="username"
                  className="loginField"
                  svgPath={require("../../Assets/Images/user.png")}
                >
                  <InputField
                    type="text"
                    name="username"
                    className="textarea"
                    {...getFieldProps("username")}
                  />
                </FormGroup>
                <FormGroup
                  label="Password"
                  name="password"
                  className="loginField"
                  svgPath={require("../../Assets/Images/lock.png")}
                >
                  <PasswordInput {...getFieldProps("password")} />
                </FormGroup>
                <div className="middle">
                  <div className="left">
                    <input
                      type="checkbox"
                      id="check"
                      onChange={(e) => {
                        setCheck(e.target.checked);
                      }}
                      checked={check}
                      style={{ marginRight: "5px" }}
                    />
                    <label htmlFor="check"> Remember Me</label>
                  </div>
                  <div className="right">
                    <label>
                      <a href="/forget-password">Forgot password?</a>
                    </label>
                  </div>
                </div>

                {isSubmitting ? (
                  <button
                    className="btn1"
                    style={{ display: "grid" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <Loader />
                  </button>
                ) : (
                  <button className="btn1" type="submit">
                    Get started
                  </button>
                )}

                {/* <p id="account">Not a member? Create an account below</p> */}
                <button className="btn12 signup-btn" onClick={handleMember}>
                  <img
                    src={require("../../Assets/Images/email-border.png")}
                    alt=""
                  />
                  <div className="email-btn"> Signup with email</div>
                </button>
                <a id="l4" href="/contactus" className="needhelp">
                  Need Help ?
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Login;

const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
